import {useEffect, useState} from 'react';

import {HeaderGeneral} from '../../components/HeaderGeneral/HeaderGeneral';
import {SwiperExplore} from '../../components/ExploreSwiper/Swiper/SwiperExplore';
import {AnimateBtn} from '../../components/Buttons/AnimateBtn/AnimateBtn';
import {TransparentBtn} from '../../components/Buttons/TransparentBtn/TransparentBtn';
import {BattleList} from '../../components/BattleList/BattleList';
import {battleAPI, statisticsAPI} from '../../api/api';

import styled from './Explore.module.scss';
import {useNavigate} from 'react-router-dom';
import {MovingBackground} from '../../components/ProfileLayout/MovingBackground';
import {TopPlayers} from '../../components/WinnersPages/TopPlayers/TopPlayers';
import {NotificationT} from '../../components/ToastifyNot/NotificationToastify';
import {setBattle} from "../../store/battleSlice";
import {useDispatch} from "react-redux";
import {useAppDispatch} from "../../hooks/reduxHooks";

interface ITrack {
    id?: number;
    post?: string;
    title?: string;
    genre?: string;
    url?: string;
    duration?: number;
    date_end?: string; //new
    status?: number; //!new?
    votes?: any; //new
    winner?: any; //new
    winner_customer_id?: number; //new
}

const Explore = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [userStatistic, setUserStatistic] = useState([]);

    useEffect(() => {
        statisticsAPI.getTopPlayers().then((res) => {
            setUserStatistic(res);
        });
    }, []);

    const [currentBattle, setCurrentBattle] = useState<any>(null);

    const fetchCurrentBattle = () => {
        battleAPI
            .showBattleWithFilter(-1, 1)
            .then((res) => {
                if (res.data.data.length !== 0) {
                    setCurrentBattle(res.data.data[0]);
                } else {
                    setCurrentBattle(null)
                }
            })
            .catch(() => {});
    };

    useEffect(fetchCurrentBattle, []);

    useEffect(() => {
        if (currentBattle && currentBattle.is_count_tme) {
            const date_end_utc = new Date(Date.parse(currentBattle.date_end.replace(' ', 'T') + 'Z')); // ISO8601 format with 'Z' for UTC timezone

            let milliseconds = Math.abs(date_end_utc.getTime() - new Date().getTime());

            if (milliseconds < 0) {
                milliseconds = 0;
            }

            milliseconds += 15 * 1000;

            const timeout = setTimeout(fetchCurrentBattle, milliseconds);

            return () => { clearTimeout(timeout); };
        }

        let milliseconds = 1000;

        const interval = setInterval(fetchCurrentBattle, milliseconds);

        return () => { clearInterval(interval); };

    }, [currentBattle]);

    useEffect(() => {
        dispatch(setBattle(currentBattle));
    }, [currentBattle]);

    return (
        <>
            <HeaderGeneral title='Home'/>
            <NotificationT/>

            <div className={styled.container}>
                <div className={styled.headContent}>
                    <MovingBackground customBackground={true}>
                        <div className={styled.headInfo}>
                            <div className={styled.infoContent}>
                                <h2 className={styled.title}>VOTE and WIN</h2>
                                <div className={styled.buttonsWrapper}>
                                    <AnimateBtn handleClick={() => {
                                        navigate(`/category-selection`);
                                    }} title='Battles'/>
                                    <TransparentBtn handleClick={() => {
                                        navigate(`/how-to-play-and-win`);
                                    }} title='How To Play'/>
                                </div>
                            </div>
                            {currentBattle &&
                                <div className={styled.battles} style={{paddingTop: "40px", overflow: "visible"}}>
                                    <BattleList
                                        currentRound={1}
                                        battle={currentBattle}
                                    />
                                </div>
                            }
                        </div>
                    </MovingBackground>
                </div>

                <div className={styled.cardsWrapper}>
                    <SwiperExplore/>
                </div>

                <div className={styled.topNFTWinnersWrapper}>
                    <div className={styled.topPlayerInfo}>
                        <h3 className={styled.tableTitle} style={{marginBottom: '12px'}}>Top Players</h3>
                        <p className={styled.seeMore} onClick={() => {
                            navigate(`/winners`);
                        }}>See more</p>
                    </div>
                    <div>
                        <TopPlayers topPlayers={userStatistic}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export {Explore};

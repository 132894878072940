import React, {useEffect, useRef, useState} from 'react';

import NavigateElement from './NavigateElement';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import {toggleOpenMenu} from '../../store/mainMenuSlice';
import {ReactComponent as Explore} from '../../assets/svg/mainMenu/home.svg';
import {ReactComponent as NftBattle} from '../../assets/svg/mainMenu/path.svg';
import {ReactComponent as Note} from '../../assets/svg/mainMenu/note.svg';
import {ReactComponent as Heart} from '../../assets/svg/mainMenu/heart.svg';
import {ReactComponent as Trophy} from '../../assets/svg/mainMenu/trophy.svg';
import {ReactComponent as Album} from '../../assets/svg/mainMenu/music-album.svg';
import {ReactComponent as Playlist} from '../../assets/svg/mainMenu/playlist.svg';
import {ReactComponent as Email} from '../../assets/svg/mainMenu/email.svg';
import {ReactComponent as Info} from '../../assets/svg/mainMenu/info.svg';

import {ReactComponent as Twitter} from '../../assets/svg/footer-links/twitter.svg';
import {ReactComponent as Discord} from '../../assets/svg/footer-links/discord.svg';
import {ReactComponent as Reddit} from '../../assets/svg/footer-links/reddit.svg';
import {ReactComponent as Telegram} from '../../assets/svg/footer-links/telegram.svg';

import styled from './Navigate.module.scss';
import {Link} from "react-router-dom";

const Navigate = () => {
    const ref = useRef(null);
    const dispatch = useAppDispatch();
    const [isAuth, setIsAuth] = useState(true);

    const isOpenModal = useAppSelector((state) => state.modalLogin.isOpen);

    useEffect(() => {
        if (localStorage.getItem('isAuth')) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
    }, []);

    function toggleMenu() {
        dispatch(toggleOpenMenu(null));
    }

    const menu = [
        {name: 'Home', url: 'explore', svg: <Explore/>},
        {name: 'Battles', url: 'category-selection', svg: <NftBattle/>},
        {name: 'Buy NFT', url: 'buy-nft', svg: <Note/>},
        {name: 'Order NFT', url: 'make-offer', svg: <Heart/>},
        {name: 'Charts', url: 'winners', svg: <Trophy/>},
    ];

    const profile = [
        {name: 'Your NFT', url: 'customer-tracks', svg: <Album/>},
        {name: 'Your Playlist', url: 'profile/playlists', svg: <Playlist/>},
    ];

    const help = [
        // { name: 'FAQ', url: 'support', svg: <Headphones /> },
        {name: 'Contact', url: 'contacts', svg: <Email/>},
        {name: 'How to play', url: 'how-to-play-and-win', svg: <Info/>},
    ];

    return (
        <>
            {isOpenModal ? null : (
                <nav ref={ref} className={styled.container}>
                    <div className={styled.topWrapper}>
                        <div className={styled.logo} style={{position: 'relative'}}>
                            <img
                                onClick={toggleMenu}
                                src='image/logo-3.svg'
                                alt=''
                            />

                            <span style={{color: 'white', fontSize: '8px', opacity: '0.4', position: 'absolute', bottom: '-10px', left: '20px'}}>beta version 0.152.8</span>
                        </div>
                        <div className={styled.menuWrapper}>
                            <h3 className={styled.title}>Menu</h3>
                            <NavigateElement menuElement={menu}/>
                        </div>

                        {isAuth && (
                            <div className={styled.menuWrapper}>
                                <h3 className={styled.title}>Profile</h3>
                                <NavigateElement menuElement={profile}/>
                            </div>
                        )}
                        <div className={styled.menuWrapper}>
                            <h3 className={styled.title}>Help</h3>
                            <NavigateElement menuElement={help}/>
                        </div>
                    </div>

                    <div className={styled.rightsWrapper}>
                        <div className={styled.textWrapper}>
                            <Link className={styled.text} to='/terms-and-conditions'>
                                Terms & Conditions
                            </Link>
                            <span style={{color: 'white', opacity: '0.35'}}>&nbsp;|&nbsp;</span>
                            <Link className={styled.text} to='/privacy-policy'>
                                Privacy Policy
                            </Link>
                        </div>
                        <div className={styled.iconsShape}>
                            <a href={'https://twitter.com/www_music_bet'} style={{
                                display: 'block',
                                fill: 'white',
                                opacity: '0.6'
                            }}>
                                <Twitter/>
                            </a>
                            <a href={'https://discord.com/channels/1085543688605802527/1085543689528561667'} style={{
                                display: 'block',
                                fill: 'white',
                                opacity: '0.6'
                            }}>
                                <Discord/>
                            </a>
                            <a href={'https://www.reddit.com/user/www_music_bet'} style={{
                                display: 'block',
                                fill: 'white',
                                opacity: '0.6'
                            }}>
                                <Reddit/>
                            </a>
                            <a href={'https://telegram.com'} style={{
                                display: 'block',
                                fill: 'white',
                                opacity: '0.6'
                            }}>
                                <Telegram/>
                            </a>
                        </div>
                        <p className={styled.text}>© 2022 All rights reserved MUSIC.BET</p>
                    </div>
                </nav>
            )}
        </>
    );
};

export {Navigate};

import React, {useEffect, useRef, useState} from "react";
import WaveSurfer from "wavesurfer.js";
import styled from "./PlayerMobile.module.scss";
import {ReactComponent as PlaySvg} from "../../assets/svg/play.svg";
import {ReactComponent as PauseSvg} from "../../assets/svg/pause.svg";
import {ReactComponent as MoreSvg} from "../../assets/svg/moreSvgWhite.svg";
import {ReactComponent as MenuFight} from "../../assets/svg/menuFight.svg";
import {ReactComponent as MenuWinners} from "../../assets/svg/menuWinners.svg";
import {ReactComponent as MenuHome} from "../../assets/svg/menuHome.svg";
import {ReactComponent as MenuNFT} from "../../assets/svg/menuNFT.svg";
import {ReactComponent as MenuProfile} from "../../assets/svg/menuProfile.svg";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {setVolume, setPrevVolume, setCurrentTime, setTotalTime, setPlaying} from "../../store/playerSlice";
import {PlayNow} from "../ProfileInfoMenu/PlayNow/PlayNow";
import Progress from "../ProfileInfoMenu/ProgressBar/ProgressBar";
import {Link, useNavigate} from "react-router-dom";
import {ScrollingText} from "../TextUtils/ScrollingText";

const linGrad = document
    .createElement("canvas")
    .getContext("2d")
    .createLinearGradient(0, 0, 1000, 128);
linGrad.addColorStop(0.0029, "#00E476");
linGrad.addColorStop(0.48, "#00B2FD");
linGrad.addColorStop(1, " #0074F0");

const formWaveSurferOptions = (ref) => ({
    container: ref,
    waveColor: "#eee",
    progressColor: linGrad,
    cursorColor: "$blue",
    barWidth: 2,
    barHeight: 10,
    barRadius: 3,
    cursorWidth: 0,
    responsive: true,
    fillParent: true,
    minPxPerSec: 100,
    height: 40, // If true, normalize by the maximum peak instead of 1.0.
    normalize: true, // Use the PeakCache to improve rendering speed of large waveforms.
    partialRender: true,
    hideScrollbar: true,
    backend: 'MediaElement',
});

const PlayerMobile = () => {
    const dispatch = useAppDispatch();
    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);
    const playing = useAppSelector((state) => state.player.playing);
    const [countStartTries, setCountStartTries] = useState(0);
    const volume = useAppSelector((state) => state.player.volume);
    const [isOpen, setIsOpen] = useState(false);
    const url = useAppSelector((state) => state.player.songUrl);
    const image = useAppSelector((state) => state.player.songImage);
    const title = useAppSelector((state) => state.player.songTitle);
    const singer = useAppSelector((state) => state.player.songSinger);
    const id = useAppSelector((state) => state.player.songId);
    const currentTimeRedux = Number(useAppSelector((state) => state.player.currentTime));
    const totalTimeRedux = Number(useAppSelector((state) => state.player.totalTime));
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setPlaying(false));

        const options = formWaveSurferOptions(waveformRef.current);
        wavesurfer.current = WaveSurfer.create(options);
        if (url !== "") {
            wavesurfer.current.load(url);
        }

        wavesurfer.current.on("ready", function () {
            if (wavesurfer.current) {
                wavesurfer.current.setVolume(volume);
                if (id !== null) {
                    dispatch(setPlaying(true));
                }
            }
        });
        // Removes events, elements and disconnects Web Audio nodes.
        // when component unmount
        return () => wavesurfer.current.destroy();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    useEffect(() => {
        if (playing) {
            const totalTime = wavesurfer.current.getDuration().toFixed(0);
            dispatch(setTotalTime(totalTime));

            const id = setInterval(() => {
                const currentTime = wavesurfer.current.getCurrentTime().toFixed(0);
                dispatch(setCurrentTime(currentTime));
            }, 1000);
            return () => clearInterval(id);
        }
    }, [dispatch, playing]);

    useEffect(() => {
        if (playing && window.WaveSurferAudioContext.state !== 'running') {
            const timeout = setTimeout(() => {
                wavesurfer.current.play().catch(() => {
                });
                setCountStartTries(countStartTries + 1);
            }, 100);

            return () => { clearTimeout(timeout); };
        }
    }, [playing, wavesurfer, countStartTries]);

    useEffect(() => {
        const progress = Number(((currentTimeRedux * 100) / totalTimeRedux).toFixed(0));
        setProgress(progress);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTimeRedux]);

    useEffect(() => {
        if (playing) {
            wavesurfer.current.play().catch(() => {})
        } else {
            wavesurfer.current.pause();
        }
    }, [playing]);

    const handlePlayPause = (e) => {
        e.stopPropagation();
        dispatch(setPlaying(!playing));
    };

    return (<div className={styled.section}>
            {isOpen && <Progress completed={progress}/>}
            <div className={styled.container}>
                <div
                    className={styled.info}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className={styled.song}>
                        <img src={image} alt='' width='47' height='47'/>
                        <div>
                            <h3><ScrollingText text={title ? title : 'Unknown'}/></h3>
                            <p>{singer ? singer : "Unknown"}</p>
                        </div>
                    </div>
                    {!playing ? (<div
                            width='14px'
                            height='21px'
                            onClick={(e) => handlePlayPause(e)}
                            className={styled.controllers}
                        >
                            <PlaySvg width='20' height='21'/>
                        </div>) : (<div
                            width='14px'
                            height='21px'
                            onClick={(e) => handlePlayPause(e)}
                            className={styled.controllers}
                        >
                            <PauseSvg width='20' height='21'/>
                        </div>)}
                    <div className={styled.waveform}>
                        <div id='waveform' ref={waveformRef}/>
                    </div>
                    <div className={styled.more}>
                        <MoreSvg/>
                    </div>
                </div>
                {isOpen ? <>
                        <div className={styled.songInfo}>
                            <div className={styled.songInfoBox}>
                                <p>
                                    Missing a track? Tell us the artist and track name. We'll make a 24hr exclusive NFT for you. Redeem with exclusive rights for 48hrs, then it goes on free sale.
                                    <Link to={'make-offer'} style={{
                                        color: 'white',
                                        fontSize: '14px',
                                        textDecoration: 'underline',
                                        textDecorationColor: 'white',
                                        textDecorationThickness: '2px'
                                    }}>Offer NFT</Link>
                                </p>
                            </div>

                            {/*<div className={styled.songInfoBox}>
            {isDesktop ? (
                <NoteSvg width='16' height='16' />
            ) : (
                <NoteWhiteSvg width='16' height='16' />
            )}
            <p>BUY NOW</p>
          </div>*/}

                        </div>
                    </>

                    : <></>}

                <div className={styled.menu}>
                    <div width='20px' height='20px' onClick={() => {
                        navigate("/buy-nft");
                    }} className={styled.menuIcons}>
                        <MenuNFT width='20px' height='20px'/>
                    </div>
                    <div width='20px' height='20px' onClick={() => {
                        navigate("/category-selection");
                    }} className={styled.menuIcons}>
                        <MenuFight width='20px' height='20px'/>
                    </div>
                    <div width='20px' height='20px' onClick={() => {
                        navigate("/explore");
                    }} className={styled.menuIcons}>
                        <MenuHome width='20px' height='20px'/>
                    </div>
                    <div width='20px' height='20px' onClick={() => {
                        navigate("/winners");
                    }} className={styled.menuIcons}>
                        <MenuWinners width='20px' height='20px'/>
                    </div>
                    <div width='20px' height='20px' onClick={() => {
                        navigate('/customer-tracks');
                    }} className={styled.menuIcons}>
                        <MenuProfile width='20px' height='20px'/>
                    </div>
                </div>
            </div>
        </div>);
};

export {PlayerMobile};

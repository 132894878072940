import React, {useMemo, useRef, useState} from 'react';
import {ReactComponent as User} from '../../assets/favorites/user.svg';
import {ReactComponent as Cup} from '../../assets/favorites/cup.svg';
import {useOutSideClick} from '../../hooks/useOutSideClick';
import styled from './TopWinnerElement.module.scss';
import {ModalPendingBattle} from '../Profile/Battles/ModalPendingBattle/ModalPendingBattle';
import {useMediaQuery} from 'react-responsive';
import {setSongData} from '../../store/playerSlice';
import {useAppDispatch} from "../../hooks/reduxHooks";
import {ScrollingText} from "../TextUtils/ScrollingText";

interface IProps {
    tracks: any;
    nft: any;
    index: number;
}

const TopWinnerElement: React.FC<IProps> = ({nft, index, tracks}) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const modalRef = useRef(null);

    useOutSideClick(modalRef, closeLoginModalMenu, isOpenModal);

    const dispatch = useAppDispatch();

    function closeLoginModalMenu() {
        setIsOpenModal(false);
    }

    const hideRightSide = useMediaQuery({
        query: '(max-width: 430px)',
    });

    const [isOpenMore, setIsOpenMore] = useState(false);

    function closeModal() {
        setIsOpenMore(false);
    }

    const displayAvatar = useMemo(() => {
        return nft?.track?.image_url ? `${nft.track.image_url}` : undefined;
    }, [nft]);

    if (!nft.track) {
        return null;
    }

    return (
        <li className={styled.container}>
            <div className={styled.index}>#{index + 1}</div>

            <div style={{width: "60px", height: "60px", marginRight: "20px", position: "relative"}}>
                <img src={displayAvatar} className={styled.image} style={{width: "60px", height: "60px",}} alt='cover'/>
            </div>

            {!hideRightSide && (
                <>
                    <div className={styled.soundArtistWrapper}>
                        <p className={styled.title}>{nft.track.label}</p>
                        <p className={styled.singer}>{nft.track.name}</p>
                    </div>

                    <article className={styled.infoElementWrapper}>
                        <User className={styled.icon}/>
                        <p className={styled.username}>{nft.customer.username}</p>
                    </article>

                    <article className={styled.infoElementWrapper}>
                        <Cup className={styled.icon}/>
                        <p className={styled.points}>{nft.track.win_count} points</p>
                    </article>
                </>
            )}

            <div className={styled.contentContainer}>
                <div className={styled.soundArtistWrapper}>
                    <p className={styled.singer}>
                        <ScrollingText
                            text={nft.track.name}
                        ></ScrollingText>
                    </p>
                </div>
                <div className={styled.userInfo}>
                    <article className={styled.infoElementWrapper}>
                        <User className={styled.icon}/>
                        <p className={styled.username}>{nft.customer.username}</p>
                    </article>
                </div>

                <article className={styled.infoElementWrapper}>
                    <Cup className={styled.icon}/>
                    <p className={styled.points}>{nft.track.win_count} points</p>
                </article>
            </div>

            {isOpenMore && (
                <div style={{position: 'relative'}} className={styled.modalPlay}>
                    <ModalPendingBattle
                        track={nft}
                        isOpenMore={isOpenMore}
                        closeModal={closeModal}
                        setIsOpenMore={setIsOpenMore}
                    />
                </div>
            )}
        </li>
    );
};

export {TopWinnerElement};

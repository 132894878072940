import styled from './Login.module.scss';
import {useMediaQuery} from "react-responsive";
import {useEffect} from "react";

interface IProps {
    children: JSX.Element;
}

const Login: React.FC<IProps> = ({children}) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 721px)',
    });

    return (
        <div className={styled.container} style={{overflow: "hidden"}}>
            <div className={styled.loginWrapper} style={!isDesktopOrLaptop ? {height: '100vh'} : {height: '606px'}}>
                <img className={styled.logo} src='image/logo.svg' alt='logo'/>
                {/*<h3 className={styled.title}>Sign Up For MUSIC.BET</h3>*/}
                {/*<p className={styled.text}>Stream the music you love and win tokens</p>*/}

                {children}
            </div>

            {/* decoration */}
            {isDesktopOrLaptop && (
                <div className={styled.info}>
                    <div className={styled.absolutePosition}>
                        <div className={styled.txtWrapper}>
                            <img className={styled.icon} src='image/login/musical.svg' alt='music'/>
                            <p>Find or order your favorite songs</p>
                        </div>
                        <div className={styled.txtWrapper}>
                            <img className={styled.icon} src='image/login/playlist.svg' alt='play'/>
                            <p>Complete your strong playlist</p>
                        </div>
                        <div className={styled.txtWrapper}>
                            <img className={styled.icon} src='image/login/money-bag.svg' alt='wallet'/>
                            <p>Win valuable prizes</p>
                        </div>
                        <div className={styled.txtWrapper}>
                            <img className={styled.icon} src='image/login/star.svg' alt='star'/>
                            <p>Play. Earn. Enjoy</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export {Login};

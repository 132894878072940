import {Link, useNavigate} from 'react-router-dom';

import {AnimateBtn} from '../../Buttons/AnimateBtn/AnimateBtn';
import {ReactComponent as Twitter} from '../../../assets/svg/footer-links/twitter.svg';
import {ReactComponent as Discord} from '../../../assets/svg/footer-links/discord.svg';
import {ReactComponent as Reddit} from '../../../assets/svg/footer-links/reddit.svg';
import {ReactComponent as Telegram} from '../../../assets/svg/footer-links/telegram.svg';

import styled from './Footer.module.scss';
import {useMediaQuery} from 'react-responsive';

const Footer = () => {
    const navigate = useNavigate();

    function startToPlayNavigate() {
        navigate('/battle/all-battles?status=0&category_id=1&step=1');
    }

    const isMobile = useMediaQuery({
        query: '(max-width: 430px)',
    });
    const additionalStyles = isMobile ? {height: "45px", width: "255px"} : {};
    return (
        <div>
            {/* top-content */}
            <div className={styled.btnWrapper}>
                {!isMobile && <img className={styled.logo} src='image/howToPlayAndWin/logo-symbol.svg' alt=''/>}
                <div className={styled.btn}>
                    <AnimateBtn additionalStyles={additionalStyles} handleClick={() => startToPlayNavigate()}
                                title='Start To Play'/>
                </div>
            </div>
            <hr className={styled.line}/>
            {/* bottom-content */}
            <div className={styled.footerContainer}>
                <div className={styled.menuWrapper}>
                    <div className={styled.linkWrapper}>
                        <Link className={styled.link} to='/explore'>
                            Home
                        </Link>
                        <Link
                            className={styled.link}
                            to='/battle/all-battles?status=0&category_id=1&step=1'>
                            Battles
                        </Link>
                    </div>
                    <div className={styled.linkWrapper}>
                        <Link className={styled.link} to='/buy-nft'>
                            Buy NFT
                        </Link>
                        <Link className={styled.link} to='/make-offer'>
                            Order NFT
                        </Link>
                    </div>
                    <div className={styled.linkWrapper}>
                        <Link className={styled.link} to='/winners'>
                            Charts
                        </Link>
                        <Link className={styled.link} to='/contacts'>
                            Contact
                        </Link>
                    </div>
                </div>
                <div className={styled.rightsWrapper}>
                    <div className={styled.rights}>
                        <div>
                            <Link className={styled.linkRight} to='/terms-and-conditions'>
                                Terms & Conditions
                            </Link>
                            <span style={{color: 'white', opacity: '0.35'}}>&nbsp;|&nbsp;</span>
                            <Link className={styled.linkRight} to='/privacy-policy'>
                                Privacy Policy
                            </Link>
                        </div>
                        <div>
                            <span className={styled.linkRight}>
                                &copy; 2023 All rights reserved MUSIC.BET
                            </span>
                        </div>
                    </div>
                </div>
                <div className={styled.iconsShape}>
                    <a href={'https://twitter.com/www_music_bet'} style={{
                        display: 'block',
                        fill: 'white',
                        opacity: '0.6'
                    }}>
                        <Twitter/>
                    </a>
                    <a href={'https://discord.com/channels/1085543688605802527/1085543689528561667'} style={{
                        display: 'block',
                        fill: 'white',
                        opacity: '0.6'
                    }}>
                        <Discord/>
                    </a>
                    <a href={'https://www.reddit.com/user/www_music_bet'} style={{
                        display: 'block',
                        fill: 'white',
                        opacity: '0.6'
                    }}>
                        <Reddit/>
                    </a>
                    <a href={'https://telegram.com'} style={{
                        display: 'block',
                        fill: 'white',
                        opacity: '0.6'
                    }}>
                        <Telegram/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export {Footer};

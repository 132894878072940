import {MouseEvent, useEffect, useMemo, useState} from 'react';
import {ReactComponent as LikeSvg} from '../../../assets/svg/likeWhite.svg';
import {ReactComponent as LikeActiveSvg} from '../../../assets/svg/likeWhiteActive.svg';
import {ReactComponent as WinSvg} from '../../../assets/svg/win.svg';
import {ReactComponent as UserSvg} from '../../../assets/svg/user.svg';

import {useAppDispatch, useAppSelector} from '../../../hooks/reduxHooks';

import styled from '../TimerBattle.module.scss';
import {useMediaQuery} from 'react-responsive';
import {battleAPI, profileAPI} from '../../../api/api';
import {toast} from 'react-toastify';
import {ScrollingText} from "../../TextUtils/ScrollingText";
import {closeMobileMenu} from "../../../store/mobileMenuSlice";
import {openLoginModalMenu, toggleLoginScene} from "../../../store/loginModalSlice";
import {setProfile} from "../../../store/profileSlice";

//create general types
interface Song {
    id: number;
    post: string;
    battleId: number;
    title: string;
    singer: string;
    url: string;
    genre: string;
}

interface IProps {
    song: Song;
    isOver: boolean;
    idx: number;
    currentBattleIdx?: number;
    currentSongIdx?: number;
    songRef: any;
    winnerCustomerId: number;
    sumVotes: any;
    mobileClickedSongId: number;
    setMobileClickedSongId: any;
    isVoted: number;
    setIsVoted: any;
    userVotes: any;
}

function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
}

const BattleCard: React.FC<IProps> = ({
                                          song,
                                          isOver,
                                          mobileClickedSongId,
                                          setMobileClickedSongId,
                                          currentBattleIdx,
                                          idx,
                                          currentSongIdx,
                                          songRef,
                                          winnerCustomerId,
                                          sumVotes,
                                          isVoted,
                                          setIsVoted,
                                          userVotes,
                                      }) => {
    const dispatch = useAppDispatch();
    const idSong = useAppSelector((state) => state.battle.songId);
    const step = useAppSelector((state) => state.battle.step);
    const [isWin, setIsWin] = useState(false);
    const [isLiked, setIsLiked] = useState((mobileClickedSongId > 0 ? mobileClickedSongId === song.id : idSong === song.id));
    const [isActive, setIsActive] = useState(false);

    const isLastChance = useAppSelector(state => state.battle.isLastChange);
    const playingSong = useAppSelector(state => state.battle.songId);

    const [currentSongIndex, setCurrentSongIdx] = useState(1);

    useEffect(() => {
        if (mobileClickedSongId > 0 && mobileClickedSongId === song.id) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [mobileClickedSongId]);

    useEffect(() => {
        if (currentSongIdx) {
            setCurrentSongIdx(currentSongIdx);
        }
    }, [currentSongIdx]);

    useEffect(() => {
        if (isLiked) {
            setTimeout(() => {
                setIsLiked(false);
            }, 1000);
        }
    }, [isLiked]);

    useEffect(() => {
        findOutTheWinner();
    }, [isOver, winnerCustomerId]);

    function findOutTheWinner() {
        //@ts-ignore
        if (song.customer.id === winnerCustomerId) {
            setIsWin(true);
        }
    }

    // useEffect(() => {
    //   if (isOver) {
    //     const n = getRandomInt(1, 4);
    //     if (n === 1) {
    //       setIsWin(true);
    //     } else setIsWin(false);
    //   }
    // }, [isOver]);


    const isMobile = useMediaQuery({
        query: '(max-width: 430px)',
    });
    const [zIndex, setZIndex] = useState(5);

    const handleClickOnCard = () => {
        if (isMobile) {
            setMobileClickedSongId(song.id);
        }
    };

    const pulsateLike = useMemo(() => {
        return playingSong === song.id || isLastChance;
    }, [playingSong, isLastChance]);

    function votee(id: number): void {
        battleAPI
            .votesForCard(id)
            .then((res) => {
                setIsVoted();

                profileAPI.getProfile().then((res) => {
                    dispatch(setProfile(res.data));
                });

                return res.data;
            })
            .catch((error) => {
                if ('Unauthenticated.' === error.response.data.message) {
                    dispatch(closeMobileMenu(null));
                    dispatch(toggleLoginScene(null));
                    dispatch(openLoginModalMenu(null));
                } else {
                    toast.error(error.response.data.message);
                }
            });
    }

    const handleLike = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        if (song.id === playingSong || isLastChance) {
            setIsLiked(!isLiked);
            setIsLiked(!isLiked);
            votee(song.battleId);
        }
    };
    // @ts-ignore
    return !isOver ? (
        <li
            onClick={handleClickOnCard}
            ref={songRef}
            className={
                currentSongIndex > idx
                    ? styled.timerSongItemBefore
                    : (isActive ? styled.timerSongItemActive : styled.timerSongItem)
            }
            key={song.id}>
            {/* @ts-ignore */}
            <h3><ScrollingText text={song.name}></ScrollingText></h3>
            {/* @ts-ignore */}
            <p><ScrollingText text={song.artists[0].name}></ScrollingText></p>
            <div className={styled.user}>
                <p>
                    <div className={styled.userSvg} style={{fill: '#2B3134'}}>
                        <UserSvg/>
                    </div>
                    {/* @ts-ignore */}
                    &nbsp;{song.customer.username}
                </p>
            </div>
            <div
                className={styled.songInfoControllers}
                style={{marginTop: '22px'}}
            >
                <p className={styled.songInfoLike}>&nbsp;{isVoted ? 'VOTED' : 'VOTE'}&nbsp;</p>

                {pulsateLike ? (isLiked ? (
                    <div className={styled.songInfoSvg} onClick={handleLike}>
                        <LikeActiveSvg/>
                    </div>
                ) : (
                    <div className={styled.songInfoSvg} onClick={handleLike}>
                        <LikeSvg/>
                    </div>
                )) : (isLiked ? (
                    <div className={styled.inactiveSongInfo} onClick={handleLike}>
                        <LikeActiveSvg/>
                    </div>
                ) : (
                    <div className={styled.inactiveSongInfo} onClick={handleLike}>
                        <LikeSvg/>
                    </div>
                ))}

                <p className={styled.songInfoWin}>&nbsp;{isVoted} TKN&nbsp;</p>
            </div>
        </li>
    ) : (
        <li
            ref={songRef}
            className={
                currentSongIndex > idx
                    ? styled.timerSongItemWinBefore
                    : styled.timerSongItemWin
            }
            style={
                !isWin
                    ? {background: '#FFFFFF', color: '#2B3134'}
                    : {
                        background: 'linear-gradient(135.17deg, #002068 0.29%, #000035 100%)',
                        color: '#ffffff',
                    }
            }
            key={song.id}>
            {/* @ts-ignore */}
            <h3><ScrollingText text={song.name}></ScrollingText></h3>
            {/* @ts-ignore */}
            <p><ScrollingText text={song.artists[0].name}></ScrollingText></p>
            <div className={styled.user}>
                <p>
                    <div className={styled.userSvg} style={{fill: isWin ? '#ffffff' : '#2B3134'}}>
                        <UserSvg/>
                    </div>
                    {/* @ts-ignore */}
                    &nbsp;{song.customer.username}
                </p>
            </div>
            {isWin ? (
                <div
                    className={styled.songInfoControllers}
                    style={{marginTop: '12px'}}
                >
                    <p className={styled.songInfoLike} style={{alignSelf: 'center'}}>
                        BET
                        <br/>
                        {isVoted} TKN
                    </p>
                    <div className={styled.songWinSvg}>
                        <WinSvg/>
                    </div>
                    <p className={styled.songInfoWin} style={{alignSelf: 'center'}}>
                        WON
                        <br/>
                        {(isVoted + (userVotes?.wonSum ?? 0)).toFixed(2).replace('.00', '')} TKN
                    </p>
                </div>
            ) : (
                <div
                    className={styled.songInfoControllers}
                    style={{justifyContent: 'space-around', marginTop: '22px'}}>
                    <p className={styled.songInfoLike}>
                        BET
                        <br/>
                        {isVoted} TKN
                    </p>
                    <p className={styled.songInfoWin}>LOST <br/> {isVoted} TKN</p>
                </div>
            )}
        </li>
    );
};

export {BattleCard};

import {useMemo, useState} from 'react';
import {BattleCard} from './BattleCard/BattleCard';
import {differenceInSeconds} from 'date-fns';
import {Timer} from '../Timer';

import styled from './TimerBattle.module.scss';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import {clearBattle, clearBattleIfNotNull, setBattle} from '../../store/battleSlice';
import {setVolume, setPlaying} from '../../store/playerSlice';
import {useDispatch} from "react-redux";

//create general types
export interface Song {
    id: number;
    post: string;
    title: string;
    singer: string;
    url: string;
    music_categories: any[];
    artists: any[];
    file: string;
    file_url: string;
    battleId: number;
    image: string;
    image_url: string;
    lyrics: string;
    name: string;
    genre: string;
    duration: number;
}

export interface BattleInfo {
    battle?: {
        winner_customer_id: number;
    };
    battleCustomers: any[];
}

interface IProps {
    songs: Song[];
    currentRound?: number;
    currentBattleIdx?: number;
    currentSongIdx?: number;
    songRefs: any;
    mobileClickedSongId: number;
    votes: any;
    userVotes: any;
    setMobileClickedSongId: any;
    battleInfo: BattleInfo;
    timeToFinish: number;
    voted: number[];
    setVoted: any;
}

const TimerBattle: React.FC<IProps> = ({
                                           songs,
                                           currentRound,
                                           mobileClickedSongId,
                                           setMobileClickedSongId,
                                           battleInfo,
                                           currentBattleIdx,
                                           currentSongIdx,
                                           songRefs,
                                           votes,
                                           userVotes,
                                           timeToFinish,
                                           voted,
                                           setVoted,
                                       }) => {
    const [isOver, setTimeIsOver] = useState(false);
    const [shouldStartCountdownSong, setShouldStartCountdownSong] = useState(false);
    const selectedCategoryName = useAppSelector((state) => state.musicCategories.selectedCategoryName);
    const battle = useAppSelector((state) => state.battle.battle);

    const dispatch = useAppDispatch();
    const winnerCustomerId = useMemo(() => {
        if (battleInfo === null || typeof battleInfo === 'undefined') {
            return 0;
        }

        if (battleInfo.battle) {
            setTimeout(() => {
                dispatch(clearBattle());
            }, 5000);
            return battleInfo.battle.winner_customer_id;
        }

        return 0;
    }, [battleInfo]);

    const votess = useMemo(() => {
        if (battleInfo === null || typeof battleInfo === 'undefined') {
            return [];
        }

        if (battleInfo.battle) {
            const votesss: any[] = [];

            battleInfo.battleCustomers.map(bc => {
                const anyVotes = votes.find((aa: any) => aa.track_id === bc.track.id);
                const votesData = {
                    id: bc.track.id,
                    votes: anyVotes ? anyVotes.votes : 0
                };
                votesss.push(votesData);
            });
            return votesss;
        }
    }, [battleInfo]);

    function votesForSong(songId: number) {
        if (votess) {
            return votess.find((votee: { id: number }) => votee.id === songId);
        } else {
            return 0;
        }
    }

    const genreBattleString = useMemo(() => {
        if (battle) {
            if (!battle.category) {
                return 'Mixed';
            }
            return `${battle.category.name} vs ${battle.category.name}`;
        }
    }, [battle]);

    // const totalDuration = songs.reduce(
    //   (result, currentSong) => result + currentSong.duration,
    //   0,
    // );

    //!  TIME FOR THE LAST 60 SECONDS NEED TO TEST
    const testTime = differenceInSeconds(Date.now() + 60000 * timeToFinish, Date.now());
    const time = new Date();

    var t = new Date();
    const dd = t.setSeconds(t.getSeconds() + timeToFinish);

    time.setSeconds(time.getSeconds() + testTime);

    const classSongCardList =
        1 === currentRound
            ? styled.songCardListFirst
            : 2 === currentRound
                ? styled.songCardListSecond
                : styled.songCardListThird;

    const classSongCardIsOver = classSongCardList + ' ' + styled.songCardListOver;

    let audio = new Audio('/audio/The_Final_Countdown_10.mp3');

    if (timeToFinish === 10) {
        if (!shouldStartCountdownSong) {
            setShouldStartCountdownSong(true);

            dispatch(setVolume(0.0));
            dispatch(setPlaying(false));

            audio.play();

            setTimeout(() => {
                dispatch(setVolume(1));
                dispatch(setPlaying(true));
            }, 10500);
        }
    }

    const profile = useAppSelector((state) => state.profile.profile);

    return (
        <>
            <div className={styled.timerContainer}>
                <p className={styled.genre}>{genreBattleString}</p>
                <div className={styled.timerTitleContainer}>
                    {!isOver ? <p>Last chance to win</p> : <p>WINNER</p>}
                </div>
                <p className={styled.timer}>
                    <Timer expiryTimestamp={dd} setTimeIsOver={setTimeIsOver}/>
                </p>
                <div className={styled.listWrapper}>
                    <ul className={!isOver ? classSongCardList : classSongCardIsOver}>
                        {songs.map((song, idx) => (
                            <BattleCard
                                mobileClickedSongId={mobileClickedSongId}
                                setMobileClickedSongId={setMobileClickedSongId}
                                sumVotes={votesForSong(song.id)}
                                winnerCustomerId={winnerCustomerId}
                                songRef={(ref: any) => (songRefs[idx] = ref)}
                                currentBattleIdx={currentBattleIdx}
                                idx={idx}
                                currentSongIdx={currentSongIdx}
                                song={song}
                                key={song.id}
                                isOver={isOver}
                                isVoted={voted[idx]}
                                setIsVoted={
                                    () => {
                                        setVoted((prev) => {
                                            const newVoted = [...prev];
                                            newVoted[idx] += profile?.customer.nominal ?? 1;
                                            return newVoted;
                                        });
                                    }
                                }
                                userVotes={userVotes}
                            />
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
};

export {TimerBattle};

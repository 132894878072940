import {MenuHeader} from './MenuHeader/MenuHeader';
import {PlayNow} from './PlayNow/PlayNow';
import {TopPlayers} from './TopPlayers/TopPlayers';

import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import {setSongData,} from '../../store/playerSlice';

import styled from './ProfileInfoMenu.module.scss';
import {useEffect, useState} from 'react';
import {musicAPI, statisticsAPI} from '../../api/api';
import {useLocation} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';

const ProfileInfoMenu = () => {
    const dispatch = useAppDispatch();
    const idSong = useAppSelector((state) => state.player.songId);
    const [topPlayers, setTopPlayers] = useState([]);
    const [initialSong, setInitialSong] = useState<boolean | null>(null);
    const [isVisibleMenu, setIsVisibleMenu] = useState(true);

    const route = useLocation();

    const hideRightSide = useMediaQuery({
        query: '(max-width: 1600px)',
    });

    useEffect(() => {
        if (route.pathname.includes('how-to-play-and-win')) {
            setIsVisibleMenu(false);
        } else {
            setIsVisibleMenu(true);
        }
    }, [route.pathname]);

    useEffect(() => {
        if (topPlayers.length === 0) {
            statisticsAPI.getTopPlayers().then((res) => {
                setTopPlayers(res);
            });
        }
    }, [topPlayers]);

    const [isSettingNewSong, setIsSettingNewSong] = useState(false);

    useEffect(() => {
        if (initialSong === null) {
            setInitialSong(true);

            musicAPI.getMusic().then((res) => {
                const random = Math.floor(Math.random() * res.data.data.length);
                const track = res.data.data[random];
                let lyrics = [];
                try {
                    lyrics = JSON.parse(track.lyrics.replace(/[\r\n]/g, ''));
                } catch (e) {
                }

                dispatch(
                    setSongData({
                        id: track.id,
                        url: `${track.file_url}`,
                        openseaUrl: `${''}`,
                        image: `${track.image_url}`,
                        title: track.name,
                        singer: track?.artists[0]?.name,
                        lyrics: lyrics,
                        genre:
                            track.music_categories.length > 0 ? track.music_categories[0].name : 'Pop',
                    }),
                );
            });
        }
    }, [initialSong]);

    const playing = useAppSelector((state) => state.player.playing);
    const totalTime = useAppSelector((state) => state.player.totalTime);
    const currentTime = useAppSelector((state) => state.player.currentTime);

    useEffect(() => {
        if (initialSong && playing && (parseInt(totalTime) - 1) === (parseInt(currentTime) - 0)) {
            setInitialSong(null);
        }
    }, [currentTime]);

    if (hideRightSide) {
        return null;
    }
    return !isVisibleMenu ? null : (
        <div className={styled.container}>
            <div className={styled.bgWhite}>
                <MenuHeader/>
                <hr className={styled.line}/>
                {idSong !== null && <PlayNow/>}
                <TopPlayers topPlayers={topPlayers}/>
            </div>
        </div>
    );
};

export {ProfileInfoMenu};

import React, {useEffect, useState} from "react";
import {musicAPI} from "../../api/api";
import {useInfiniteQuery} from "@tanstack/react-query";
import {CurrentCardElement} from "../../components/ExploreSwiper/CurrentCardElement/CurrentCardElement";
import {HeaderGeneral} from "../../components/HeaderGeneral/HeaderGeneral";
import styled from "./BuyNFT.module.scss";
import {useInView} from "react-intersection-observer";
import {useMediaQuery} from "react-responsive";
import {Search} from "../../components/Search/Search";

import { ReactComponent as ArrowDownSvg } from "../../assets/svg/arrowDown.svg";
import { ReactComponent as ArrowUpSvg } from "../../assets/svg/arrowUp.svg";
import { ReactComponent as FilterToggleSvg } from "../../assets/svg/filter-toggle.svg";
import { ReactComponent as DeleteSvg } from "../../assets/svg/delete.svg";

const BuyNFT = () => {
    const fetchTracks = async (
        {
            pageParam = 1,
            name = '',
            category = '',
            sortBy = '',
            sortOrder = '',
            year = '',
        }
    ) => {
        return musicAPI.getTracks(pageParam, name, category, sortBy, sortOrder, year).then((res) => res);
    }

    const [nameSearch, setNameSearch] = useState("");
    const [category, setCategory] = useState<string>("");
    const [year, setYear] = useState<string>("");
    const [categoriesData, setCategoriesData] = useState([]);
    const [yearsData, setYearsData] = useState([]);

    const [sortBy, setSortBy] = React.useState("");
    const [sortOrder, setSortOrder] = React.useState("");
    const [sortByText, setSortByText] = React.useState(null);
    const [isSortOpen, setIsSortOpen] = React.useState(false);

    const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);
    const [isGenreFilterOpen, setIsGenreFilterOpen] = React.useState(false);
    const [isYearFilterOpen, setIsYearFilterOpen] = React.useState(false);

    const [activeFilters, setActiveFilters] = React.useState([]);

    useEffect(() => {
        musicAPI.getCategories().then((res) => setCategoriesData(res));
    }, []);

    useEffect(() => {
        musicAPI.getYears().then((res) => setYearsData(res.data));
    }, []);

    const {ref, inView} = useInView();
    const {
        data,
        fetchNextPage,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: ['tracks', nameSearch, category, sortBy, sortOrder, year],
        queryFn: ({queryKey, pageParam}) => fetchTracks({
            pageParam,
            name: queryKey[1],
            category: queryKey[2],
            sortBy: queryKey[3],
            sortOrder: queryKey[4],
            year: queryKey[5],
        }),
        getNextPageParam: (data) => data.data.current_page !== data.data.last_page ? data.data.current_page + 1 : undefined,
    });

    useEffect(() => {
        if (inView) {
            fetchNextPage()
        }
    }, [inView]);

    const isMobile = useMediaQuery({
        query: '(max-width: 430px)',
    });

    const onSortSelect = (value) => {
        const sortBy = value.split('-')[0];
        const sortOrder = value.split('-')[1];

        setSortBy(sortBy);
        setSortOrder(sortOrder);

        if (value === 'price-asc') {
            setSortByText('Price low to high');
        } else if (value === 'price-desc') {
            setSortByText('Price high to low');
        } else {
            setSortByText('');
        }

        setIsSortOpen(false);
    }

    const addActiveFilter = (filter) => {
        if (-1 !== activeFilters.findIndex(obj => obj.key === filter.key)) {
            return;
        }

        if (filter.key.startsWith('category_')) {
            setCategory(filter.value);

            const index = activeFilters.findIndex(obj => obj.key.startsWith('category_'));

            if (-1 !== index) {
                deleteActiveFilter(activeFilters[index], false);
            }
        } else if (filter.key.startsWith('year_')) {
            setYear(filter.value);

            const index = activeFilters.findIndex(obj => obj.key.startsWith('year_'));

            if (-1 !== index) {
                deleteActiveFilter(activeFilters[index], false);
            }
        }

        setActiveFilters([...activeFilters, filter]);

        // scroll to top of page
        window.scrollTo(0, 0);
    }

    const deleteActiveFilter = (filter, unset) => {
        const index = activeFilters.findIndex(obj => obj.key === filter.key);

        if (unset) {
            if (filter.key.startsWith('category_')) {
                if (category == filter.value) {
                    setCategory('');
                }
            } else if (filter.key.startsWith('year_')) {
                if (year == filter.value) {
                    setYear('');
                }
            }
        }

        if (index !== -1) {
            activeFilters.splice(index, 1);

            setActiveFilters(activeFilters);
        }
    }

    const clearAll = () => {
        setActiveFilters([])
        setCategory('')
        setYear('');
    };

    return (
        <>
            <div style={{width: "100%"}}>
                <HeaderGeneral title='Buy NFT'/>
                {/*<div className={styled.containerBuyNFT}>*/}

                <div className={styled.filters}>
                    {isMobile && <div className={styled.scrollWrapper}>
                        <div className={styled.filtersWrapper}>
                            <span className={styled.filterIcon} onClick={() => {
                                setIsFiltersOpen(!isFiltersOpen);
                                setIsSortOpen(false);

                            }}>
                                <FilterToggleSvg/>
                                <span className={styled.filterIconText}>Filters</span>
                            </span>

                            <span onClick={() => {
                                setIsSortOpen(!isSortOpen);
                                setIsFiltersOpen(false);
                                setIsGenreFilterOpen(false);
                                setIsYearFilterOpen(false);
                            }}>
                                {sortByText ?? 'Sort By'}
                            </span>
                        </div>

                        {isSortOpen &&
                            <div className={styled.sortWrapper}>
                                <ul className={styled.sortList}>
                                    <li className={'selected'} onClick={() => {
                                        onSortSelect('price-asc')
                                    }}>
                                        Price low to high
                                    </li>
                                    <li onClick={() => {
                                        onSortSelect('price-desc')
                                    }}>
                                        Price high to low
                                    </li>
                                </ul>
                                <div style={{clear: 'both'}}/>
                            </div>}

                        {isFiltersOpen &&
                            <>
                                <div className={styled.filterContainer}>
                                    <div className={styled.filterWrapper}>
                                        <button
                                            className={styled.filterButton}
                                            onClick={() => {
                                                setIsGenreFilterOpen(!isGenreFilterOpen);
                                                setIsYearFilterOpen(false);
                                                setIsSortOpen(false);
                                            }}
                                        >
                                            <span style={isGenreFilterOpen ? {
                                                color: '#0074f0',
                                                alignSelf: 'center',
                                            } : {
                                                alignSelf: 'center'
                                            }}>
                                                Genre
                                            </span>
                                            <div style={{alignSelf: 'center'}}>
                                                {!isGenreFilterOpen ? <ArrowDownSvg/> : <ArrowUpSvg/>}
                                            </div>
                                        </button>
                                        <button
                                            className={styled.filterButton}
                                            onClick={() => {
                                                setIsYearFilterOpen(!isYearFilterOpen);
                                                setIsGenreFilterOpen(false);
                                                setIsSortOpen(false);
                                            }}
                                        >
                                            <span style={isYearFilterOpen ? {
                                                color: '#0074f0',
                                                alignSelf: 'center',
                                            } : {
                                                alignSelf: 'center'
                                            }}>
                                                Year
                                            </span>
                                            <div style={{alignSelf: 'center'}}>
                                                {!isYearFilterOpen ? <ArrowDownSvg/> : <ArrowUpSvg/>}
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </>
                        }

                        {isGenreFilterOpen &&
                            <ul className={styled.filterValuesList}>
                                {categoriesData.map((cat) => {
                                    return (
                                        <div
                                            key={cat.id}
                                            className={styled.filterValues}
                                            style={category == cat.id ? {
                                                background: "#0074f0",
                                                color: "white"
                                            } : {
                                                background: "white"
                                            }}
                                            onClick={() => {
                                                addActiveFilter({
                                                    key: `category_${cat.id}`,
                                                    value: cat.id,
                                                    name: cat.name
                                                });
                                                setIsFiltersOpen(!isFiltersOpen);
                                                setIsGenreFilterOpen(!isGenreFilterOpen);
                                            }}
                                        >
                                            {cat.name}
                                        </div>
                                    )
                                })}
                            </ul>
                        }

                        {isYearFilterOpen &&
                            <ul className={styled.filterValuesList}>
                                {yearsData.map((yearData) => {
                                    return (
                                        <div
                                            key={yearData}
                                            className={styled.filterValues}
                                            style={year == yearData ? {
                                                background: "#0074f0",
                                                color: "white"
                                            } : {
                                                background: "white"
                                            }}
                                            onClick={() => {
                                                addActiveFilter({
                                                    key: `year_${yearData}`,
                                                    value: yearData,
                                                    name: yearData
                                                });
                                                setIsFiltersOpen(!isFiltersOpen);
                                                setIsYearFilterOpen(!isYearFilterOpen);
                                            }}
                                        >
                                            {yearData}
                                        </div>
                                    )
                                })}
                            </ul>
                        }
                    </div>}

                    {!isMobile && <div className={styled.scrollWrapper}>
                        <div className={styled.filtersWrapper}>
                            <div className={styled.filterContainer}>
                                <div className={styled.filterWrapper}>
                                    <button
                                        className={styled.filterButton}
                                        onClick={() => {
                                            setIsGenreFilterOpen(!isGenreFilterOpen);
                                            setIsYearFilterOpen(false);
                                            setIsSortOpen(false);
                                        }}
                                    >
                                            <span style={isGenreFilterOpen ? {
                                                color: '#0074f0',
                                                alignSelf: 'center',
                                            } : {
                                                alignSelf: 'center'
                                            }}>
                                                Genre
                                            </span>
                                        <div style={{alignSelf: 'center'}}>
                                            {!isGenreFilterOpen ? <ArrowDownSvg/> : <ArrowUpSvg/>}
                                        </div>
                                    </button>
                                    <button
                                        className={styled.filterButton}
                                        onClick={() => {
                                            setIsYearFilterOpen(!isYearFilterOpen);
                                            setIsGenreFilterOpen(false);
                                            setIsSortOpen(false);
                                        }}
                                    >
                                            <span style={isYearFilterOpen ? {
                                                color: '#0074f0',
                                                alignSelf: 'center',
                                            } : {
                                                alignSelf: 'center'
                                            }}>
                                                Year
                                            </span>
                                        <div style={{alignSelf: 'center'}}>
                                            {!isYearFilterOpen ? <ArrowDownSvg/> : <ArrowUpSvg/>}
                                        </div>
                                    </button>
                                </div>
                            </div>

                            <span style={{cursor: 'pointer'}} onClick={() => {
                                setIsSortOpen(!isSortOpen);
                                setIsFiltersOpen(false);
                                setIsYearFilterOpen(false);
                                setIsYearFilterOpen(false);
                            }}>
                                <span style={{alignSelf: 'center'}}>
                                    {sortByText ?? 'Sort By'}
                                </span>
                                <div style={{alignSelf: 'center', marginLeft: '15px'}}>
                                    {!isSortOpen ? <ArrowDownSvg/> : <ArrowUpSvg/>}
                                </div>
                            </span>
                        </div>

                        {isSortOpen &&
                            <div className={styled.sortWrapper}>
                                <ul className={styled.sortList}>
                                    <li onClick={() => onSortSelect('price-asc')}>
                                        Price low to high
                                    </li>
                                    <li onClick={() => onSortSelect('price-desc')}>
                                        Price high to low
                                    </li>
                                </ul>

                                <div style={{clear: 'both'}}/>
                            </div>}

                        {isGenreFilterOpen &&
                            <ul className={styled.filterValuesList}>
                                {categoriesData.map((cat) => {
                                    return (
                                        <div
                                            key={cat.id}
                                            className={styled.filterValues}
                                            style={category == cat.id ? {
                                                background: "#0074f0",
                                                color: "white"
                                            } : {
                                                background: "white"
                                            }}
                                            onClick={() => {
                                                addActiveFilter({
                                                    key: `category_${cat.id}`,
                                                    value: cat.id,
                                                    name: cat.name
                                                });
                                                setIsFiltersOpen(!isFiltersOpen);
                                                setIsGenreFilterOpen(!isGenreFilterOpen);
                                            }}
                                        >
                                            {cat.name}
                                        </div>
                                    )
                                })}
                            </ul>
                        }

                        {isYearFilterOpen &&
                            <ul className={styled.filterValuesList}>
                                {yearsData.map((yearData) => {
                                    return (
                                        <div
                                            key={yearData}
                                            className={styled.filterValues}
                                            style={year == yearData ? {
                                                background: "#0074f0",
                                                color: "white"
                                            } : {
                                                background: "white"
                                            }}
                                            onClick={() => {
                                                addActiveFilter({
                                                    key: `year_${yearData}`,
                                                    value: yearData,
                                                    name: yearData
                                                });
                                                setIsFiltersOpen(!isFiltersOpen);
                                                setIsYearFilterOpen(!isYearFilterOpen);
                                            }}
                                        >
                                            {yearData}
                                        </div>
                                    )
                                })}
                            </ul>
                        }
                    </div>}

                    {!isMobile && activeFilters.length > 0 && <>
                        <div style={{display: 'flex', justifyContent: 'space-between', padding: '10px 20px'}}>
                            <ul className={styled.selectedValuesList}>
                                {activeFilters.map((activeFilter) => (
                                    <li
                                        key={activeFilter.key}
                                        className={styled.selectedValuesItem}
                                        onClick={() => deleteActiveFilter(activeFilter, true)}
                                    >
                                        <span>{activeFilter.name}</span>
                                        <div className={styled.deleteSvg}>
                                            <DeleteSvg/>
                                        </div>
                                    </li>
                                ))}
                                {activeFilters.length !== 0 && (
                                    <>
                                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                            <button className={styled.selectedClearAll} onClick={clearAll}>
                                                Clear All
                                            </button>
                                        </div>
                                    </>
                                )}
                            </ul>
                            {activeFilters.length !== 0 && (
                                <p style={{alignSelf: 'center'}}>
                                    {data?.pages[0]?.data?.total} items
                                </p>
                            )}
                        </div>
                    </>}

                    <div className={styled.containerSearchAnd}>
                        <Search
                            placeholder='Search for NFT'
                            value={nameSearch}
                            onChange={(e: any) => {
                                setNameSearch(e.target.value);
                            }}
                            borderStyle='1px solid #E2E2E2'
                        />
                    </div>

                    {isMobile && activeFilters.length > 0 && <ul className={styled.selectedValuesList}>
                        {activeFilters.map((activeFilter) => (
                            <li
                                key={activeFilter.key}
                                className={styled.selectedValuesItem}
                                onClick={() => deleteActiveFilter(activeFilter, true)}
                            >
                                <span>{activeFilter.name}</span>
                                <div className={styled.deleteSvg}>
                                    <DeleteSvg/>
                                </div>
                            </li>
                        ))}
                    </ul>}

                    {isMobile && activeFilters.length !== 0 && (
                        <div style={{display: 'flex', justifyContent: 'space-between', padding: '5px 20px 10px'}}>
                            <p style={{alignSelf: 'center'}}>
                                {data?.pages[0]?.data?.total} items
                            </p>
                            <button className={styled.selectedClearAll} onClick={clearAll}>
                                Clear All
                            </button>
                        </div>
                    )}
                </div>

                <div className={styled.wrapper}>
                    <div className={styled.container}>



                        {status === 'loading' ? (
                            (/*<p style={{display: 'none'}}>Loading...</p>*/'')
                        ) : status === 'error' ? (
                                <p>Failed fetching data. Please try again.</p>
                            ) :
                            <>
                                {data.pages.map((group, i) => (
                                    <React.Fragment key={i}>
                                        {group.data.data.map((track, i, row) => (
                                            <CurrentCardElement key={track.id} track={track}/>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </>
                        }
                        <div ref={ref}></div>
                        <div style={{display: 'none'}}>{isFetching && !isFetchingNextPage ? 'Fetching...' : null}</div>
                    </div>
                </div>
            </div>
        </>
    )
};

export {BuyNFT};

import {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {setProfile} from "../../store/profileSlice";
import {HeaderGeneral} from '../HeaderGeneral/HeaderGeneral';
import {ProfileMenu} from '../ProfileMenu/ProfileMenu';
import {ProfileTabs} from '../ProfileTabs/ProfileTabs';
import downArrow from '../../assets/profile/arrow-down.png';
import upArrow from '../../assets/profile/arrow-top.png';
import logo from '../../assets/profile/logo.svg';
import {profileAPI} from '../../api/api';

import styled from './ProfileLayout.module.scss';
import {MovingBackground} from './MovingBackground';
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {MobileProfileMenu} from "../ProfileMenu/MobileProfileMenu";
import {ScrollingText} from "../TextUtils/ScrollingText";

// import { AuthContext } from '../../providers/AuthProvider';

interface IProps {
    children?: any;
}

const ProfileLayout: React.FC<IProps> = ({children}) => {
    const dispatch = useAppDispatch();
    const profile = useAppSelector((state) => state.profile.profile);
    const navigate = useNavigate();
    const [isAuth, setIsAuth] = useState(true);
    const [isKebabMenuOpen, setIsKebabMenuOpen] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('isAuth')) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
    }, []);

    useEffect(() => {
        if (!isAuth) {
            navigate('/how-to-play-and-win');
        }
    }, [isAuth, navigate]);

    useEffect(() => {
        if (null === profile) {
            profileAPI.getProfile().then((res) => {
                dispatch(setProfile(res.data));
            });
        }
    }, [profile]);

    const toggleKebabMenu = () => {
        setIsKebabMenuOpen(!isKebabMenuOpen);
    }

    return (
        <>
            <div className={styled.container}>
                <div className={styled.preHeaderContainer}>
                    <h1 className={styled.title}>Profile page</h1>
                    <div onClick={() => toggleKebabMenu()}>
                        <img src='image/menu-black.svg' alt='menu'/>
                    </div>
                </div>
                {isKebabMenuOpen &&
                    <div className={styled.postHeaderContainer}>
                        <MobileProfileMenu/>
                    </div>
                }
                {!isKebabMenuOpen && <MovingBackground>
                    <div className={styled.profileHeaderWrapper}>
                        <div style={{display: 'flex'}}>
                            <div className={styled.avatarWrapper}>
                                <img className={styled.avatar} src={profile?.customer.avatar_url} alt='avatar'/>
                            </div>

                            <div className={styled.profileInfoContainer}>
                                <div className={styled.profileInfoWrapper}>
                                    <div className={styled.headingsWrapper}>
                                        <h2 className={styled.username}>
                                            <ScrollingText text={profile?.customer.username}/>
                                        </h2>
                                        {/*<p className={styled.description}>Lorem ipsum dolor sit amet</p>*/}
                                    </div>
                                    <div className={styled.statisticsWrapper}>
                                        <div className={styled.statistics}>
                                            <div>
                                                <h2>Ratings</h2>
                                                <p>{profile?.statistics.ratings}</p>
                                            </div>
                                            {/*<div>
                                                <h2>Stars</h2>
                                                <p>123</p>
                                            </div>*/}
                                            <div>
                                                <h2>Tracks</h2>
                                                <p>{profile?.statistics.tracks}</p>
                                            </div>
                                            <div>
                                                <h2>Playlists</h2>
                                                <p>{profile?.statistics.playlists}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styled.tokenInfoWrapperLight}>
                                        <div className={styled.tokenItemWrapper}>
                                            <p style={{color: '#00B2FD'}}>Expense</p>
                                            <span className={styled.iconWrapper}>
                                                <img src={downArrow} alt='down arrow'/>
                                                {profile ? (
                                                    <h4>{(profile?.transactions_sum.refill_transactions_sum * -1)} TKN</h4>
                                                ) : (
                                                    <h4>0 TKN</h4>
                                                )}
                                            </span>
                                        </div>

                                        <div className={styled.tokenItemWrapper}>
                                            <p style={{color: '#04CB6B'}}>Income</p>
                                            <span className={styled.iconWrapper}>
                                                <img src={upArrow} alt={'up arrow'}/>
                                                {profile ? (
                                                    <h4>{(profile?.transactions_sum.win_transactions_sum)} TKN</h4>
                                                ) : (
                                                    <h4>0 TKN</h4>
                                                )}
                                            </span>
                                        </div>

                                        <div className={styled.tokenItemWrapper}>
                                            <p>Account</p>
                                            <span className={styled.iconWrapper}>
                                                <h4>{profile?.account.balance} TKN</h4>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styled.tokenInfoWrapperLight}>
                            <div className={styled.tokenItemWrapper}>
                                <p style={{color: '#00B2FD'}}>Expense</p>
                                <span className={styled.iconWrapper}>
                                    <img src={downArrow} alt='down arrow'/>
                                    {profile ? (
                                        <h4>{(profile?.transactions_sum.refill_transactions_sum * -1)} TKN</h4>
                                    ) : (
                                        <h4>0 TKN</h4>
                                    )}
                                </span>
                            </div>

                            <div className={styled.tokenItemWrapper}>
                                <p style={{color: '#04CB6B'}}>Income</p>
                                <span className={styled.iconWrapper}>
                                    <img src={upArrow} alt={'up arrow'}/>
                                    {profile ? (
                                        <h4>{(profile?.transactions_sum.win_transactions_sum)} TKN</h4>
                                    ) : (
                                        <h4>0 TKN</h4>
                                    )}
                                </span>
                            </div>

                            <div className={styled.tokenItemWrapper}>
                                <p>Account</p>
                                <span className={styled.iconWrapper}>
                                    <h4>{profile?.account.balance} TKN</h4>
                                </span>
                            </div>
                        </div>
                    </div>
                </MovingBackground>}
                <ProfileTabs/>
                <div className={styled.menuWrapper}>
                    <ProfileMenu/>
                </div>
                <div className={styled.children}>
                    {/*!================           pages           ===============!*/}
                    {children}
                </div>
            </div>
        </>
    );
};

export {ProfileLayout};

import {useMediaQuery} from 'react-responsive';
import {toast} from 'react-toastify';
import {useEffect, useState} from 'react';
import {WhiteBtn} from '../../components/Buttons/WhiteBtn/WhiteBtn';
import {Footer} from '../../components/HowToPlayAndWin/Footer/Footer';
import {HeadMenu} from '../../components/HowToPlayAndWin/HeadMenu/HeadMenu';
import {MainContent} from '../../components/HowToPlayAndWin/MainContent/MainContent';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import phone from '../../assets/phone.svg';
import {LoginModal} from '../Login/LoginModal/LoginModal';
import {openLoginModalMenu} from '../../store/loginModalSlice';
import {authAPI} from '../../api/api';
import {userLogOut} from '../../store/isAuthSlice';

import styled from './PrivacyPolicy.module.scss';

const PrivacyPolicy = () => {
    const dispatch = useAppDispatch();
    const [isAuth, setIsAuth] = useState(false);
    const isOpenModalLogin = useAppSelector((state) => state.modalLogin.isOpen);

    useEffect(() => {
        if (localStorage.getItem('isAuth')) setIsAuth(true);
        if (!localStorage.getItem('isAuth')) setIsAuth(false);
    }, []);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 620px)',
    });

    const isDesktop = useMediaQuery({
        query: '(min-width: 1201px)',
    });

    function scroll() {
        window.scrollTo(0, 900);
    }

    function openModal() {
        dispatch(openLoginModalMenu(null));
    }

    async function logout() {
        authAPI
            .logOut()
            .then(() => {
                dispatch(userLogOut(null));
            })
            .then(() => setIsAuth(false))
            .catch(() => {
                toast.error('the server is not responding, try again');
            });
    }

    useEffect(() => {
        if (isOpenModalLogin) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isOpenModalLogin]);

    return (
        <div className={styled.containerWrapper}>
            {isOpenModalLogin && <LoginModal/>}

            <div className={styled.bgWrapper}>
                <div className={styled.bgTop}></div>
                <div className={styled.imagesBg}></div>
                <img className={styled.ph} src={phone} alt='phone'/>
            </div>
            <div className={styled.container}>
                {isDesktop && <div className={styled.menu}>
                    <div style={{width: '220px', textAlign: 'left'}}>
                        <img className={styled.logo} src='image/logo.svg' alt='logo'/>
                    </div>
                    <HeadMenu/>
                    <div style={{width: '220px', textAlign: 'right'}}>
                        {!isAuth && <WhiteBtn handleClick={() => openModal()} title='Sign Up Free'/>}
                        {isAuth && <WhiteBtn handleClick={() => logout()} title='logout'/>}
                    </div>
                </div>}

                <div className={styled.afterMainWrapper}>
                    {/*<img className={styled.tv} src='image/howToPlayAndWin/tv.png' alt='tv' />*/}

                    {/*<h2 className={styled.headerSecondAsphalt}>We Give Everyone the</h2>*/}

                    <h2 className={styled.headerSecondWhite}>
                        PRIVACY POLICY
                    </h2>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        1. Preface
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        Music.bet is a music entertainment platform where music lovers can ("you" or "user") can cheer
                        on and support their favorite musicians and artists.
                        We respect your privacy and are committed to providing a secure environment for our Users.
                        This privacy policy ("Politics ”) is intended to provide you with important information about
                        how
                        and why your data may be collected and processed by the company.Music.bet (« We », « us » or
                        «Music.bet "). All Personal Data (defined below) will be processed in accordance with applicable
                        law, including the European Union General Data Protection Regulation, US federal and state
                        laws governing the protection of personal data, and other applicable data protection laws. This
                        Policy is part Terms and Conditions expressly stated therein. Capitalized terms and not
                        expressly defined here have the meaning given to them in the Terms.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        2. Collected data and goals
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        We collect and process a limited amount of Personal Data for certain purposes, such as to
                        provide you with access to the Services, to make the Services interesting and easy to use, to
                        improve your experience, and to prevent fraud. Whenever we collect your Personal Data and for
                        any purpose, we strive to always collect the minimum amount of Data necessary for the relevant
                        purpose.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        This section provides further information about what Personal Data we collect, why we process
                        that Personal Data, and the legal basis for each specific processing purpose.
                    </p>

                    <ul className={styled.text} style={{textAlign: 'left'}}>
                        <li>
                            Account Information.Music.bet collects and processes Data to enable you to create and
                            access your user account. In order to manage your user account from creation to
                            deletion, you must provide us with some information about yourself (for example, your
                            first name, last name, username, email address, and for some transactions, your phone
                            number for identity verification purposes). You will also be able to store your personal
                            settings, preferences and favorite teams or players in your shared user account and/or
                            across your browser and device. To save themMusic.bet associate your choice with your
                            user account, technical identifiers, browser and/or device.
                        </li>
                        <li>
                            The legal basis for this processing is the contract. We require this data in order to
                            create and maintain your user account, to comply with the Terms and Conditions
                            and any other applicable Additional Terms that you agreed to when registering
                            your user account.
                        </li>
                        <li>
                            Website use.Music.bet collects and processes some Data to understand how Users
                            actually interact with the Services and to improve the User experience. This data consists
                            of various browsing information obtained from cookies or technical mobile identifiers (see
                            below).Cookie Policy ), some of which may include personal information.
                        </li>
                        <li>
                            The legal basis for such processing depends on your location and applicable law.
                            In countries where the use of cookies is subject to the prior consent of
                            Users,Music.bet processes this Data based on your consent. In other
                            countriesMusic.bet processes this Data based on (i) its legitimate interests,
                            including understanding how its Services are used by Users; and improve the
                            Services, if necessary, or (ii) your consent, depending on the purpose of the
                            cookie.
                        </li>
                        <li>
                            Access to and Use of the Services.Music.bet collects and processes Personal Data to
                            enable you to access and use various parts of the Services, including to facilitate the
                            purchase of Collectibles, participation in the Games, or participation in our referral
                            programs.
                        </li>
                        <li>
                            To facilitate participation in the Tournament , send prizes to winners, publish
                            leaderboards and more,Music.bet processes your username, wallet address and
                            the prizes you have won (where applicable).Music.bet may publish your
                            username and the prizes you have won on its website as part of a leaderboard
                            presenting tournament results.
                        </li>
                        <li>
                            To allow participation in our referral program, Music.bet collects and
                            processes your email address, username and wallet address.
                        </li>
                        <li>
                            The legal basis for this processing is the contract. We require this data in order to
                            comply with the Terms and Conditions and any other applicable Additional Terms
                            that you agreed to when registering your user account.
                        </li>
                        <li>
                            Compliance with the terms; Website and account security. Music.bet collects certain
                            device and browser information (User Agent), browsing data, including IP address (from
                            which information about your country/state of location can be inferred), account
                            information (including your email address), and transaction details to maintain the
                            security of the Services and your user account, and detect, prevent, investigate and/or
                            monitor any attempt to violate our Terms and Conditions or any other Applicable Terms,
                            such as through malicious hacking. During the investigationMusic.bet may take additional
                            security measures, including, but not limited to, requesting additional supporting
                            documents and/or information, as well as suspending or canceling the relevant
                            transaction if it cannot be verified. If payment fraud has been investigated and confirmed,
                        </li>
                        <li>
                            The legal basis for such processing is the legitimate interestsMusic.bet. Music.bet
                            has a legitimate interest in detecting, preventing and investigating fraud to ensure
                            fair play for all users and to protect its Services and Users from possible credit
                            card fraud.
                        </li>
                        <li>
                            User requests and communication . Music.bet collects and processes Personal Data
                            such as your username, contact details and the content of your message (to the extent
                            that such content is considered Personal Data) in managing and following up on any
                            questions and/or queries you may submit through our help . Center or other method.
                        </li>
                        <li>
                            The legal basis for this processing is the contract. Support is an integral part of
                            the Services we provide, so we require this data to comply with the Terms and
                            Conditions and any other applicable Additional Terms that you agreed to when
                            registering your user account.
                        </li>
                    </ul>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        3. Blockchain technology and your data
                    </p>

                    <ul className={styled.text} style={{textAlign: 'left'}}>
                        <li>
                            NFT.Blockchain is at the heart of the Services provided by Music.bet. This technology, in
                            the form of non-fungible tokens (NFTs), ensures the verifiable authenticity, rarity and
                            compatibility of your collectibles.
                        </li>
                        <li>
                            Your data.Address of your wallet and information related to the purchase of game
                            tokens, withdrawals, connection of your profile to an external wallet is stored on our
                            servers, which is committed to strict confidentiality obligations. As stated in our terms
                            and
                            conditions, Music.bet will not be responsible for any third party content or services and
                            disclaims any and all liability for any and all services provided to the User by a third
                            party
                            service provider.
                        </li>
                    </ul>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        4. Data storage
                    </p>

                    <ul className={styled.text} style={{textAlign: 'left'}}>
                        <li>
                            Account Information . Music.bet will retain your Personal Data until your account is
                            closed. However, if required or permitted by applicable law, ifMusic.bet you need to retain
                            certain Personal Data after the date your account is closed, the maximum applicable
                            retention period will apply in accordance with the applicable statutory limitation periods.
                            This period will not exceed the period for which the Company must retain the Data.
                        </li>
                        <li>
                            Website use . Personal data processed by the companyMusic.bet or on its behalf for the
                            purposes of analytics or other research or analysis, will not be stored by the
                            companyMusic.bet after completion of such studies and preparation of relevant reports,
                            results and/or conclusions.
                        </li>
                        <li>
                            Access to the Services . Personal data processed for the use of the Services will only
                            be kept for fifteen (15) months from the date of the relevant action.
                        </li>
                        <li>
                            Compliance. Website and account security. Personal data processed for fraud
                            prevention will be kept for three (3) years after the relevant data is included in the
                            warning list. Personal data processed for security reasons will be kept for five (5) years
                            from the last login or suspicious activity.
                        </li>
                        <li>
                            User requests and communication . To manage your questions and/or
                            requestsMusic.bet will retain your Personal Data for no more than three (3) years from
                            the date you last contacted us.
                        </li>
                    </ul>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        Notwithstanding the above,Music.bet may retain some Personal Data for a longer period of time
                        in accordance with its legitimate interests and applicable legal obligations. After the
                        respective
                        storage period has elapsedMusic.bet delete your Personal Data or apply adapted measures to
                        the relevant data so that you can no longer be identified. IfMusic.bet concludes that it is not
                        necessary to store your Personal Data in its active database, it will archive the relevant Data.
                        For
                        all active and archived DataMusic.bet ensures that access to relevant Data is limited to a
                        limited
                        number of persons who actually need to access it.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        5. Processors and data transfer
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        Music.bet may use third party service providers and other third parties ("Processors") to
                        facilitate,
                        maintain, improve, analyze and protect the Website and Services. Processors may have access
                        to Personal Data for the sole and exclusive purpose of carrying out the processing activities
                        assigned to the relevant ProcessorMusic.bet. Music.bet ensures that service providers have
                        sufficient guarantees to carry out processing operations and comply with applicable laws and
                        regulations.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        Personal data may be processed outside the European Economic Area (“EEA”). When Data is
                        transferred outside the EEA,Music.bet takes all necessary precautions and, alternatively or in
                        combination: (i) guarantees that the European Commission has made an adequate decision in
                        respect of the country of destination, (ii) that the model contractual clauses adopted by the
                        European Commission or the supervisory authority have been signed with the recipient, (iii) that
                        the recipient adhered to an approved code of conduct or certification mechanism, etc.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        6. Data exchange
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        The Company shares your Personal Data with third party service providers and vendors who
                        assist the Company in achieving the purposes set out in this Policy.
                        Third Party Services
                    </p>

                    <ul className={styled.text} style={{textAlign: 'left'}}>
                        <li>
                            Some content or features of the Website are provided by third party service providers. To
                            facilitate your access to such content and/or features,Music.bet may share some of your
                            personal data with third party services and providers. A third party service may ask you
                            for additional information. Whether integrated directly into the Website, these Third Party
                            Services collect and process any Data they receive in accordance with their respective
                            privacy policies under the sole responsibility of that provider.
                        </li>
                        <li>
                            Please note that in contractual agreements betweenMusic.bet and our third party service
                            providers (such as payment service providers or marketing partners) indicate that
                            andMusic.bet, and the third party service provider will act as an "independent controller"
                            as defined by the GDPR (or "Business" as defined by the CCPA) and must comply with
                            all applicable data protection laws and regulations. Please see the respective privacy
                            policy of the respective Third Party Service for more information.
                            Judicial and state bodies
                        </li>
                        <li>
                            If required by law or pursuant to a valid court order and to the extent permitted by
                            law,Music.bet may share your Personal Data with competent courts and/or any other
                            government and/or public authorities requesting access to your Personal Data.
                            We take sharing your data seriously. Whenever we share your Personal Data with any of the
                            above recipients, we do so strictly on a need-to-know basis and only as necessary to fulfill
                            specific processing purposes.
                        </li>
                    </ul>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        7. Use by children
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        The Website, App and Services are intended for use by persons eighteen (18) years of age or
                        older. In accordance with conditions , the Website and the Services are not available to Users
                        under 18 years of age.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        8. Your Rights
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        Most applicable data privacy laws (including the GDPR of the European Union and the laws of
                        certain US states, including but not limited to the CCPA) provide individuals with rights in
                        relation
                        to their Data and how that Data is processed. The section below is intended to inform you of
                        your
                        rights in connection with your Personal Data. Please note that all inquiries under this section
                        will
                        be processed in accordance with applicable European laws (in particular GDPR) regardless of
                        your location or country of residence, but our responses may be adapted to meet local law
                        requirements.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        You have the right to access, rectify, move, delete and restrict the processing of your Personal
                        Data.
                    </p>
                    <p className={styled.text} style={{textAlign: 'left'}}>
                        Requests
                    </p>


                    <ul className={styled.text} style={{textAlign: 'left'}}>
                        <li>
                            To exercise your rights or make a request regarding data privacy inMusic.bet, you should
                            send the request, accompanied by an identity document, by info@music.bet
                        </li>
                        <li>
                            Music.bet will endeavor to respond to your request as soon as possible, but no later than
                            within one (1) month of receipt of the request. For complex requests, we reserve the right
                            to extend this period up to three (3) months.
                            Deleting a user account
                        </li>
                        <li>
                            If you intend to request deletion of your personal information, you may use the method
                            described above or simply request deletion of your user account through your user
                            account settings as described in the terms and conditions.Music.bet.
                        </li>
                        <li>
                            Deleting your account will permanently delete the Personal Data associated with the
                            account. To enforce your rights without changing the history and traceability of every
                            collectible you have owned, the username associated with the ownership of those
                            collectibles will be pseudonymized and replaced with a random set of alphanumeric
                            characters.
                        </li>
                        <li>
                            Any collectibles, game tokens stored in your profile that have not been transferred from
                            your account on the date of deletion will be permanently deleted.Music.bet is not
                            responsible for the permanent loss of any collectibles and/or game tokens that were not
                            saved prior to the deletion request.
                            Protecting your personal data and complying with the applicable data protection legal
                            framework
                            are two top prioritiesMusic.betand we need your help to succeed. By agreeing to this Policy,
                            you
                            agree to update your Data onMusic.bet and provide only accurate and up-to-date Personal
                            Data.
                            In addition, if you send us any information that would allow us to directly or indirectly
                            identify
                            any
                            other individuals, you represent and warrant that this Policy was provided to such other
                            individuals prior to the transmission of that information and, that to the extent
                            applicable, have
                            consented to the processing of their Data.
                        </li>
                    </ul>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        9. Cookies
                    </p>

                    <ul className={styled.text} style={{textAlign: 'left'}}>
                        <li>
                            When you browse our Website, cookies are placed on your device either directly or after
                            obtaining your consent, where required in accordance with the cookie regulations.
                        </li>
                        <li>
                            Cookies are pieces of information placed on the Internet user's end equipment by the
                            server of the website being visited. They are used by a website to send information to the
                            Internet user's browser and allow that browser to send information back to the original
                            website (such as a session ID or language choice).
                        </li>
                        <li>
                            Only the sender of a cookie can read or change the information it contains.
                        </li>
                        <li>
                            There are different types of cookies:
                        </li>
                        <li>
                            session cookies, which disappear as soon as you leave the Website;
                        </li>
                        <li>
                            persistent cookies that remain on your device until they expire or until you delete
                            them using your browser features.
                        </li>
                        <li>
                            You are aware that cookies may be set on your end equipment during your visits to the
                            Website.
                        </li>
                        <li>
                            To learn more about the use of cookies on our Website, please see our cookie policy
                            here:PoliticsMusic.bet regarding cookies .
                        </li>
                    </ul>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        10. Safety
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        CompanyMusic.bet has implemented and maintains measures to protect the confidentiality,
                        security and integrity of your user account and Personal Data from unauthorized access and
                        disclosure, modification, alteration, damage, accidental loss or accidental or unlawful
                        destruction,
                        and from any other form of unauthorized access. unauthorized processing or disclosure.
                        We encourage you to follow best security practices, including keeping your account information
                        secure and keeping your account information private. Please note that our backup services will
                        not back up your data to personal storage, local storage, or disk; we recommend that you use an
                        additional backup source and/or other best practices to save your data. In addition, we
                        recommend that you enable two-factor authentication, choose a strong password, and/or use a
                        password manager for any and all password-protected features of your user account and your
                        user account itself.
                        Music.bet recognizes the importance and value of the efforts of security researchers to help
                        keep
                        our community safe.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        11. Updates to this Privacy Policy
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        Music.bet reserves the right to change this Policy as necessary, as the Service develops or in
                        accordance with the requirements of applicable law. You will be notified of any material changes
                        to the Policy. By using the Services, you are deemed to have accepted the terms of the Policy
                        posted on our Website.
                    </p>

                    <Footer/>
                </div>
            </div>
        </div>
    );
};

export {PrivacyPolicy};

import {useEffect, useRef, useState} from 'react';
import {useMediaQuery} from 'react-responsive';

import {CurrentCardElement} from '../CurrentCardElement/CurrentCardElement';
import 'swiper/css/navigation';
import 'swiper/css';

import styled from './SwiperExplore.module.scss';
import {musicAPI} from '../../../api/api';
import {useNavigate} from 'react-router-dom';

interface Track {
    id: any;
}

const SwiperExplore = () => {
    const navigate = useNavigate();
    const [tracks, setTracks] = useState<Track[]>([]);

    useEffect(() => {
        if (tracks.length === 0) {
            musicAPI.getMusic().then(({data: {data}}) => {
                if (data.length > 5) {
                    setTracks([data[0], data[1], data[2], data[3], data[4], data[5], data[6]])
                }
            });
        }
    }, []);

    function navigateToBuyMore() {
        navigate('/buy-nft');
    }

    return (
        <div className={styled.container}>
            <div className={styled.textWrapper}>
                <div className={styled.offers}>Buy NFT</div>
                <div className={styled.seeMore} onClick={navigateToBuyMore}>See more</div>
            </div>

            <div className={styled.cardsContainer}>
                {tracks.map(track => {
                    return (
                        <CurrentCardElement key={track.id} track={track}/>
                    );
                })}
            </div>
        </div>
    );
};

export {SwiperExplore};

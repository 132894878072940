import {BattleItem} from './BattleItem.tsx/BattleItem';
import styled from './BattleList.module.scss';

interface IProps {
    currentRound?: number;
    battle: any;
}

const BattleList: React.FC<IProps> = ({
                                          currentRound,
                                          battle,
                                      }) => {
    return (
        battle && (
            <ul
                className={
                    1 === currentRound
                        ? styled.songBattleListFirst
                        : 2 === currentRound
                            ? styled.songBattleListSecond
                            : styled.songBattleListThird
                }>
                <BattleItem
                    key={battle.id}
                    battleId={battle.id}
                    currentRound={currentRound}
                    battle={battle}
                />
            </ul>
        )
    );
};

export {BattleList};

import {useEffect, useMemo, useRef, useState} from 'react';
import {useAppSelector} from '../../hooks/reduxHooks';
import {SongInfo} from './SongInfo/SongInfo';
import {SongPoster} from './SongPoster/SongPoster';
import styled from './SongCard.module.scss';
import {useMediaQuery} from 'react-responsive';

interface Song {
    id: number;
    post: string;
    title: string;
    singer: string;
    url: string;
    genre: string;
}

interface IProps {
    // song: Song;
    song: any;
    currentRound: any;
    idx: number;
    currentSongIdx?: number;
    isVoted: number;
    setIsVoted: any;
}

const SongCard: React.FC<IProps> = ({
                                        song,
                                        currentRound,
                                        idx,
                                        currentSongIdx,
                                        isVoted,
                                        setIsVoted,
                                    }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const id = useAppSelector((state) => state.player.songId);
    const selectedCategoryId = useAppSelector((state) => state.musicCategories.selectedCategory);
    const [initialZindex, setInitialZindex] = useState(null);
    const containerRef = useRef<any>(null);

    useEffect(() => {
        if (containerRef.current && initialZindex === null) {
            setInitialZindex(containerRef.current.style.zIndex);
        }
    }, [containerRef, initialZindex]);

    const [currentSongIndex, setCurrentSongIdx] = useState(1);

    const songGenre = useMemo(() => {
        return song.music_categories[0].name;
    }, [selectedCategoryId, song.music_categories]);

    useEffect(() => {
        if (currentSongIdx) {
            setCurrentSongIdx(currentSongIdx);
        }
    }, [currentSongIdx]);

    const isMobile = useMediaQuery({
        query: '(max-width: 720px)',
    });

    useEffect(() => {
        if (id === song.id) {
            if (containerRef.current) {
                containerRef.current.style.zIndex = 9;
                containerRef.current.style.opacity = 1;
            }
            setIsPlaying(true);
        } else {
            containerRef.current.style.zIndex = initialZindex;
            containerRef.current.style.opacity = 0.6;
            setIsPlaying(false);
        }
    }, [id, song.id]);

    return (
        <li
            ref={containerRef}
            className={
                currentSongIndex > idx && isMobile
                    ? styled.songCardItemBefore
                    : styled.songCardItem
            }>
            <div
                className={isPlaying ? styled.containerActive : styled.container}
            >
                <SongPoster
                    isPlaying={isPlaying}
                    genre={songGenre}
                    handlePlay={() => {}}
                    currentRound={currentRound}
                    songData={song}
                />
                <SongInfo
                    song={song}
                    title={song.name}
                    singer={song.artists[0].name}
                    id={song.battleId}
                    isVoted={isVoted}
                    setIsVoted={setIsVoted}
                />
            </div>
        </li>
    );
};

export {SongCard};

import {ReactComponent as PointsSvg} from '../../../assets/svg/points.svg';
import {ReactComponent as GenreSvg} from '../../../assets/svg/genre.svg';
import styled from '../SongCard.module.scss';
import {useMemo} from 'react';
import {useAppSelector} from '../../../hooks/reduxHooks';

interface IProps {
    isPlaying: boolean;
    genre: string;
    handlePlay: () => void;
    currentRound: any;
    songData: any;
}

const SongPoster: React.FC<IProps> = ({
                                          isPlaying,
                                          genre,
                                          handlePlay,
                                          currentRound,
                                          songData,
                                      }) => {
    const currentlyPlayingSongDuration = useAppSelector((state) => state.battle.currentlyPlayingSongDuration);
    const currentlyPlayingSong = useAppSelector((state) => state.battle.songId);

    const duration = useMemo(() => {
        if (songData.id === currentlyPlayingSong) {
            return currentlyPlayingSongDuration;
        }

        return 0;
    }, [currentlyPlayingSongDuration, currentlyPlayingSong, songData]);

    function formattingTime(data: number): any {
        let minutes = 0;

        for (let i = data; i >= 60; i = i - 60) {
            minutes += 1;
        }

        const seconds = data - minutes * 60;
        const displayMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const displaySeconds = seconds < 10 ? `0${seconds}` : seconds;

        return `${displayMinutes}:${displaySeconds}`;
    }

    const bgImage = useMemo(() => {
        return `${songData.image_url}`;
    }, [songData]);
    return (
        <div
            style={{backgroundImage: `url("${bgImage}")`, backgroundPosition: "center", backgroundSize: "cover"}}
            className={styled.songContainer}
        >
            <div className={styled.songContainerFlex}>
                <div className={styled.songPostPoints}>
                    <PointsSvg className={styled.songPostSvg}/>
                    <p>{songData.win_count} wins</p>
                </div>
                <div className={styled.centerBox} onClick={handlePlay}>
                    {isPlaying ? (
                        <div className={styled.songPostTotalTime}>
                            {formattingTime(duration)}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <div className={styled.songPostGenre}>
                    <GenreSvg className={styled.songPostSvg}/>
                    <p>{genre ? genre : 'others'}</p>
                </div>
            </div>
        </div>
    );
};
export {SongPoster};

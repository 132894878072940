import {AnimateBtn} from '../../components/Buttons/AnimateBtn/AnimateBtn';
import {AnimateBtnWhite} from '../../components/Buttons/AnimateBtnWhite/AnimateBtnWhite';
import {TransparentBtn} from '../../components/Buttons/TransparentBtn/TransparentBtn';
import {HeaderGeneral} from '../../components/HeaderGeneral/HeaderGeneral';
import {ReactComponent as Path1Svg} from './Path1.svg';
import {ReactComponent as Path2Svg} from './Path2.svg';
import {ReactComponent as Path3Svg} from './Path3.svg';
import {ReactComponent as Path4Svg} from './Path4.svg';
import styled from './NTFBattles.module.scss';
import {MovingBackground} from '../../components/ProfileLayout/MovingBackground';
import {NotificationT} from '../../components/ToastifyNot/NotificationToastify';
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {useMediaQuery} from "react-responsive";
import {useNavigate, useSearchParams} from "react-router-dom";
import {battleAPI, musicAPI} from "../../api/api";
import {loadMusicCategories} from "../../store/musicCategoriesSlice";
import {BattleList} from "../../components/BattleList/BattleList";
import {closeMobileMenu} from "../../store/mobileMenuSlice";
import {openLoginModalMenu, toggleLoginScene} from "../../store/loginModalSlice";
import {setBattle} from "../../store/battleSlice";

const NFTBattles = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const isDesktop = useMediaQuery({query: '(min-width: 1201px)'});

    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('isAuth')) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
    }, []);

    const categories = useAppSelector((state) => state.musicCategories.categories);

    useEffect(() => {
        if (categories.length > 0) {
            return;
        }

        musicAPI.getCategories().then(categoriesResponse => {
            dispatch(loadMusicCategories(categoriesResponse));
        });
    }, [categories]);

    const [searchParams, setSearchParams] = useSearchParams();
    const [currentCategory] = useState<number>(parseInt(searchParams.get('category_id')));
    const [currentStep, setCurrentStep] = useState<number>(parseInt(searchParams.get('step')));

    useEffect(() => {
        if (currentCategory.toString() === searchParams.get('category_id') && currentStep.toString() === searchParams.get('step')) {
            return;
        }

        setSearchParams({status: '0', category_id: currentCategory.toString(), step: currentStep.toString()});
    }, [currentCategory, currentStep]);

    let selectedCategoryName = 'Mixed';

    // search in categories for a category where category.id === currentCategory
    categories.forEach(category => {
        if (currentCategory === category.id) {
            // set selectedCategoryName to category.name
            selectedCategoryName = category.name;
        }
    });

    const [currentBattle, setCurrentBattle] = useState<any>(null);

    const fetchCurrentBattle = () => {
        battleAPI
            .showBattleWithFilter(currentCategory, currentStep)
            .then((res) => {
                if (res.data.data.length !== 0) {
                    setCurrentBattle(res.data.data[0]);
                } else {
                    setCurrentBattle(null)
                }
            })
            .catch(() => {});
    };

    useEffect(fetchCurrentBattle, [currentStep, currentCategory]);

    useEffect(() => {
        if (currentBattle && currentBattle.is_count_tme) {
            const date_end_utc = new Date(Date.parse(currentBattle.date_end.replace(' ', 'T') + 'Z')); // ISO8601 format with 'Z' for UTC timezone

            let milliseconds = Math.abs(date_end_utc.getTime() - new Date().getTime());

            if (milliseconds < 0) {
                milliseconds = 0;
            }

            milliseconds += 15 * 1000;

            const timeout = setTimeout(fetchCurrentBattle, milliseconds);

            return () => { clearTimeout(timeout); };
        }

        let milliseconds = 1000;

        const interval = setInterval(fetchCurrentBattle, milliseconds);

        return () => { clearInterval(interval); };

    }, [currentBattle]);

    useEffect(() => {
        dispatch(setBattle(currentBattle));
    }, [currentBattle]);

    if (0 === categories.length) {
        return;
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingBottom: '200px'}}>
            <HeaderGeneral title='Battles'/>
            <NotificationT/>
            <MovingBackground customBackground={true}>
                <div className={styled.bgMain}>
                    <div className={styled.bg}>
                        <div className={styled.flexMainContainer}>
                            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                                <ul className={styled.roundList}>
                                    {[1, 2, 3].map((step) => (
                                        <li
                                            onClick={() => setCurrentStep(step)}
                                            className={styled.roundItem}
                                            key={step}
                                            style={currentStep === step ? {opacity: 1} : {opacity: 0.5}}>
                                            <div
                                                className={styled.round}
                                                style={
                                                    step === 1
                                                        ? {backgroundColor: '#ffffff', color: '#001542'}
                                                        : step === 2
                                                            ? {backgroundColor: '#0074f0'}
                                                            : {backgroundColor: '#000035'}
                                                }>
                                                <span>{step}</span>
                                            </div>
                                            <div className={styled.roundText}>Round</div>
                                        </li>
                                    ))}
                                </ul>
                                <div className={styled.info}>
                                    <h2>{selectedCategoryName} battles</h2>
                                </div>
                            </div>
                            <div className={styled.currentOptions}>
                                <div
                                    style={{
                                        position: 'relative',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-end',
                                    }}>
                                    <span className={styled.currentRoundNumber}>{currentStep.toString()}</span>
                                </div>
                                <span className={styled.currentRoundText}>Round</span>
                            </div>
                        </div>
                        <div className={styled.animatedArrows}>
                            <div>
                                <Path1Svg/>
                                <Path2Svg/>
                                <Path3Svg/>
                                <Path4Svg/>
                            </div>
                        </div>
                        <div className={styled.navigate} style={{marginTop: '10px'}}>
                            <div className={styled.buttonsContainer}>
                                {isDesktop && <>
                                    <AnimateBtn title='Buy NFT' handleClick={() => {
                                        navigate('/buy-nft')
                                    }}/>
                                    {/*<AnimateBtnWhite title='Buy TKN' notAnimated={true} hideBtn={true} handleClick={() => {
                                        if (isAuth) {
                                            navigate('/buy-tkn')
                                        } else {
                                            dispatch(closeMobileMenu(null));
                                            dispatch(toggleLoginScene(null));
                                            dispatch(openLoginModalMenu(null));
                                        }
                                    }}/>*/}
                                </>
                                }
                                {isDesktop && <TransparentBtn title='How to Play' handleClick={() => {
                                    navigate('/how-to-play-and-win')
                                }} additionalStyles={{zIndex: '9999'}}/>}
                                {!isDesktop && <AnimateBtn title='How to Play' handleClick={() => {
                                    navigate('/how-to-play-and-win')
                                }} additionalStyles={{width: '100%'}}/>}
                            </div>
                        </div>
                        <div
                            ref={null}
                            className={(styled.bg, styled.bgBattles)}
                        >
                            {currentBattle &&
                                <BattleList
                                    key={currentBattle.id}
                                    currentRound={currentStep}
                                    battle={currentBattle}
                                />
                            }
                            {!currentBattle && (
                                <div className={styled.pulsate} style={{lineHeight: '243px'}}>
                                    Waiting for battle to be scheduled
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </MovingBackground>
        </div>
    );
};

export {NFTBattles};

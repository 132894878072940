import {toast} from 'react-toastify';
import {Field, Form, Formik} from 'formik';

import {AnimateBtn} from '../../components/Buttons/AnimateBtn/AnimateBtn';
import {HeaderGeneral} from '../../components/HeaderGeneral/HeaderGeneral';
import {NotificationT} from '../../components/ToastifyNot/NotificationToastify';
import 'react-toastify/dist/ReactToastify.css';
import {contactAPI} from '../../api/api';
import * as validation from '../../utils/Validation';
import {TextError} from './TextError/TextError';

import styled from './Contacts.module.scss';
import {useMediaQuery} from 'react-responsive';
import {MovingBackground} from "../../components/ProfileLayout/MovingBackground";

interface IValue {
    name: string;
    email: string;
    subject: string;
    message: string;
}

const initialValues = {
    name: '',
    email: '',
    subject: '',
    message: '',
};

const Contacts = () => {
    function sendMessage(value: IValue) {
        contactAPI
            .createContact(value.name, value.email, value.subject, value.message)
            .then((res) => {
                toast.success(res.message);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    }

    return (
        <>
            <HeaderGeneral title='Contact'/>
            <NotificationT/>
            <div style={{padding: '20px'}}>
                <MovingBackground>
                    <div className={styled.container}>
                        <div className={styled.boxContainer}>
                            <div>
                                <h1 className={styled.title}>Let's talk</h1>
                                <p style={{color: 'white'}}>If you have questions, ideas, proposals for cooperation, please write to us</p>
                                <div className={styled.inputs}>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validation.ContactsSchema}
                                        onSubmit={(values) => {
                                            sendMessage(values);
                                        }}>
                                        {({errors, values, touched}) => (
                                            <Form className={styled.formWrapper}>

                                                <div className={styled.input}>
                                                    {errors.name && touched.name ? (
                                                        <TextError>{errors.name}</TextError>
                                                    ) : null}
                                                    <Field
                                                        type='text'
                                                        id='name'
                                                        name='name'
                                                        placeholder='Your Name'
                                                        className={styled.inputField}
                                                    />
                                                </div>

                                                <div className={styled.input}>
                                                    {errors.email && touched.email ? (
                                                        <TextError>{errors.email}</TextError>
                                                    ) : null}
                                                    <Field
                                                        type='text'
                                                        id='email'
                                                        name='email'
                                                        placeholder='Your Email'
                                                        className={styled.inputField}
                                                    />
                                                </div>

                                                <div className={styled.input}>
                                                    {errors.subject && touched.subject ? (
                                                        <TextError>{errors.subject}</TextError>
                                                    ) : null}
                                                    <Field
                                                        type='text'
                                                        id='subject'
                                                        name='subject'
                                                        placeholder='About'
                                                        className={styled.inputField}
                                                    />
                                                </div>

                                                <div className={styled.input}>
                                                    {errors.message && touched.message ? (
                                                        <TextError>{errors.message}</TextError>
                                                    ) : null}
                                                    <Field
                                                        as='textarea'
                                                        rows={6}
                                                        type='text'
                                                        id='message'
                                                        name='message'
                                                        placeholder='Your message'
                                                        className={styled.inputField}
                                                    />
                                                </div>

                                                <AnimateBtn additionalStyles={{width: '100%', height: '50px'}} title='Send'
                                                            type='submit'/>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </MovingBackground>
            </div>
        </>
    );
};

export {Contacts};

import {useEffect, useState} from 'react';
import {battleAPI, profileAPI} from '../../../api/api';
import {useAppDispatch, useAppSelector} from '../../../hooks/reduxHooks';
import {
    setCurrentlyPlayingSongDuration,
    setLastChance,
    setSongId,
    setTimeLeft
} from '../../../store/battleSlice';
import {setSongData,} from '../../../store/playerSlice';
import {SongCard} from '../../SongCard/SongCard';
import {Song, TimerBattle} from '../../TimerBattle/TimerBattle';
import styled from '../BattleList.module.scss';
import {setProfile} from "../../../store/profileSlice";

interface IProps {
    battleId: number;
    currentRound?: number;
    battle: any;
}


const useBattleInfo = (battleId: number, timeToFinish: number) => {
    const [battleInfo, setBattleInfo] = useState<any>(null);
    const [tries, setTries] = useState<number>(0);

    useEffect(() => {
        if (timeToFinish > 0 || battleInfo !== null) {
            return;
        }

        const timeout = setTimeout(() => {
            battleAPI.getBattleInfo(battleId).then(data => {
                if (data.data.battle.winner_customer_id === 0) {
                    setTimeout(() => {
                        battleAPI.endAllBattles(battleId);
                        setTries((prev) => prev + 1);
                    }, 500);
                } else {
                    setBattleInfo(data.data);
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [tries, timeToFinish]);

    return battleInfo;
};

const useTimeLeft = (battle: any) => {
    const [timeLeft, setTimeLeft] = useState(99);

    useEffect(() => {
        if (!battle.date_end) {
            return;
        }

        const dateEnd = new Date(battle.date_end + " GMT");

        // console.log('dateEnd', dateEnd);

        const timer = setInterval(() => {
            let newTimeLeft;

            if (0 > (parseInt(Math.round((dateEnd.getTime() - new Date().getTime()) / 1000).toFixed(0)))) {
                newTimeLeft = 0;
            } else {
                newTimeLeft = parseInt(Math.round((dateEnd.getTime() - new Date().getTime()) / 1000).toFixed(0));
            }

            if (timeLeft === newTimeLeft) {
                return;
            }

            // console.log('timeLeft', newTimeLeft);

            setTimeLeft(newTimeLeft);
        }, 250);

        return () => clearInterval(timer);

    }, [battle.date_end]);

    return [timeLeft];
};

const BattleItem: React.FC<IProps> = ({
                                          battleId,
                                          currentRound,
                                          battle,
                                      }) => {
    const [songs, setSongs] = useState<Song[]>([]);
    const [currentlyPlayingSong, setCurrentlyPlayingSong] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [timeToFinish] = useTimeLeft(battle);
    const [isTimeForLastChance, setIsTimeForLastChance] = useState(false);
    const [votes, setVotes] = useState([]);
    const [userVotes, setUserVotes] = useState([]);
    const [isAuth, setIsAuth] = useState(false);
    const [getVotesTries, setGetVotesTries] = useState(0);
    const [mobileClickedSongId, setMobileClickedSongId] = useState(0);
    const dispatch = useAppDispatch();
    const currentlyPlayingSongDuration = useAppSelector((state) => state.battle.currentlyPlayingSongDuration);
    const battleInfo = useBattleInfo(battleId, timeToFinish);

    useEffect(() => {
        if (localStorage.getItem('isAuth')) setIsAuth(true);
        if (!localStorage.getItem('isAuth')) setIsAuth(false);
    }, []);

    useEffect(() => {
        if (battle && battle.battle_customers) {
            const battleSongs = battle.battle_customers.map((bc: { track: any[], id: number, customer: any[] }) => {
                return {...bc.track, battleId: bc.id, customer: {...bc.customer}};
            });
            setSongs(battleSongs);
        }
    }, [battle]);

    useEffect(() => {
        if (timeToFinish < 15) {
            dispatch(setLastChance(true));
            setIsTimeForLastChance(true);
        }
    }, [timeToFinish]);

    useEffect(() => {
        // setTime();
        function formattingTime(data: number): any {
            let minutes = 0;

            data = Math.floor(data);

            for (let i = data; i >= 60; i = i - 60) {
                minutes += 1;
            }

            const seconds = data - minutes * 60;
            const displayMinutes = minutes < 10 ? `0${minutes}` : minutes;
            const displaySeconds = seconds < 10 ? `0${seconds}` : seconds;

            return `${displayMinutes}:${displaySeconds}`;
        }

        let newTimeToFinish = timeToFinish;

        if (timeToFinish < 0) {
            newTimeToFinish = 0;
        }

        dispatch(setTimeLeft(`${formattingTime(newTimeToFinish)} MIN`));
    }, [timeToFinish, dispatch]);

    useEffect(() => {
        const timer = setInterval(() => {
            dispatch(setCurrentlyPlayingSongDuration());
        }, 1000);
        if (currentlyPlayingSongDuration === 0) {
            return () => {
                clearInterval(timer);
                dispatch(setCurrentlyPlayingSongDuration(0));
            };
        }
        return () => {
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentlyPlayingSongDuration]);

    useEffect(() => {
        if (songs.length === 0) {
            return;
        }

        const {play_time} = battle;

        let statement = true;
        let songIndex = 0;

        while (statement) {
            if (isNaN(timeToFinish)) {
                statement = false;
                continue;
            }

            if (timeToFinish - 15 < 0) {
                statement = false;
                continue;
            }

            const dd = ((songIndex + 1) * play_time) - (timeToFinish - 15);

            if (dd < 0) {
                songIndex++;
            } else if (dd >= 0) {
                statement = false;
                setIsPlaying(true);

                const leftTimeOfCurrentSong = play_time - dd;
                const inx = (songs.length - 1) - songIndex;

                if (!songs[inx]) {
                    continue;
                }

                setCurrentlyPlayingSong(inx);

                let lyrics = [];
                try {
                    lyrics = JSON.parse(songs[inx].lyrics.replace(/[\r\n]/g, ''));
                } catch (e) {
                }

                dispatch(
                    setSongData({
                        id: songs[inx].id,
                        url: `${songs[inx].file_url}`,
                        openseaUrl: `${''}`,
                        image: `${songs[inx].image_url}`,
                        title: songs[inx].name,
                        singer: songs[inx]?.artists[0]?.name,
                        lyrics: lyrics,
                        genre: songs[inx].music_categories[0].name,
                    }),
                );
                dispatch(setCurrentlyPlayingSongDuration(leftTimeOfCurrentSong));
                dispatch(setSongId(songs[inx].id));
            }

        }
    }, [timeToFinish, battle, currentlyPlayingSong, songs, songs.length, isPlaying, dispatch]);

    let songRefs: any = [];

    useEffect(() => {
        if (timeToFinish === 0) {
            if (getVotesTries > 10) {
                return;
            }

            battleAPI.getVotes(battleId).then((res) => {
                if (isAuth) {
                    if (!res.data.userVotes) {
                        setTimeout(() => {
                            setGetVotesTries(prev => prev + 1);
                        }, 500);

                        return;
                    } else {
                        setUserVotes(res.data.userVotes);

                        profileAPI.getProfile().then((res) => {
                            dispatch(setProfile(res.data));
                        });
                    }
                }

                setVotes(res.data.trackVotes)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeToFinish, getVotesTries]);

    const [voted, setVoted] = useState<number[]>(songs.map(() => 0));

    useEffect(() => {
        setVoted(songs.map(() => 0));
    }, [songs]);

    const profile = useAppSelector((state) => state.profile.profile);

    return !isTimeForLastChance ? (
        <li className={styled.battle}>
            <ul
                className={
                    1 === currentRound
                        ? styled.songListFirst
                        : 2 === currentRound
                            ? styled.songListSecond
                            : styled.songListThird
                }>
                {songs.map((song: any, idx: number) => (
                    <SongCard
                        song={song}
                        key={song.id}
                        idx={idx}
                        currentRound={currentRound}
                        isVoted={voted[idx]}
                        setIsVoted={
                            () => {
                                setVoted((prev) => {
                                    const newVoted = [...prev];
                                    newVoted[idx] += profile?.customer.nominal ?? 1;
                                    return newVoted;
                                });
                            }
                        }
                    />
                ))}
            </ul>
        </li>
    ) : (
        <li className={styled.battle}>
            <TimerBattle
                votes={votes}
                userVotes={userVotes}
                mobileClickedSongId={mobileClickedSongId}
                setMobileClickedSongId={setMobileClickedSongId}
                songRefs={songRefs}
                songs={songs}
                currentRound={currentRound}
                battleInfo={battleInfo}
                timeToFinish={timeToFinish}
                voted={voted}
                setVoted={setVoted}
            />
        </li>
    );
};

export {BattleItem};

import React from "react";
import Marquee from "react-fast-marquee";

interface IProps {
    text: string;
    threshold?: number;
    classNames?: string | undefined;
}

const ScrollingText: React.FC<IProps> = ({text, threshold = 13, classNames = undefined}) => {
    const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const textRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        setIsPlaying(false);

        setTimeout(() => {
            setIsPlaying(false);
        }, 0);
    }, [text]);

    React.useEffect(() => {
        // get width of containerRef
        if (textRef.current?.scrollWidth > containerRef.current?.clientWidth) {
            setIsPlaying(true);
        }
    }, [text, isPlaying]);

    React.useEffect(() => {
        if (!containerRef.current) {
            return;
        }

        const resizeObserver = new ResizeObserver(() => {
            setIsPlaying(false);

            setTimeout(() => {
                setIsPlaying(false);
            }, 0);
        });

        resizeObserver.observe(containerRef.current);

        return () => resizeObserver.disconnect();
    }, [containerRef]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (isPlaying) {
                return;
            }

            if (textRef.current?.scrollWidth > containerRef.current?.clientWidth) {
                setIsPlaying(true);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div ref={containerRef}>
            {isPlaying ?
                <Marquee gradient={false} speed={40} className={classNames}>{text}&nbsp;</Marquee>
                : <div className={classNames} style={{whiteSpace: 'nowrap'}} ref={textRef}>{text}&nbsp;</div>
            }
        </div>
    );
};

export {ScrollingText};

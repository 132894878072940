import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {contactAPI, profileAPI} from '../../api/api';

import {AnimateBtn} from '../../components/Buttons/AnimateBtn/AnimateBtn';
import {HeaderGeneral} from '../../components/HeaderGeneral/HeaderGeneral';
import {NotificationT} from '../../components/ToastifyNot/NotificationToastify';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import {openLoginModalMenu} from '../../store/loginModalSlice';
import {LoginModal} from '../Login/LoginModal/LoginModal';

import styled from './MakeOffer.module.scss';
import {MovingBackground} from "../../components/ProfileLayout/MovingBackground";
import {TransparentBtn} from "../../components/Buttons/TransparentBtn/TransparentBtn";
import {BattleList} from "../../components/BattleList/BattleList";

const MakeOffer = () => {
    const [songName, setSongName] = useState('');
    const [artist, setArtist] = useState('');
    const [email, setEmail] = useState('');
    const [wallet, setWallet] = useState('');
    const [isAuth, setIsAuth] = useState(false);
    const [userData, setUserData] = useState<any>({});

    useEffect(() => {
        if (Object.keys(userData).length === 0) {
            profileAPI.getProfile().then((res) => {
                setUserData(res.data.customer);
            });
        }
    }, [userData]);

    const dispatch = useAppDispatch();
    const isOpenModalLogin = useAppSelector((state) => state.modalLogin.isOpen);

    useEffect(() => {
        setIsAuth(JSON.parse(localStorage.getItem('isAuth')!));
    }, []);

    function handleAuth() {
        dispatch(openLoginModalMenu(null));
    }

    function handleSubmit() {
        const userEmail = userData.email ? userData.email : email;
        const name = userData.first_name ? `${userData.first_name} ${userData.last_name}` : "Not logged in user";

        contactAPI
            .createContact(
                name,
                userEmail,
                "Order NFT form submitting",
                `song: ${songName} | artist: ${artist} | email: ${email} | wallet: ${wallet}`
            )
            .then((res) => {
                toast.success(res.message);
                setSongName('');
                setArtist('');
                setEmail('');
                setWallet('');
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    }

    return (
        <>
            {isOpenModalLogin && <LoginModal/>}
            <HeaderGeneral title='Order NFT'/>
            <NotificationT/>

            <div style={{padding: '20px'}}>
                <MovingBackground customBackground={true}>
                    <div className={styled.container}>
                        <div className={styled.bgMain}>
                            <div className={styled.wrapperLeft}>
                                <h2>Can’t find the song you want?</h2>
                                <p>
                                    You did not find the track in our collection you were looking for? Write us the name
                                    of
                                    the artist and the name of the track, and within 24 hours we will create an NFT for
                                    this
                                    track especially for you, which you can redeem with exclusive rights within 48
                                    hours,
                                    after this time the NFT will go on free sale.
                                </p>
                            </div>
                            <div className={styled.wrapperRight}>
                                <h2>Tell us who it is and we will add it to the list ot NFT</h2>
                                <div className={styled.wrapperQuery}>
                                    <input
                                        type='text'
                                        placeholder='Name of the Song'
                                        value={songName}
                                        onChange={(e) => setSongName(e.target.value)}
                                    />
                                    <input
                                        type='text'
                                        placeholder='Artist'
                                        value={artist}
                                        onChange={(e) => setArtist(e.target.value)}
                                    />
                                    <input
                                        type='email'
                                        placeholder='Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <input
                                        type='text'
                                        placeholder='Wallet'
                                        value={wallet}
                                        onChange={(e) => setWallet(e.target.value)}
                                        style={{marginBottom: 0}}
                                    />
                                    <AnimateBtn title='Send' handleClick={handleSubmit}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </MovingBackground>
            </div>
        </>
    );
};

export {MakeOffer};

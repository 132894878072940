import React, {useEffect, useRef, useState} from 'react';

import NavigateElement from './NavigateElement';
import {useAppSelector} from '../../hooks/reduxHooks';
import {ReactComponent as Explore} from '../../assets/svg/mainMenu/home.svg';
import {ReactComponent as NftBattle} from '../../assets/svg/mainMenu/path.svg';
import {ReactComponent as Note} from '../../assets/svg/mainMenu/note.svg';
import {ReactComponent as Heart} from '../../assets/svg/mainMenu/heart.svg';
import {ReactComponent as Trophy} from '../../assets/svg/mainMenu/trophy.svg';
import {ReactComponent as Album} from '../../assets/svg/mainMenu/music-album.svg';
import {ReactComponent as Playlist} from '../../assets/svg/mainMenu/playlist.svg';
import {ReactComponent as Email} from '../../assets/svg/mainMenu/email.svg';
import {ReactComponent as Info} from '../../assets/svg/mainMenu/info.svg';

import {ReactComponent as Twitter} from '../../assets/svg/footer-links/twitter.svg';
import {ReactComponent as Discord} from '../../assets/svg/footer-links/discord.svg';
import {ReactComponent as Reddit} from '../../assets/svg/footer-links/reddit.svg';
import {ReactComponent as Telegram} from '../../assets/svg/footer-links/telegram.svg';

import styled from './ShortNavigate.module.scss';
import NavigateElementToExternalLinks from "./NavigateElementToExternalLinks";

const ShortNavigate = () => {
    const ref = useRef(null);
    const [isAuth, setIsAuth] = useState(true);

    const isOpenModal = useAppSelector((state) => state.modalLogin.isOpen);

    useEffect(() => {
        if (localStorage.getItem('isAuth')) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
    }, []);

    const menu = [
        {name: '', url: 'explore', svg: <Explore/>},
        {name: '', url: 'category-selection', svg: <NftBattle/>},
        {name: '', url: 'buy-nft', svg: <Note/>},
        {name: '', url: 'make-offer', svg: <Heart/>},
        {name: '', url: 'winners', svg: <Trophy/>},
    ];

    const profile = [
        {name: '', url: 'customer-tracks', svg: <Album/>},
        {name: '', url: 'profile/playlists', svg: <Playlist/>},
    ];

    const help = [
        // { name: '', url: 'support', svg: <Headphones /> },
        {name: '', url: 'contacts', svg: <Email/>},
        {name: '', url: 'how-to-play-and-win', svg: <Info/>},
    ];

    const footerLinks = [
        {name: '', url: 'https://twitter.com/www_music_bet', svg: <Twitter/>, external: true},
        {
            name: '',
            url: 'https://discord.com/channels/1085543688605802527/1085543689528561667',
            svg: <Discord/>,
            external: true
        },
        {name: '', url: 'https://www.reddit.com/user/www_music_bet', svg: <Reddit/>, external: true},
        {name: '', url: 'https://telegram.com', svg: <Telegram/>, external: true},
    ];

    return (
        <>
            {isOpenModal ? null : (
                <nav ref={ref} className={styled.container}>
                    <div className={styled.topWrapper}>
                        <div className={styled.logoWrapper} style={{position: 'relative'}}>
                            <svg
                                width='45'
                                height='64'
                                viewBox='0 0 45 64'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M3 3C3 3 3 49.5198 3 50C3 50.4802 3.79411 56.2839 9.34447 59.3658C14.8948 62.4478 19.9515 60.3307 21.2257 59.7547C22.5 59.1786 35.0447 51.0169 36.5044 50C37.964 48.9832 42 45.8144 42 40.1094C42 34.4044 38.1834 31.0005 36.5044 29.7562C34.8253 28.512 16.0779 17.8006 16.0779 17.8006V11.0312L3 3Z'
                                    stroke='url(#paint0_linear_0_37496)'
                                    strokeWidth='4.73981'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M14 30V50L31 40.1254L14 30Z'
                                    stroke='url(#paint1_linear_0_37496)'
                                    strokeWidth='4.23446'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <defs>
                                    <linearGradient
                                        id='paint0_linear_0_37496'
                                        x1='-15.7015'
                                        y1='21.3968'
                                        x2='26.2623'
                                        y2='60.8428'
                                        gradientUnits='userSpaceOnUse'>
                                        <stop stopColor='#00E476'/>
                                        <stop offset='0.549658' stopColor='#00B2FD'/>
                                        <stop offset='1' stopColor='#0074F0'/>
                                    </linearGradient>
                                    <linearGradient
                                        id='paint1_linear_0_37496'
                                        x1='4.66228'
                                        y1='34.5771'
                                        x2='18.1188'
                                        y2='51.5731'
                                        gradientUnits='userSpaceOnUse'>
                                        <stop stopColor='#00E476'/>
                                        <stop offset='0.549128' stopColor='#00B2FD'/>
                                        <stop offset='1' stopColor='#0074F0'/>
                                    </linearGradient>
                                </defs>
                            </svg>

                            <span style={{color: 'white', fontSize: '8px', opacity: '0.4', position: 'absolute', bottom: '-35px', left: '-8px'}}>beta version 0.152.8</span>
                        </div>
                        <div className={styled.menuWrapper}>
                            <h3 className={styled.title}>Menu</h3>
                            <NavigateElement menuElement={menu}/>
                        </div>

                        {isAuth && (
                            <div className={styled.menuWrapper}>
                                <h3 className={styled.title}>Profile</h3>
                                <NavigateElement menuElement={profile}/>
                            </div>
                        )}
                        <div className={styled.menuWrapper}>
                            <h3 className={styled.title}>Help</h3>
                            <NavigateElement menuElement={help}/>
                        </div>
                        <div className={styled.menuWrapper}>
                            <h3 className={styled.title}></h3>
                            <NavigateElementToExternalLinks menuElement={footerLinks}/>
                        </div>
                    </div>

                    {/*<div className={styled.rightsWrapper}>*/}
                    {/*  <div className={styled.iconsShape}>*/}

                    {/*    <Twitter/>*/}
                    {/*    <Reddit/>*/}
                    {/*    <Discord/>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                </nav>
            )}
        </>
    );
};

export {ShortNavigate};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ProfileSlice = {
    profile: any;
};

const initialState: ProfileSlice = {
    profile: null,
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile(state, action: PayloadAction<any>) {
            state.profile = action.payload;
        },
    },
});

export const { setProfile } = profileSlice.actions;

export default profileSlice.reducer;

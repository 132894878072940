import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as User} from '../../assets/svg/profile-main-btn-svg/user.svg';
import {ReactComponent as Setting} from '../../assets/svg/profile-main-btn-svg/setting.svg';
import {ReactComponent as Bell} from '../../assets/svg/profile-main-btn-svg/bell.svg';
import {ReactComponent as Mail} from '../../assets/svg/profile-main-btn-svg/mail.svg';
import { ReactComponent as Wallet } from '../../assets/favorites/wallet.svg';

import styled from './MobileProfileMenu.module.scss';

const MobileProfileMenu = () => {
    const buttons = [
        {title: 'Profile Settings', url: '/profile/settings', svg: <Setting/>},
        {title: 'Withdraw', url: '/profile/withdraw', svg: <Wallet/>},
        {title: 'Contact us', url: '/profile/contact-us', svg: <Mail/>},
    ];

    return (
        <div className={styled.container}>
            {buttons.map((el, index) => {
                return (
                    <Link
                        to={el.url}
                        key={index}
                        className={styled.activeContainer}>
                        <div className={styled.activeIcon}>{el.svg}</div>
                        <span className={styled.activeText}>{el.title}</span>
                    </Link>
                );
            })}
        </div>
    );
};

export {MobileProfileMenu};

import {useEffect, useState} from 'react';

import {musicAPI, profileAPI, trackAPI} from '../../../api/api';
import {NFTMusicBlock} from '../../NFTMusicBlock/NFTMusicBlock';
import {NFTSongs} from '../../NFTSongs/NFTSongs';
import {ProfileLayout} from '../../ProfileLayout/ProfileLayout';
import {ProfileNftButtons} from '../../ProfileNfrtButtons/ProfileNftButtons';

import styled from './NFTSong.module.scss';
import {NotificationT} from '../../ToastifyNot/NotificationToastify';
import {toast} from 'react-toastify';
import {setProfile} from "../../../store/profileSlice";
import {useAppDispatch} from "../../../hooks/reduxHooks";

interface customSong {
    in_battle?: number;
    artists: any;
    music_categories: any;
    win_count: number;
    id: number;
    name: string;
    title: string;
    image: string;
    image_url: string;
    label: string;
    file: string;
    file_url: string;
    lyrics: string;
}

export interface ISong {
    id: number;
    track_id: number;
    track: customSong;
}

const NFTSong = () => {
    const dispatch = useAppDispatch();

    const [songs, setSongs] = useState<ISong[]>([]);
    const [trackHash, setTrackHash] = useState<string>("");
    const [registerOpen, setRegisterOpen] = useState(false);
    const [promoCode, setPromoCode] = useState("");

    useEffect(() => {
        musicAPI
            .getCustomerMusic()
            .then((res) => {
                setSongs(res.data.data);
            })
            .catch((error) => console.log(error));
    }, []);

    function toggleRegisterMenu() {
        setRegisterOpen((prev) => !prev);
    }

    function handleRegisterTrack() {
        trackAPI.registerTrack(trackHash, promoCode).then((res) => {
            musicAPI
                .getCustomerMusic()
                .then((res) => {
                    setSongs(res.data.data);
                    setPromoCode("");
                    setTrackHash("");
                    toast.success("Nft registered successfully");

                    profileAPI.getProfile().then((res) => {
                        dispatch(setProfile(res.data));
                    });
                })
                .catch((error) => {
                    console.log("error:", error);
                });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    return (
        <ProfileLayout>
            <NotificationT/>
            <div className={styled.container}>
                <div className={styled.btnWrapper}>
                    <ProfileNftButtons handleClick={toggleRegisterMenu} title="Register NFT"/>
                </div>
                {registerOpen && (
                    <div className={styled.regInfo}>
                        <div>
                            <p>Enter Register code here and <span>register</span></p>
                            <input
                                value={trackHash}
                                onChange={(e: any) => {
                                    setTrackHash(e.target.value);
                                }}
                                type='text'
                                placeholder='Register code *'
                            />
                        </div>

                        <div>
                            <p>Enter Promo Code if you have one</p>
                            <input
                                type="text"
                                placeholder="Promo Code"
                                value={promoCode}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setPromoCode(e.target.value)
                                }
                            />
                        </div>

                        <button onClick={handleRegisterTrack}>REGISTER</button>

                    </div>
                )}

                {songs?.map((song, index: number) => {
                    return (
                        <NFTMusicBlock key={song.id}>
                            <NFTSongs index={index} {...song} />
                        </NFTMusicBlock>
                    );
                })}
            </div>
        </ProfileLayout>
    );
};

export {NFTSong};

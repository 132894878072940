import {useEffect, useMemo, useState} from 'react';
import {battleAPI, profileAPI} from '../../../api/api';
import {ReactComponent as LikeSvg} from '../../../assets/svg/likeWhite.svg';
import {ReactComponent as LikeActiveSvg} from '../../../assets/svg/likeWhiteActive.svg';
import {ReactComponent as UserSvg} from '../../../assets/svg/user.svg';
import {useAppDispatch, useAppSelector} from '../../../hooks/reduxHooks';
import {toast} from 'react-toastify';
import styled from '../SongCard.module.scss';
import {ScrollingText} from "../../TextUtils/ScrollingText";
import {closeMobileMenu} from "../../../store/mobileMenuSlice";
import {openLoginModalMenu, toggleLoginScene} from "../../../store/loginModalSlice";
import {setProfile} from "../../../store/profileSlice";

interface IProps {
    title: string;
    singer: string;
    song: {
        id: number;
        customer: any;
    };
    id: number;
    isVoted: number;
    setIsVoted: any;
}

const SongInfo: React.FC<IProps> = ({title, singer, id, song, isVoted, setIsVoted}) => {
    const dispatch = useAppDispatch();
    const isLastChance = useAppSelector(state => state.battle.isLastChange);
    const playingSong = useAppSelector(state => state.battle.songId);
    const [isLiked, setIsLiked] = useState(false);

    function vote(id: number): void {
        battleAPI
            .votesForCard(id)
            .then((res) => {
                setIsVoted();

                profileAPI.getProfile().then((res) => {
                    dispatch(setProfile(res.data));
                });

                return res.data;
            })
            .catch((error) => {
                if ('Unauthenticated.' === error.response.data.message) {
                    dispatch(closeMobileMenu(null));
                    dispatch(toggleLoginScene(null));
                    dispatch(openLoginModalMenu(null));
                } else {
                    toast.error(error.response.data.message);
                }
            });
    }

    const pulsateLike = useMemo(() => {
        return playingSong === song.id || isLastChance;
    }, [playingSong, isLastChance]);

    useEffect(() => {
        if (isLiked) {
            setTimeout(() => {
                setIsLiked(false);
            }, 1000);
        }
    }, [isLiked]);

    const handleLike = () => {
        if (song.id === playingSong || isLastChance) {
            setIsLiked(!isLiked);
            vote(id);
        }
    };

    return (
        <div className={styled.songInfo}>
            <h3 className={styled.songInfoTitle}><ScrollingText text={title}></ScrollingText></h3>
            <p className={styled.songInfoSinger}><ScrollingText text={singer}></ScrollingText></p>
            <div className={styled.user}>
                <p>
                    <div className={styled.userSvg} style={{fill: '#2B3134'}}>
                        <UserSvg/>
                    </div>
                    {/* @ts-ignore */}
                    &nbsp;{song.customer.username}
                </p>
            </div>

            <div className={styled.SongInfoControllers}>
                <p className={styled.songInfoLike}>&nbsp;{isVoted ? 'VOTED' : 'VOTE'}&nbsp;</p>

                {pulsateLike ? (isLiked ? (
                    <div className={styled.songInfoSvg} onClick={handleLike}>
                        <LikeActiveSvg/>
                    </div>
                ) : (
                    <div className={styled.songInfoSvg} onClick={handleLike}>
                        <LikeSvg/>
                    </div>
                )) : (isLiked ? (
                    <div className={styled.inactiveSongInfo} onClick={handleLike}>
                        <LikeActiveSvg/>
                    </div>
                ) : (
                    <div className={styled.inactiveSongInfo} onClick={handleLike}>
                        <LikeSvg/>
                    </div>
                ))}

                <p className={styled.songInfoWin}>&nbsp;{isVoted} TKN&nbsp;</p>
            </div>
        </div>
    );
};

export {SongInfo};

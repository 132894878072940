import React, {useMemo} from 'react';

import {ReactComponent as Cup} from '../../../../assets/favorites/cup.svg';
import {ReactComponent as Star} from '../../../../assets/favorites/star.svg';
import {ReactComponent as More} from '../../../../assets/more.svg';
import {ReactComponent as Play} from '../../../../assets/icons-for-modal-battle/play.svg';
import {ReactComponent as Stop} from '../../../../assets/icons-for-modal-battle/stop.svg';

import styled from './Song.module.scss';
import {BE_BASE_URL} from '../../../../api/api';
import {setPlaying, setSongData} from "../../../../store/playerSlice";
import {useDispatch} from "react-redux";
import {useAppDispatch, useAppSelector} from "../../../../hooks/reduxHooks";

interface ISong {
    url: string;
    title: string;
    name: string;
    points: number;
    stars: number;
    id: number;
    track: any;
    tracksIdsInPlaylist: any;
    addOrRemoveTrackIdsInPlaylist: (id: number) => void;
}

const Song: React.FC<ISong> = ({
   url,
   track,
   title,
   name,
   points,
   stars,
   id,
   tracksIdsInPlaylist,
   addOrRemoveTrackIdsInPlaylist,
}) => {
    const dispatch = useAppDispatch();
    const songUrl = useMemo(() => {
        return `${url}`;
    }, [url]);

    function isAddedTrack(id: number) {
        return tracksIdsInPlaylist.includes(id);
    }

    const currentlyPlayingSongId = useAppSelector((state) => state.player.songId);
    const isPlayerPlaying = useAppSelector((state) => state.player.playing);
    const isTrackPlaying = isPlayerPlaying && track.track.id === currentlyPlayingSongId;

    return (
        <li className={styled.container}>

            <div onClick={() => {
                if (isTrackPlaying) {
                    dispatch(setPlaying(false));

                    return;
                }

                dispatch(
                    setSongData({
                        id: track.track.id,
                        url: track.track.file_url,
                        openseaUrl: track.track.opensea_link,
                        image: track.track.image_url,
                        title: track.track.name,
                        singer: track?.track.artists[0]?.name,
                        lyrics: [],
                        genre:
                            track.track.music_categories.length > 0 ? track.track.music_categories[0].name : 'Pop',
                    }),
                );

                dispatch(setPlaying(true));
            }}>
                {isTrackPlaying ? (<Stop className={styled.player}/>) : (<Play className={styled.player}/>)}
            </div>

            {/* <Stop className={styled.player} /> */}

            <img className={styled.cover} src={songUrl} alt='cover'/>

            <div className={styled.actorWrapper}>
                <p className={styled.title}>{title}</p>
                <p className={styled.name}>{name}</p>
            </div>

            <div className={styled.iconWrapper}>
                <div>
                    <Cup/>
                </div>
                <p>{points} points</p>
            </div>

            {!isAddedTrack(track.track.id) && (
                <button className={styled.btn} onClick={() => addOrRemoveTrackIdsInPlaylist(track.track.id)}>
                    <span className={styled.sign}>+</span>
                    <span className={styled.word}>ADD</span>
                </button>
            )}

            {isAddedTrack(track.track.id) && (
                <button className={styled.btn} onClick={() => addOrRemoveTrackIdsInPlaylist(track.track.id)}>
                    <span className={styled.sign}>-</span>
                    <span className={styled.word}>REMOVE</span>
                </button>
            )}
        </li>
    );
};

export {Song};

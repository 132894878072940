import React, {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {Link, useNavigate} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import logo from '../../assets/svg/mobile-icons/logo.png';
import {ReactComponent as User} from '../../assets/svg/mobile-icons/user.svg';
import {closeMobileMenu, openMobileMenu} from '../../store/mobileMenuSlice';
import {MobileMenu} from '../mobileMenu/MobileMenu';

import styled from './Layout.module.scss';
import {WhiteBtn} from '../Buttons/WhiteBtn/WhiteBtn';
import {authAPI, profileAPI} from '../../api/api';
import {userLogOut} from '../../store/isAuthSlice';
import {openLoginModalMenu} from "../../store/loginModalSlice";
import {LoginModal} from "../../pages/Login/LoginModal/LoginModal";
import Dropdown from "../Dropdown/Dropdown";
import {setProfile} from "../../store/profileSlice";
import DropdownInverted from "../DropdownIverted/DropdownInverted";
import {ReactComponent as Wallet} from '../../assets/svg/tabs/wallet-white.svg';

interface IProps {
    children: JSX.Element;
}

const Layout: React.FC<IProps> = ({children}) => {
    const navigate = useNavigate();
    const [isAuth, setIsAuth] = useState(false);

    function openProfile() {
        navigate('/customer-tracks');
    }

    const isVisibleMenu = useAppSelector((state) => state.mainMenu.isVisible);
    const dispatch = useAppDispatch();
    const isOpenMobileMenu = useAppSelector((state) => state.mobileMenu.isOpen);
    const isOpenModalLogin = useAppSelector((state) => state.modalLogin.isOpen);

    const isDesktop = useMediaQuery({
        query: '(min-width: 1201px)',
    });

    useEffect(() => {
        if (localStorage.getItem('isAuth')) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (localStorage.getItem('isAuth')) {
                setIsAuth(true);
            } else {
                setIsAuth(false);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    async function logout() {
        authAPI
            .logOut()
            .then(() => {
                dispatch(userLogOut(null));
            })
            .then(() => setIsAuth(false));
    }

    function openLoginModal() {
        dispatch(closeMobileMenu(null));
        dispatch(openLoginModalMenu(null));
    }

    const profile = useAppSelector((state) => state.profile.profile);

    useEffect(() => {
        if (null === profile) {
            profileAPI.getProfile().then((res) => {
                dispatch(setProfile(res.data));
            });
        }
    }, [profile]);

    return (
        <div className={!isVisibleMenu ? styled.activeContainer : styled.container}>

            {/*<div>*/}
            {/*    {!isDesktop && <Logo className={styled.logo}/>}*/}
            {/*    {!isDesktop && (*/}
            {/*        <img*/}
            {/*            className={styled.menuIconOpen}*/}
            {/*            onClick={() => dispatch(openMobileMenu(null))}*/}
            {/*            src='image/menu.svg'*/}
            {/*            alt='menu'*/}
            {/*        />*/}
            {/*    )}*/}
            {/*</div>*/}
            {/*<div className={styled.notificationWrapper}>*/}
            {/*    /!*        {!isDesktop && <Notification className={styled.notificationIcon} onClick={() => { navigate('/profile/notification')}} />}*!/*/}
            {/*    {(!isDesktop && !isAuth) && <User onClick={openLoginModal} className={styled.userIcon}/>}*/}
            {/*    {(!isDesktop && isAuth) && <User onClick={openProfile} className={styled.userIcon}/>}*/}
            {/*</div>*/}

            {/*{!isDesktop &&*/}
            {/*    <div className={styled.logoutWrapper}>*/}
            {/*        {isAuth &&*/}
            {/*            <WhiteBtn additionalStyle={{width: "100px"}} handleClick={() => logout()} title='logout'/>}*/}
            {/*    </div>*/}
            {/*}*/}

            {!isDesktop &&
                <div className={styled.mobileContainer}>
                    <div>
                        <Link to='/explore' style={{position: 'relative'}}>
                            {/*<Logo />*/}
                            <img src={logo} alt='logo' />
                        </Link>
                        <span style={{color: 'white', fontSize: '8px', opacity: '0.4', position: 'absolute', bottom: '4px', left: '20px'}}>beta version 0.152.8</span>
                    </div>

                    <div className={styled.actionContainer}>
                        {isAuth && <div className={styled.walletContainer}>
                            <Wallet/>
                            <div>
                                <p>{profile?.account.balance ?? 0} TKN</p>
                                {profile?.customer.nominal && <DropdownInverted
                                    options={[1, 50, 100, 200, 500, 1000]}
                                    defaultOption={profile?.customer.nominal ?? 1}
                                    onSelect={(option) => {
                                        profileAPI.setNominal(option).then(() => {
                                            profileAPI.getProfile().then((res) => {
                                                dispatch(setProfile(res.data));
                                            });
                                        });
                                    }}
                                />}
                            </div>
                        </div>}

                        {!isAuth && <User onClick={openLoginModal} className={styled.actionMenuIcon} />}
                        {isAuth && <User onClick={openProfile} className={styled.actionMenuIcon} />}

                        <img
                            onClick={() => dispatch(openMobileMenu(null))}
                            src='image/menu.svg'
                            alt='menu'
                            className={styled.actionMenuIcon}
                        />
                    </div>

                    {/*<div className={styled.notificationWrapper}>*/}
                        {/*        {!isDesktop && <Notification className={styled.notificationIcon} onClick={() => { navigate('/profile/notification')}} />}*/}
                    {/*</div>*/}
                    {/*<div className={styled.logoutWrapper}>*/}
                    {/*    {isAuth &&*/}
                    {/*        <WhiteBtn additionalStyle={{width: "100px"}} handleClick={() => logout()} title='logout'/>}*/}
                    {/*</div>*/}
                </div>
            }

            {isOpenMobileMenu && <MobileMenu/>}
            {isOpenModalLogin && <LoginModal/>}

            <div className={styled.wrapper}>
                <>{children}</>
            </div>
        </div>
    );
};

export {Layout};

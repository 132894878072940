import {useMediaQuery} from 'react-responsive';
import {toast} from 'react-toastify';
import {useEffect, useState} from 'react';
import {WhiteBtn} from '../../components/Buttons/WhiteBtn/WhiteBtn';
import {Footer} from '../../components/HowToPlayAndWin/Footer/Footer';
import {HeadMenu} from '../../components/HowToPlayAndWin/HeadMenu/HeadMenu';
import {MainContent} from '../../components/HowToPlayAndWin/MainContent/MainContent';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import phone from '../../assets/phone.svg';
import {LoginModal} from '../Login/LoginModal/LoginModal';
import {openLoginModalMenu} from '../../store/loginModalSlice';
import {authAPI} from '../../api/api';
import {userLogOut} from '../../store/isAuthSlice';

import styled from './TermsAndConditions.module.scss';

const TermsAndConditions = () => {
    const dispatch = useAppDispatch();
    const [isAuth, setIsAuth] = useState(false);
    const isOpenModalLogin = useAppSelector((state) => state.modalLogin.isOpen);

    useEffect(() => {
        if (localStorage.getItem('isAuth')) setIsAuth(true);
        if (!localStorage.getItem('isAuth')) setIsAuth(false);
    }, []);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 620px)',
    });

    const isDesktop = useMediaQuery({
        query: '(min-width: 1201px)',
    });

    function scroll() {
        window.scrollTo(0, 900);
    }

    function openModal() {
        dispatch(openLoginModalMenu(null));
    }

    async function logout() {
        authAPI
            .logOut()
            .then(() => {
                dispatch(userLogOut(null));
            })
            .then(() => setIsAuth(false))
            .catch(() => {
                toast.error('the server is not responding, try again');
            });
    }

    useEffect(() => {
        if (isOpenModalLogin) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isOpenModalLogin]);

    return (
        <div className={styled.containerWrapper}>
            {isOpenModalLogin && <LoginModal/>}

            <div className={styled.bgWrapper}>
                <div className={styled.bgTop}></div>
                <div className={styled.imagesBg}></div>
                <img className={styled.ph} src={phone} alt='phone'/>
            </div>
            <div className={styled.container}>
                {isDesktop && <div className={styled.menu}>
                    <div style={{width: '220px', textAlign: 'left'}}>
                        <img className={styled.logo} src='image/logo.svg' alt='logo'/>
                    </div>
                    <HeadMenu/>
                    <div style={{width: '220px', textAlign: 'right'}}>
                        {!isAuth && <WhiteBtn handleClick={() => openModal()} title='Sign Up Free'/>}
                        {isAuth && <WhiteBtn handleClick={() => logout()} title='logout'/>}
                    </div>
                </div>}

                <div className={styled.afterMainWrapper}>
                    {/*<img className={styled.tv} src='image/howToPlayAndWin/tv.png' alt='tv' />*/}

                    {/*<h2 className={styled.headerSecondAsphalt}>We Give Everyone the</h2>*/}

                    <h2 className={styled.headerSecondWhite}>
                        Terms of Use
                    </h2>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        Music.bet is an entertainment platform where music lovers (“You » or «user "). Music.bet creates
                        digital cards based on blockchain technology with the name of music tracks (“collectibles ").
                        You
                        can buy collectibles on Opensea.io, use your musical knowledge to build the music collection of
                        your dreams, and take part in battles and competitions based on the actual results of your
                        chosen tracks. You can also list your collectibles for sale or buy other users' collectibles on
                        the
                        Opensea.io marketplace.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        These Terms and Conditions (“Terms and conditions » or «Conditions ”) govern your use of
                        the Music.bet website located at Music.bet. (including any subsequent URLs) (“Music.bet
                        Website”, “Website”),. In addition, this privacy policy (“Privacy Policy”) governs how we handle
                        any data we collect from your use of the Website and Services.
                        Depending on how you use the Website, additional terms and conditions may apply (“Additional
                        Terms "), including:
                    </p>

                    <ul className={styled.text} style={{textAlign: 'left'}}>
                        <li>
                            Referral program terms if you participate in our referral program;
                        </li>
                        <li>
                            Game rules if you participate in battles and competitions;
                        </li>
                        <li>
                            Brand Guidelines if you use any Music.bet marks or trademarks.
                        </li>
                    </ul>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        These Terms and Conditions and any Additional Terms (where applicable) form a legally binding
                        agreement between you and Music.bet. Please read them carefully. For the purposes of these
                        Terms, any capitalized terms have the intended meaning. We invite you to contact our support
                        team via the feedback form if you have any questions.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        1. Site Music.bet
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        1.1. The website and services are owned and operated by Music.bet
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        1.2. The Services include all products, games and features offered by Music.bet through the
                        Website (“Services "). Through the Opensea.io marketplace, you can purchase, collect, trade
                        and play digital collectible cards from the music.bet collection that use blockchain technology.
                        You can also create a playlist of your cards (each defined below), enter tournaments, and
                        compete against other users.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        1.3. The Website may contain links or content from services not operated by Music.bet that
                        provide additional content or features such as payment services, buying and selling digital
                        assets
                        as legal tender, digital asset wallets, and player data and items collectibles from third
                        parties.
                        trading platforms ("Third Party Services"). Whether or not they are integrated into the Website,
                        these Third Party Services are provided subject to the terms of use and privacy policy of the
                        respective third party provider, under the sole responsibility of that provider. Music.bet is
                        not
                        responsible for the availability or accuracy of third party services, or services, products or
                        content
                        available on third party services. Linking to or use of any Third Party Services does not
                        constitute
                        Music.bet's endorsement of such Third Party Services. Please see the terms and/or privacy
                        policy of the relevant Third Party Service for more information.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        2. Account
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        2.1. Create an account.Music.bet can be used for free. To register a user account, you must
                        provide:
                    </p>

                    <ul className={styled.text} style={{textAlign: 'left'}}>
                        <li>
                            Username;
                        </li>
                        <li>
                            E-mail address.
                        </li>
                    </ul>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        By creating a user account, you must agree to these Terms, and you hereby represent and
                        warrant that you (i) are eighteen (18) years of age or older; (ii) agree to these Terms; (iii)
                        will use
                        the Website and/or Services in accordance with these Terms and any applicable laws, rules or
                        regulations; and (iv) you are not located in a country to which the US Government, the European
                        Union and/or the United Nations have placed an embargo restriction, and you are not on the list
                        of parties or persons restricted by the US Government, the European Union or the United
                        Nations ( including specially designated citizen status by the US government).
                        For the avoidance of doubt, you must not use or access the Website or Services at any time from
                        a country that has been embargoed by the US government, the European Union and/or the
                        United Nations, or if you are added to the US government, European Union or United Nations list
                        of countries or persons subject to restrictions (including the status of a specially designated
                        citizen by the US government).
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        2.2. Account use.The Website, user accounts and services are free for individual,
                        non-commercial, personal use. You must not register on behalf of or act in conjunction with
                        another person, group of persons and/or company.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        2.3. Username.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        2.3.1. When creating a user account, you will be prompted to select a username that will be
                        associated with any purchases and game play of your user account.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        2.3.2. You must not attempt to mislead, confuse and/or deceive other users with elements of your
                        username. You may not include any offensive, harassing, abusive or otherwise malicious
                        language or suggestions in your username.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        2.3. Account security.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        2.3.1. Please choose a strong password, store your information securely, do not share your
                        account information with third parties, and follow other security best practices. We are not
                        responsible for the loss or theft of your password or other account information. You are
                        responsible for taking all steps to secure access to your login and account information,
                        including
                        your password. You are responsible for the actions of any person using your user account,
                        including without your prior consent.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        2.3.2. You agree to notify us of the theft of your information, login information or password,
                        unauthorized access to your account and/or any other fraudulent or unauthorized use of your
                        account by third parties as soon as possible by contacting our support team
                        2.4. Prohibited Account Use.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        2.4.1. Multiple accounts.You may not create more than one User account and/or manage the
                        User accounts of other Users (even if such Users have been given explicit permission). Only an
                        individual can create an account, and partnerships, joint ventures, and/or corporate accounts
                        are
                        prohibited.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        2.4.2. Linking accounts.You may not collude or otherwise act jointly or jointly with other user
                        accounts, whether formally or informally, to attempt to circumvent or circumvent these Terms;
                        attempt to create or create an unfair competitive advantage and/or other unfair or artificial
                        advantage in the Game; or attempt to unfairly or otherwise artificially influence the value of a
                        Collectible Value, including, but not limited to, directing other Users to act on the relevant
                        User
                        Account, lending, exchanging, buying, reselling and/or selling a Collectible Value below market
                        value.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        2.4.3. Recreating an account.You may not create a user account if your user account has been
                        temporarily suspended or if we have permanently deleted a user account associated with you in
                        the past.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        3. Your wallet
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        3.1. Your wallet.Each User account has an associated unique and personal wallet for use in
                        connection with the Services (“Wallet "). In your wallet, you can store your game tokens
                        intended
                        for participation in battles and tournaments as a means of payment.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        3.1.1. We are not responsible for the loss or theft of your password or collectibles that you
                        store
                        in your profile. You are responsible for taking all steps to secure access to your login and
                        account
                        information, including your password. If you lose your profile password or suspect that a third
                        party has gained unauthorized access to your profile, please contact us immediately.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        3.1.2. You can use your Profile to track the balance of game tokens that you can withdraw to an
                        external crypto wallet in accordance with the rules.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        4. Services
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        4.1. Grant of a license.Subject to your compliance with these Terms and any applicable
                        Additional Terms, we hereby grant you a limited, non-exclusive, non-transferable,
                        non-sublicensable, revocable personal license to use the Services.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        5. Cards
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        5.1 License Restrictions.You may not, under any circumstances and without the prior written
                        consent of Music.bet, perform or attempt to perform any of the following activities with the
                        Cards,
                        whether or not you own them: (i) use the Cards and any Third-Parts of the Card for commercial
                        purposes, advertising or promotion of third party products or services; (ii) sell merchandise,
                        physical or digital, that represents the card; (iii) change the image associated with the map
                        and/or change, edit or modify Third Party Map Elements in any other way; (iv) attempt to claim
                        any additional intellectual property rights relating to the Map or Map Elements of third
                        parties; (v)
                        violate any applicable rights of third parties; and/or (vii) use Third Party Map Features and/or
                        map in connection with images, videos,
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        6. Collectibles
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        6.1. Collectibles.Collectibles are unique digital trading cards featuring a cover image with a
                        non-fungible token ("NFT ”) using blockchain technology released by Music.bet. NFT ensures the
                        rarity and authenticity of the Collectibles and the transparency of the Services.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        6.2. Deficit.Each NFT release in the collection Music.bet is unique and minted in a single copy
                        and can be considered an investment object. The rarity of the collectible is guaranteed by the
                        underlying NFT.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        6.3. Ownership.When you purchase a collectible, you become the ultimate owner of the
                        underlying NFT (welcome to Web3!), subject to these Terms. Unless otherwise expressly stated
                        otherwise, you have the right to freely dispose of your collectibles (through sale, loan, gift,
                        transfer, etc., through third-party services or marketplaces).
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        6.5. Collectible items and rights of third parties.Music track name, artist name, branding
                        (including trademarks) and other elements appearing on the collectibles are officially licensed
                        from the respective third party copyright holder ("Third party collectibles "). This may include
                        third party patent rights, image rights, copyrights, trade secrets, trademarks, know-how or any
                        other intellectual property rights recognized in any country or jurisdiction in the world
                        (“Third
                        Party Rights”) .
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        6.5.1. License to third party rights to collectible items.Subject to these Terms and Conditions,
                        you are hereby granted, on a non-exclusive basis, a worldwide license to use, display and
                        transfer Third Party Collectible Items associated with Collectible Items included in NFTs that
                        you
                        legally own, for the period you own the respective NFTs only for personal and non-commercial
                        purposes. For the avoidance of doubt, you may use, display and transfer Third Party Collectibles
                        only within the scope of using, displaying and transferring (if applicable) the Collectibles in
                        which
                        such Third Party Collectibles are embedded. Any other use, display or transfer of Third Party
                        Collection Items is strictly prohibited.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        6.5.2. Licensing restrictions.You may not, under any circumstances and without the prior
                        written consent of Music.bet, do or attempt to do any of the following with respect to the
                        Collectible Items, whether or not you own: (i) use the Collectible Items and any Collectibles
                        from
                        third parties. Items for commercial purposes, advertising or promotion of a third party product
                        or
                        service; (ii) marketable goods, physical or digital, representing the purchased collectible;
                        (iii)
                        change the image associated with the Collectible and/or change, edit or modify Third Party
                        Collectibles in any other way; (iv) attempt to claim any additional intellectual property rights
                        relating to Third Party Collectible Items or Collectible Items; (v) violate any applicable
                        rights of
                        third parties; and/or (vii) use Third Party Collectibles and/or Collectibles in connection with
                        images,
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        6.6 Collector's Edition.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        6.6.1. You can buy the collectible from the Opensea.io website, through messages from other
                        users (on the marketplace), or from a third-party marketplace.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        6.7 Transfer and exchange.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        6.7.1. Through the Opensea.io Marketplace, you may sell your Collectibles to other Users or
                        purchase other Users' Collectibles by listing the relevant Collectibles on the Opensea.io
                        Marketplace (the "Marketplace"). Use is subject to the Opensea.io Terms.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        6.7.2. Through a third party service.You may withdraw your collectibles from your user account
                        and transfer and/or sell the collectibles through a third party service or marketplace. Any sale
                        or
                        exchange on a Third Party Service and/or marketplace is subject to the terms and conditions of
                        such Third Party Service or marketplace.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        6.7.3. Denial of responsibility.To the extent permitted by applicable law, Music.bet shall not,
                        under any circumstances, be liable for any loss or damage that may arise during the transfer,
                        withdrawal, sale or exchange of Collectibles through the Marketplace or outside of the Website .
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        7. Payment
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        7.1. When you make a payment in connection with the Services, your payment will be processed
                        by one of our trusted third party payment service providers"Payment Provider.” For more
                        information about the various payment options, please contact Customer Support.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        7.2. In order to process your payment, the applicable payment provider may request additional
                        information from you to verify your identity or confirm the transaction in accordance with the
                        terms of service and privacy policy of that payment provider.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        7.3. You acknowledge and agree that, due to the nature of Blockchain technology, once a
                        transaction has been processed, the associated transaction data will be irreversibly linked to
                        the
                        relevant Collectible and documented on the Blockchain.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        8. Fantasy games
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        8.1. You can test your musical skills and knowledge by building a collection of your
                        collectibles
                        and then participating in virtual battles and competitions. The virtual competition tracks the
                        actual
                        scores of individual pieces of music in cards or collectibles that you have added to your dream
                        playlist ("Game of» ).
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        8.2. Participation in the Game is governed by the rules available here:HELP
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        11. Your information, content and feedback
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        11.1. Your information.Please keep your user account information up to date. Please note that
                        due to legal and/or regulatory requirements, we may request additional information from time to
                        time. In this case, we will contact you through your user account or the email address
                        associated
                        with your user account.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        11.2. Your Content. You hereby grant and/or agree to grant to Music.bet the transferable,
                        sub-licensable, free of charge, global right to exclusively use, copy, distribute, adapt,
                        creative
                        derivative works, reproduce, distribute, modify, translate, and otherwise exploit, any content
                        you
                        produce when using the Services (including but not limited to username, comments, playlist
                        composition, playlist name, etc.) (“Content”), by any present or future process and by any means
                        of distribution on the Website, social networks, or media (such as TikTok, Facebook, Twitter,
                        Instagram, Reddit, Youtube, etc.) or platforms (such as YouTube), e-banners and promotional
                        emails or newsletter and on Music.bet intranet or in its internal documentation, whether in
                        whole
                        or in part, and whether as provided or as modified, for commercial or promotional purposes.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        11.3. Your feedback.We invite you to send us any feedback, comments, suggestions or ideas
                        you have regarding the Website and/or Services ("Reviews”) via Discord, Twitter, Reddit or other
                        social media platform and/or email to our team. When you submit a review to us, you agree to
                        grant Music.bet a transferable, sublicensable, free, worldwide and for the duration of the
                        statutory
                        period of potential ownership of your review, the right to use, copy, distribute, adapt, create
                        derivative works. , reproduce, distribute, modify, translate, make publicly available or
                        publicly
                        display your feedback through any existing or future process and by any means of distribution on
                        the Website, social networks or media (such as TikTok, Facebook, Twitter, Instagram, Reddit,
                        etc.) or platforms (e.g. YouTube), electronic banners and promotional emails or newsletters, as
                        well as in the Music.bet internal network or in its internal documentation, in whole or in part,
                        and
                        in the form as provided or modified, for commercial or promotional purposes.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        12. Content Music.bet
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        12.1. Own content.The Website and Services, including, but not limited to, their own content,
                        information, designs (including the "look and feel" of the Website or Services), logos, text,
                        graphics, images, icons, data, software, algorithms, and scripts. and will remain the property
                        of
                        Music.bet and our affiliates (and licensors, where applicable) (“Proprietary content "). Any
                        unauthorized extraction or reproduction of the Music.bet database is prohibited.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        12.2. Nothing in these Terms shall be construed as granting you any ownership rights, patents or
                        other rights in the Proprietary Content. You may not copy, imitate or use, in whole or in part,
                        any
                        Proprietary Content without the prior written permission of Music.bet.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        12.3. For the avoidance of doubt, the purchase of a Collectible does not confer any ownership or
                        other rights to any proprietary Content, Third Party Collectibles, content, code, data or other
                        materials, or Music.bet's rights and/or Third Party Rights, which may be related to the
                        Services.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        13. Service Modifications
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        13.1. We reserve the right to change, suspend or discontinue, in each case temporarily or
                        permanently, some or all of the Services, products, offers, promotions and/or features of the
                        Website or the Services, or we may introduce new features. and/or the Services if we have
                        another good reason. Valid Causes include improving the Website or Services (for example,
                        offering new, changed or updated services, offering different content or services), necessary
                        modifications for technical reasons, increasing or decreasing the number of users, changing the
                        Website or Services, and/or other operational reasons, intellectual property obligations or
                        claims,
                        changes to licenses we have obtained from third parties or other third party compliance
                        requirements, significant changes due to specific and verifiable costs in the open market,
                        necessary improvements to user or third party security persons or other significant legal,
                        regulatory, technical, marketing, product or security-related reasons. This list of good reasons
                        is
                        subject to change by Music.bet unilaterally if such change reflects a simple technical
                        improvement of the Website or Services that does not result in an increase in price or a
                        decrease
                        in quality. We will notify you in advance through your user account or other designated means
                        when we make material changes to the Services. Such modification will be made at no additional
                        cost to you. which does not increase the price or decrease the quality. We will notify you in
                        advance through your user account or other designated means when we make material changes
                        to the Services. Such modification will be made at no additional cost to you. which does not
                        increase the price or decrease the quality. We will notify you in advance through your user
                        account or other designated means when we make material changes to the Services. Such
                        modification will be made at no additional cost to you.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        13.2. If the change adversely affects your access to or use of the Website or Services, you will
                        have the right to terminate these Terms and User Account, and such termination will be effective
                        for a maximum of thirty (30) days, or if you refuse the change and continue to use the Website
                        and/or Services as before.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        13.3. Beta tests.In the event that a beta feature, product, service, or offering (each referred
                        to as
                        "beta test ”) become available, we reserve the right to change, suspend, or discontinue beta
                        testing, in each case temporarily or permanently, at our sole discretion. discretion and without
                        any liability to you.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14. Prohibited use of websites and services
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.1. Music.bet wants the website and services to be inclusive places where fans and lovers of
                        all genres and styles of music and music communities around the world can cheer on their
                        favorite musicians and singers and their work. In connection with the Website and the Services,
                        including your user account, the Game, you must not:
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.1.1. publish or otherwise create materials that are misleading, suspicious and / or intended
                        to
                        deceive or mislead other Users;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.1.2. post or otherwise create material that is offensive, obscene, pornographic, defamatory,
                        harassing, abusive, vulgar, threatening or malicious in nature or violates the rights of privacy
                        or
                        publicity, incites violence, racial or ethnic hatred, or which may be described as gross
                        obscenity
                        or incitement to certain crimes or offenses.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.1.3. tamper with, disrupt or attempt to gain unauthorized access to any other Users'
                        accounts,
                        other Users' Wallets and/or computer networks;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.1.4. use or attempt to use or access another user's account;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.1.5 use your Music.bet username to show that you work for the company and/or for the
                        purpose of selling collectibles;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.1.6. impersonate any person or entity or misrepresent your affiliation with another person or
                        entity;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.1.7. use your account or other users to buy, sell or transfer fraudulently received or stolen
                        collectibles or cryptocurrencies;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.1.8. conduct transactions that are suspicious, fraudulent or otherwise disrupt the normal
                        functioning of the Services;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.2. In addition, to ensure the integrity and proper functioning of the Website and Services,
                        there are certain restrictions on what you can do when you visit the Website or when using your
                        user account. In connection with the Website, the Services, your user account, the Game, you
                        must not:
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.2.1. access (or attempt to access) any restricted areas of the Website;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.2.2. interfere with any restrictions on access or use, or prevent (or attempt to prevent)
                        another
                        User's access to or use of the Website or Services, or disrupt, interfere with and/or overburden
                        the Website or Services;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.2.3. use any robot, spider or other automated means to access, scrape or crawl the Website
                        in violation of these Terms of Use and/or Services, or create or mirror any part of the Website
                        and/or Services;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.2.4. use any data mining or data gathering or extraction methods, or otherwise collect
                        information about Music.bet, and/or the Services in violation of these T&Cs and/or its Users;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.2.5 send viruses, worms, malware, ransomware, junk email, spam, chain letters, phishing
                        emails, spam, promotions of any kind and for any purpose;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.2.6. attempt to investigate, scan, compromise or test the vulnerability of the Website,
                        Services, system or network or violate any security system or authentication;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.2.7. modify, adapt, translate, reverse engineer, disassemble or decompile any part of the
                        Website and/or Services or create derivative works;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.2.8. decompile, disassemble or reverse engineer or reverse engineer any source code,
                        algorithms, methods or techniques of the Website and/or Services;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.2.9 resell, sublicense, rent, offer or otherwise commercialize any offer, product and/or
                        feature
                        of Music.bet or infringe Music.bet's intellectual property rights to the Website and its
                        components;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.2.10 use the data obtained from the Services for any commercial activity; violate any
                        applicable law, regulation or rule (including these Terms and any applicable Additional Terms);
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.2.11 remove or alter any copyright, trademark, privacy or other proprietary notices,
                        designations or marks from the Website and/or Services;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.2.12 use the Services for money laundering or other illegal financial activities;
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.2.13 use the Website and/or Services for any illegal or unauthorized purpose.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        14.3 Exceptions.Except as expressly provided in these Terms or the Additional Terms, or as
                        expressly permitted by the contractual agreement between you and Music.bet, you may not
                        obtain or retrieve data, content and/or any other information from the Website, Services ,
                        Games.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        15. Suspicious activity and violations
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        15.1. suspicious activity.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        15.1.1 Reporting Suspicious Activity.We invite you to report any fraudulent or illegal
                        activities
                        of other Users. Music.bet, in its sole discretion, reserves the right to investigate account
                        activity
                        deemed suspicious and restrict their access to the Services or the Game and/or temporarily
                        suspend their User account pending such investigation. If you would like to report suspicious
                        activity, you can contact support. Any records will be kept in strict confidence and will not be
                        disclosed to any third party except (i) to the extent required by applicable law or regulation,
                        or (ii)
                        pursuant to a subpoena or court order or regulatory, self-regulatory or legislative body.
                        competent jurisdiction, or (iii) in connection with any regulatory report, audit or inquiry, or
                        (iv) at
                        the request of a regulatory authority.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        15.1.2. Investigations.Music.bet reserves the right to investigate account activity that is
                        deemed
                        suspicious and restrict your access to the Services or the Game and/or temporarily suspend your
                        user account for the duration of such investigation, including where such activity has been
                        flagged by other users. When Music.bet contacts you about suspicious activity, you must provide
                        any information requested.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        15.2. Violation of these Terms and/or Any Applicable Additional Terms.You must not use the
                        Website, the Services, including the Game, and/or your account in any way that violates these
                        Terms or any applicable Additional Terms. Music.bet reserves the right to restrict a User's
                        account access to portions of the Services, temporarily suspend a User's account and/or
                        permanently suspend a User's account if such User is suspected of or has actually violated these
                        Terms and/or any other applicable Additional conditions. .
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        15.2.1. Service limits.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        15.2.1.1 Disqualification in the game.We reserve the right to temporarily exclude you from the
                        Competition and/or remove you from any Competition(s) in which you participate if you are
                        suspected of violating or violating these Terms and/or any other applicable Additional Terms.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        15.2.1.2 Reward Ineligibility.We reserve the right to withhold any prize(s) won in the event
                        that
                        you are suspected of violating or violating the Terms and/or any other applicable Additional
                        Terms.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        15.2.2. Account Suspension.We reserve the right to temporarily or permanently suspend a
                        user's account. Your user account will be suspended, including, but not limited to, if you
                        violate or
                        are suspected of violating this section or any part of these Terms and/or other applicable
                        Additional Terms. Following such suspension or limitation, we will promptly notify you.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        15.2.3 Permanent Suspension.In the event of a permanent suspension, we will notify you by
                        sending an email to the email address associated with your user account. You will then have
                        thirty (30) days to withdraw game tokens to an external wallet, following the established rules
                        for
                        withdrawing funds from your wallet on the site. You agree and acknowledge that if you do not
                        make such transfer within thirty (30) days, any and all Collectibles and/or Game Tokens in the
                        profile will be permanently lost. Music.bet is not responsible for any resulting damages.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        15.3. Appeal process.If we take any action under this section, you may have the right to appeal.
                        For more information, you can contact support by providing us with the following information:
                        your name, username, email address, full details of the issue and, where applicable, Music.bet's
                        previous response to your complaint, if any, and why do you disagree with the result. We will
                        make sure to investigate your complaint and get back to you as soon as possible.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        16. Damages
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        16.1.1. To the fullest extent permitted by applicable law, you agree to indemnify and hold
                        harmless Music.bet, its affiliates, officers, agents, consultants, suppliers, shareholders,
                        employees, assigns and assigns from any and all claims, liabilities, damages, awards,
                        judgments, damages, liabilities, obligations, fines, fees, expenses (including but not limited
                        to
                        attorneys' fees) and expenses in connection with:
                    </p>

                    <ul className={styled.text} style={{textAlign: 'left'}}>
                        <li>
                            your violation of these Terms and/or other applicable Additional Terms;
                        </li>
                        <li>
                            Your use or misuse of the Services;
                        </li>
                        <li>
                            Your violation of any third party rights or your obligations to any third party, including
                            the
                            Third Party Rights of any relevant Third Party Collection Items and/or other Users;
                        </li>
                        <li>
                            Your violation of any applicable law, regulation or rule; and/or your negligence, gross
                            negligence and/or willful misconduct.
                        </li>
                    </ul>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        17. Disclaimer
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        You expressly understand and agree that access to and use of the Services is at your own risk
                        and that the Website and Services are provided on an "as is" and "as available" basis without
                        warranties of any kind, either express or implied, to the extent permitted by applicable law.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        To the fullest extent permitted by applicable law, we make no representation or warranty that
                        (i)
                        access to or use of the Services will be uninterrupted, timely, secure or error-free; (ii) the
                        usage
                        data provided by the Services or on the Website will always be accurate; and/or (iii) the
                        Services
                        do not contain malware or other harmful components.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        17.1 To the maximum extent permitted by applicable law, Music.bet disclaims all other
                        warranties, representations and/or conditions except as expressly set forth in the Terms, either
                        express or implied, including, but not limited to, the implied warranties or conditions of
                        merchantability, fitness for a particular purpose, title and/or non-infringement. Music.bet is
                        not
                        responsible for any damages or actions taken or taken in relation to the Website, the Services,
                        information or data provided on or in connection with it, or on any third party website.
                        Music.bet
                        disclaims any liability for any losses that may be incurred by Users as a result of the use of
                        blockchain and/or digital asset wallets. In addition, no advice, data or information provided in
                        writing or verbally and/or provided by Music.bet,
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        17.2 To the extent that applicable law does not allow the exclusion of implied warranties in
                        consumer contracts, part or all of the above paragraphs do not apply to the relevant Users. IF
                        THE LAW OF THE COUNTRY IN WHICH YOU RESIDE DOES NOT ALLOW THE
                        EXCLUSIONS OF LIABILITY SET FORTH IN THIS SECTION, THESE EXCLUSIONS DO NOT
                        APPLY.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        18. Responsibility
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        18.1 General.To the maximum extent permitted by applicable law, Music.bet disclaims any and
                        all liability arising in connection with the Website and/or the Services. We are not responsible
                        for:
                    </p>

                    <ul className={styled.text} style={{textAlign: 'left'}}>
                        <li>
                            You are relying on any statement, information and/or data posted on the Website, the
                            Service and/or any social media platform or blog operated by Music.bet;
                        </li>
                        <li>
                            Loss of user content, data, maps, collectibles or game tokens;
                        </li>
                        <li>
                            temporary or permanent account blocking;
                        </li>
                        <li>
                            direct or indirect damage resulting from the use of the Services;
                        </li>
                        <li>
                            interruptions in the operation of the Website or the Services and/or any damages caused
                            by errors, errors, violations and/or malfunctions;
                        </li>
                        <li>
                            technology provided by third parties;
                        </li>
                        <li>
                            malfunction or cyber attack;
                        </li>
                        <li>
                            the actions of any third party or the User's use of the Website and/or Services;
                        </li>
                        <li>
                            any event of force majeure, including failure of the blockchain protocol used to run the
                            Services, through no fault of Music.bet.
                        </li>
                    </ul>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        18.1.1. you understand that you accept the risks associated with the provision of information,
                        online exchange over the Internet and experimental technologies such as blockchain and
                        non-fungible tokens, and agree that Music.bet is not responsible for any failure of the
                        aforementioned technologies and/or breach of security of the Website and/or Services, except to
                        the extent caused by Music.bet's gross negligence, except in connection with any liability that
                        cannot be excluded or limited by law, Music.bet's maximum aggregate liability to the Licensor in
                        accordance with and in connection with these Terms is limited to direct damages in an amount
                        not exceeding the amount (s) actually paid to Music.bet by the individual, the relevant User,
                        twelve (12) months prior to the filing of the claim giving rise to such responsibility.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        18.2. User Content.Music.bet is a hosting provider of user-produced content and/or any content
                        shared by users. Such content does not refer to Music.bet or the opinion of Music.bet. Music.bet
                        is not responsible for any third party content such as content posted by users and/or any content
                        shared by users. Music.bet disclaims any and all liability for any content, as well as the nature of
                        such content exchanged between Users.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        19. Account deletion and termination
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        19.1 Account Deletion.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        19.1.1. You may terminate these Terms at any time by requesting the deletion of your user
                        account and ceasing your use of the Website and Services.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        19.1.2 Deleting your account will permanently delete all user data associated with the account.
                        Before any deletion request, you must save all collectibles and cryptocurrencies stored in the
                        wallet to an external wallet. Any collectibles and/or cryptocurrencies held in your wallet that have
                        not been transferred from your account on the date of deletion will be permanently restored.
                        Music.bet is not responsible for the permanent loss of any collectibles and/or cryptocurrencies
                        that were not saved prior to the deletion request.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        19.1.3. Music.bet may, upon notice to you, terminate your user account at any time if you breach
                        these Terms or any applicable Additional Terms. If your user account is terminated, we will
                        communicate the reasons for the termination. We may suspend your user account prior to
                        sending a message if we need to take immediate action. We will not be liable to you or any other
                        person as a result of any such suspension or termination.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        19.1.4. Music.bet may at any time, for any reason and in its sole discretion, terminate these
                        Terms and suspend and/or terminate all or part of the Services without prior notice. Users agree,
                        to the extent permitted by law, that any suspension or termination of access to the Services may
                        be made without prior notice and that Music.bet shall not be liable for the consequences of any
                        such suspension or termination.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        19.1.5. In the event of termination of these Terms, Music.bet will provide you with the option to
                        withdraw your Collectibles and Game Tokens, unless the decision to terminate or suspend your
                        account is based on a breach of these Terms, the Terms of Use and/or any applicable Additional
                        Terms, especially in case of fraudulent, offensive or illegal activity. To the fullest extent
                        permitted
                        by applicable law, and notwithstanding anything to the contrary, termination and/or suspension of
                        a User's account shall not prejudice any other legal action that Music.bet may take against the
                        User.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        20. Repair
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        20.1. We reserve the right to change or supplement these Terms and/or any Additional Terms at
                        any time. If we make material changes to any of the above terms, we will notify you in advance of
                        those changes using a specific communication method (for example, by email sent to the email
                        address associated with your user account) and provide a reasonable period for you to read the
                        updated terms. If you do not wish to agree to any updated terms, you may terminate these Terms
                        by requesting deletion of your Usage account and automatically ceasing use of the Services. If
                        you do not terminate the Terms before the expiration of the period specified after the update
                        notice, your continued use of the Services will constitute acceptance of the modified terms.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        20.2. The applicable version of the Terms is the latest version published as of the date you use
                        the Services.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        21. Miscellaneous
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        21.1. Music.bet's failure at any time to exercise its rights under these Terms shall not constitute
                        a
                        waiver of such right.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        21.2. If any term, provision or section of these Terms is held to be invalid or unenforceable, then
                        that term, provision or section shall be deemed deleted from these Terms and shall not affect the
                        validity or enforceability of any remainder of such provision, section or any other parts. another
                        term, provision or section of these Terms, provided that the main provisions of these Terms
                        remain in effect.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        21.3. All obligations which by their nature should or should survive termination or expiration of
                        these Terms shall remain in full force and effect notwithstanding any termination of these Terms
                        by you or Music.bet.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        21.4. The Website may from time to time run sweepstakes, contests, sweepstakes or similar
                        promotions (“Actions ”) through the Website. Such Promotions may be subject to rules different
                        from these Terms. If the rules of the Promotion are in conflict with these Terms, the rules of the
                        Promotion shall prevail, but only in relation to the Promotion.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        22. Resolution of the issue, jurisdiction and applicable law
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        22.1. Solution to the problem.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        22.1.1. If you have a problem or would like to report a problem, please contact us at help
                        feedback forms Online. Please include your contact details and provide any additional
                        information and relevant context.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        22.1.2 Users in the European Union can also contact the European Commission's online dispute
                        resolution service at the following address: ec.europa.eu/consumers/odr.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        22.2 Governing Law.These Terms and Conditions, the Terms of Use and any applicable
                        Additional Terms are governed by law. However, if you are a consumer and resident of any
                        European Union country, you will enjoy the mandatory provisions and legal rights available to you
                        under the laws of that country. Nothing in these Terms affects your rights as a consumer to rely
                        on these mandatory provisions and your legal rights under local law.
                    </p>

                    <Footer/>
                </div>
            </div>
        </div>
    )
        ;
};

export {TermsAndConditions};

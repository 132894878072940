import React, {useEffect, useRef, useState} from 'react';
import styles from './DropdownInverted.module.scss';

const DropdownInverted = ({options, defaultOption, onSelect}) => {
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        onSelect(option);
        setIsDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <div className={styles.dropdown} ref={dropdownRef}>
            <div className={styles.dropdown__selectedOption} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                BET {selectedOption} TKN
                <span className={isDropdownOpen ? styles.dropdown__selectedOption__arrowUp : styles.dropdown__selectedOption__arrowDown}></span>
            </div>
            {isDropdownOpen && (
                <ul className={styles.dropdown__options}>
                    {options.map((option) => (
                        <li
                            key={option}
                            className={styles.dropdown__option}
                            onClick={() => handleOptionSelect(option)}
                        >
                            {option} TKN
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default DropdownInverted;

import {Link, useNavigate} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import {closeMobileMenu} from '../../store/mobileMenuSlice';
import {ReactComponent as Logo} from '../../assets/svg/mobile-icons/logo.svg';
import {ReactComponent as Cross} from '../../assets/svg/mobile-icons/cross.svg';
import {ReactComponent as User} from '../../assets/svg/mobile-icons/user.svg';
import {ReactComponent as Explore} from '../../assets/svg/mainMenu/home.svg';
import {ReactComponent as NftBattle} from '../../assets/svg/mainMenu/path.svg';
import {ReactComponent as Note} from '../../assets/svg/mainMenu/note.svg';
import {ReactComponent as Heart} from '../../assets/svg/mainMenu/heart.svg';
import {ReactComponent as Trophy} from '../../assets/svg/mainMenu/trophy.svg';
import {ReactComponent as Album} from '../../assets/svg/mainMenu/music-album.svg';
import {ReactComponent as Playlist} from '../../assets/svg/mainMenu/playlist.svg';
import {ReactComponent as Logout} from '../../assets/svg/mainMenu/logout.svg';
import iconLink from '../../assets/svg/mobile-icons/iconLink.png';

import {ReactComponent as Twitter} from '../../assets/svg/footer-links/twitter.svg';
import {ReactComponent as Discord} from '../../assets/svg/footer-links/discord.svg';
import {ReactComponent as Reddit} from '../../assets/svg/footer-links/reddit.svg';
import {ReactComponent as Telegram} from '../../assets/svg/footer-links/telegram.svg';

import styled from './MobileMenu.module.scss';
import {useEffect, useState} from 'react';
import {openLoginModalMenu, toggleLoginScene} from "../../store/loginModalSlice";
import {userLogOut} from "../../store/isAuthSlice";
import {authAPI} from "../../api/api";
import {toast} from "react-toastify";

const MobileMenu = () => {
    const isOpen = useAppSelector((state) => state.mobileMenu.isOpen);
    const navigate = useNavigate();
    const [isAuth, setIsAuth] = useState(true);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (localStorage.getItem('isAuth')) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
    }, []);

    function openProfile() {
        dispatch(closeMobileMenu(null));
        navigate('/customer-tracks');
    }

    function openModal() {
        dispatch(closeMobileMenu(null));
        dispatch(toggleLoginScene(null));
        dispatch(openLoginModalMenu(null));
    }

    function openNotifications() {
        dispatch(closeMobileMenu(null));
        navigate('/profile/notification');
    }

    // const [searchValue, setSearchValue] = useState("");
    //
    // function onClickLoupe() {
    //     dispatch(closeMobileMenu(null));
    //     navigate(`/buy-nft?search=${searchValue}`)
    // }

    // useEffect(() => {
    //     // document.body.style.overflow = 'hidden';
    //
    //     return () => {
    //         // document.body.style.overflow = "auto";
    //     };
    // }, []);

    async function logout() {
        authAPI
            .logOut()
            .then(() => dispatch(userLogOut(null)))
            .then(() => setIsAuth(false))
            .then(() => window.location.reload())
            .catch(() => toast.error('the server is not responding, try again'))
        ;
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    return (
        <div className={styled.mobileMenu}>
            {isOpen && (
                <nav className={styled.mobileNavigationWrapper}>
                    <div className={styled.iconsWrapper}>
                        {!isAuth &&
                            <>
                                <Logo className={styled.logo}/>
                                <Cross
                                    className={styled.navIcons}
                                    onClick={() => dispatch(closeMobileMenu(null))}
                                />
                            </>
                        }
                        {isAuth &&
                            <>
                                <Logo className={styled.logo}/>

                                <Cross
                                    className={styled.navIcons}
                                    onClick={() => dispatch(closeMobileMenu(null))}
                                    style={{marginTop: '10px', marginRight: '5px'}}
                                />
                            </>
                        }
                    </div>

                    {/* main */}
                    <div className={styled.mainNavigation}>
                        <Link
                            onClick={() => dispatch(closeMobileMenu(null))}
                            to='/explore'
                            className={styled.link}>
                            <Explore className={styled.icon}/>
                            <span>Home</span>
                        </Link>
                        <Link
                            onClick={() => dispatch(closeMobileMenu(null))}
                            className={styled.link}
                            to='/category-selection'>
                            <NftBattle className={styled.icon}/>
                            <span>Battles</span>
                        </Link>
                        <Link
                            onClick={() => dispatch(closeMobileMenu(null))}
                            className={styled.link}
                            to='/buy-nft'>
                            <Note className={styled.icon}/>
                            <span>Buy NFT</span>
                        </Link>
                        <Link
                            onClick={() => dispatch(closeMobileMenu(null))}
                            className={styled.link}
                            to='/make-offer'>
                            <Heart className={styled.icon}/>
                            <span>Order NFT</span>
                        </Link>
                        <Link
                            onClick={() => dispatch(closeMobileMenu(null))}
                            className={styled.link}
                            to='/winners'>
                            <Trophy className={styled.icon}/>
                            <span>Charts</span>
                        </Link>
                    </div>

                    {/* profile */}
                    {isAuth && (
                        <div className={styled.profileNavigation}>
                            <Link
                                onClick={() => dispatch(closeMobileMenu(null))}
                                className={styled.link}
                                to='/customer-tracks'>
                                <Album className={styled.icon}/>
                                <span>Your NFT</span>
                            </Link>
                            <Link
                                onClick={() => dispatch(closeMobileMenu(null))}
                                className={styled.link}
                                to='/profile/playlists'>
                                <Playlist className={styled.icon}/>
                                <span>Your Playlists</span>
                            </Link>
                            <Link
                                onClick={() => logout()}
                                className={styled.link}
                                to='/'>
                                <Logout className={styled.icon} style={{width: '17px', height: '17px'}}/>
                                <span>Logout</span>
                            </Link>
                        </div>
                    )}

                    {/* info */}
                    <div className={styled.supportWrapper}>
                        {/*<Link
                            onClick={() => dispatch(closeMobileMenu(null))}
                            to='/support'
                            className={styled.linkSupport}>
                            <span>FAQ</span>
                        </Link>*/}
                        <Link
                            onClick={() => dispatch(closeMobileMenu(null))}
                            to='contacts'
                            className={styled.linkSupport}>
                            <span>Contact</span>
                        </Link>
                        <Link
                            onClick={() => dispatch(closeMobileMenu(null))}
                            to='how-to-play-and-win'
                            className={styled.linkSupport}>
                            <span>How to play</span>
                        </Link>
                    </div>

                    <div className={styled.iconsLinkWrapper}>
                        <a href={'https://twitter.com/www_music_bet'} style={{
                            display: 'block',
                            fill: 'white',
                            opacity: '1'
                        }}>
                            <Twitter/>
                        </a>
                        <a href={'https://discord.com/channels/1085543688605802527/1085543689528561667'} style={{
                            display: 'block',
                            fill: 'white',
                            opacity: '1'
                        }}>
                            <Discord/>
                        </a>
                        <a href={'https://www.reddit.com/user/www_music_bet'} style={{
                            display: 'block',
                            fill: 'white',
                            opacity: '1'
                        }}>
                            <Reddit/>
                        </a>
                        <a href={'https://telegram.com'} style={{
                            display: 'block',
                            fill: 'white',
                            opacity: '1'
                        }}>
                            <Telegram/>
                        </a>
                    </div>
                </nav>
            )}
        </div>
    );
};

export {MobileMenu};

import wallet from '../../../assets/profile/wallet-blue.png';

import styled from './MenuHeader.module.scss';
import {useEffect, useMemo, useState} from 'react';
import {authAPI, profileAPI} from '../../../api/api';
import {AnimateBtn} from "../../Buttons/AnimateBtn/AnimateBtn";
import {closeMobileMenu} from "../../../store/mobileMenuSlice";
import {openLoginModalMenu, toggleLoginScene} from "../../../store/loginModalSlice";
import {useAppDispatch, useAppSelector} from "../../../hooks/reduxHooks";
import {LoginModal} from "../../../pages/Login/LoginModal/LoginModal";
import {Link} from "react-router-dom";
import {userLogOut} from "../../../store/isAuthSlice";
import {toast} from "react-toastify";
import {setProfile} from "../../../store/profileSlice";
import Dropdown from "../../Dropdown/Dropdown";
import {ReactComponent as Logout} from '../../../assets/svg/mainMenu/logout-black.svg';

const MenuHeader = () => {
    const dispatch = useAppDispatch();
    const profile = useAppSelector((state) => state.profile.profile);
    const isAuthState = useAppSelector((state) => state.isAuth.isAuth);
    const [isAuth, setIsAuth] = useState(false);
    const isOpenModalLogin = useAppSelector((state) => state.modalLogin.isOpen);

    useEffect(() => {
        if (localStorage.getItem('isAuth')) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
    }, []);

    useEffect(() => {
        if (localStorage.getItem('isAuth')) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
    }, [isAuthState]);

    useEffect(() => {
        if (null === profile) {
            profileAPI.getProfile().then((res) => {
                dispatch(setProfile(res.data));
            });
        }
    }, [profile]);

    function openModal() {
        dispatch(openLoginModalMenu(null));
    }

    useEffect(() => {
        if (isOpenModalLogin) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isOpenModalLogin]);

    async function logout() {
        authAPI
            .logOut()
            .then(() => dispatch(userLogOut(null)))
            .then(() => setIsAuth(false))
            // .then(() => window.location.reload())
            .catch(() => toast.error('the server is not responding, try again'))
        ;
    }

    return (
        <>
            {isAuth && (
                <div className={styled.userInfoWrapper}>
                    <img src={profile?.customer?.avatar_url} className={styled.userAvatarSmall} alt=''/>

                    <div className={styled.infoBlock}>
                        <div className={styled.nameWrapper}>
                            <h3 className={styled.name}>{profile?.customer.username}</h3>
                            <Link className={styled.name} to={'/'} onClick={() => logout()}>
                                <Logout className={styled.icon} style={{width: '17px', height: '17px'}}/>
                            </Link>
                        </div>

                        <div className={styled.profileWrapper}>
                            <div className={styled.walletContainer}>
                                <p className={styled.wallet}></p>
                                <div className={styled.walletWrapper}>
                                    <img src={wallet} alt='wallet'/>
                                    <p className={styled.tkn}>{profile?.account.balance} TKN</p>
                                </div>
                            </div>
                            <div className={styled.nominalContainer}>
                                <div className={styled.walletWrapper}>
                                    {profile?.customer.nominal && <Dropdown
                                        options={[1, 50, 100, 200, 500, 1000]}
                                        defaultOption={profile?.customer.nominal ?? 1}
                                        onSelect={(option) => {
                                            profileAPI.setNominal(option).then(() => {
                                                profileAPI.getProfile().then((res) => {
                                                    dispatch(setProfile(res.data));
                                                });
                                            });
                                        }}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!isAuth && (
                <div className={styled.userInfoWrapper}>
                    <AnimateBtn title={'Sign Up Free'} handleClick={() => openModal()}/>
                </div>
            )}
        </>
    );
};

export {MenuHeader};

import {useEffect, useState} from 'react';
import {contactAPI, profileAPI} from '../../api/api';
import {ProfileLayout} from '../ProfileLayout/ProfileLayout';
import {toast} from 'react-toastify';

import styled from './withdraw.module.scss';
import {NotificationT} from "../ToastifyNot/NotificationToastify";
import {setProfile} from "../../store/profileSlice";
import {useAppDispatch} from "../../hooks/reduxHooks";

const Withdraw = () => {
    const dispatch = useAppDispatch();
    const [amount, setAmount] = useState<string>("");

    const onSend = () => {
        profileAPI
            .withdraw(amount)
            .then((res) => {
                toast.success(res.message);

                profileAPI.getProfile().then((res) => {
                    dispatch(setProfile(res.data));
                });

                setAmount('');
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };

    return (
        <>
            <NotificationT/>
            <ProfileLayout>
                <div className={styled.container}>
                    <div className={styled.messageWrapper}>
                        <h1 className={styled.title}>Withdraw</h1>
                        <p>
                            Enter the amount of tokens that you want to withdraw from your wallet in your account and
                            send a request to us so that we can transfer the amount to your crypto wallet. Application
                            processing will take 48 hours.
                        </p>
                        <div>
                            <label
                                htmlFor='amount'
                                className={styled.label}
                            >
                                Amount
                            </label>
                            <input
                                className={styled.input}
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                type='number'
                                name='amount'
                            />
                        </div>
                        <button onClick={onSend} className={styled.btn}>
                            Send
                        </button>
                    </div>
                </div>
            </ProfileLayout>
        </>
    );
};

export {Withdraw};

import {useMediaQuery} from 'react-responsive';
import {toast} from 'react-toastify';
import {useEffect, useState} from 'react';
import {WhiteBtn} from '../../components/Buttons/WhiteBtn/WhiteBtn';
import {Footer} from '../../components/HowToPlayAndWin/Footer/Footer';
import {HeadMenu} from '../../components/HowToPlayAndWin/HeadMenu/HeadMenu';
import {MainContent} from '../../components/HowToPlayAndWin/MainContent/MainContent';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import phone from '../../assets/phone.svg';
import {LoginModal} from '../Login/LoginModal/LoginModal';
import {openLoginModalMenu} from '../../store/loginModalSlice';
import {authAPI} from '../../api/api';
import {userLogOut} from '../../store/isAuthSlice';

import styled from './HowToPlayAndWin.module.scss';

const HowToPlayAndWin = () => {
    const dispatch = useAppDispatch();
    const [isAuth, setIsAuth] = useState(false);
    const isOpenModalLogin = useAppSelector((state) => state.modalLogin.isOpen);

    useEffect(() => {
        if (localStorage.getItem('isAuth')) setIsAuth(true);
        if (!localStorage.getItem('isAuth')) setIsAuth(false);
    }, []);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 620px)',
    });

    const isDesktop = useMediaQuery({
        query: '(min-width: 1201px)',
    });

    function scroll() {
        // window.scrollTo(0, 900);
    }

    function openModal() {
        dispatch(openLoginModalMenu(null));
    }

    async function logout() {
        authAPI
            .logOut()
            .then(() => {
                dispatch(userLogOut(null));
            })
            .then(() => setIsAuth(false))
            .catch(() => {
                toast.error('the server is not responding, try again');
            });
    }

    useEffect(() => {
        if (isOpenModalLogin) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isOpenModalLogin]);

    return (
        <div className={styled.containerWrapper}>
            {isOpenModalLogin && <LoginModal/>}

            <div className={styled.bgWrapper}>
                <div className={styled.bgTop}></div>
                <div className={styled.imagesBg}></div>
                <img className={styled.ph} src={phone} alt='phone'/>
            </div>
            <div className={styled.container}>
                {isDesktop && <div className={styled.menu}>
                    <div style={{width: '220px', textAlign: 'left'}}>
                        <img className={styled.logo} src='image/logo.svg' alt='logo'/>
                    </div>
                    <HeadMenu/>
                    <div style={{width: '220px', textAlign: 'right'}}>
                        {!isAuth && <WhiteBtn handleClick={() => openModal()} title='Sign Up Free'/>}
                        {isAuth && <WhiteBtn handleClick={() => logout()} title='logout'/>}
                    </div>
                </div>}

                <MainContent isDesktopOrLaptop={isDesktopOrLaptop} isAuth={isAuth} openModal={openModal} scroll={scroll}/>

                <div className={styled.afterMainWrapper}>
                    {/*<img className={styled.tv} src='image/howToPlayAndWin/tv.png' alt='tv' />*/}

                    {/*<h2 className={styled.headerSecondAsphalt}>We Give Everyone the</h2>*/}

                    <h2 className={styled.headerSecondWhite}>
                        GAME ROLES
                    </h2>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        DECIDE WHO YOU ARE IN THIS GAME?
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        A) I am a PRODUCER:
                    </p>
                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        I bought NFT`s from the music.bet collection, put them into BATTLES and make $ and prizes!
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        B) I am ADVANCED PRODUCER:
                    </p>
                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        I have completed my NFT`s to the PLAYLISTS and make more $ and prizes!
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        C) I am VOCAL PRODUCER:
                    </p>
                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        I have talent, I have courage. I myself create the NFT and fight in battles!
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        D) I am a MUSIC FAN:
                    </p>
                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        My VOTE is the decision maker in the BATTLES, i am the guy, who make $ for myself and producers!
                    </p>

                    <p className={styled.text}>
                        Choose your ROLE and remember - Whoever you are, music is your passion!
                    </p>

                    <div style={{marginTop: '40px'}}></div>

                    <h2 className={styled.headerSecondWhite}>
                        GAME RULES
                    </h2>

                    <p className={styled.text}>
                        EASY STEPS TO SUCCESS IN GAME:
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        You are PRODUCER:
                    </p>

                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        1) Choose and Buy NFT from Music.bet collection<br/>
                        2) Reg your NFT in your account on Music.bet<br/>
                        3) Go to battle and win!<br/>
                        - After purchasing NFT on the Opensea.io website, you will see your unique code for registering
                        it on the Music.bet website in your account.<br/>
                        - To activate the NFT in battles, you need to click on the GO TO BATTLE button opposite the
                        registered NFT in your account<br/>
                        - You can find the date and time of each match, as well as the results of your NFT matches in
                        your account.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        You are ADVANCED PRODUCER:
                    </p>

                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        1) Choose and Buy NFT from Music.bet collection<br/>
                        2) Reg your NFT in your account on Music.bet<br/>
                        3) Complete your Playlist with strong NFT`s<br/>
                        4) Fight in battles for the first places in the ranking<br/>
                        - To complete your playlist you need to have at least 4 registered NFT`s<br/>
                        - To take the first places in the ranking, you need to have your NFTs included in the playlist,
                        get high ratings according https://chartmetric.com/ and gain victory cups. Each of the four NFTs
                        in the playlist ratings sum up and show the total score.This is a real life fantasy music game.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        You are SINGING PRODUCER:
                    </p>

                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        1) Record your music video<br/>
                        2) We will create NFT from your video and you can become its exclusive buyer<br/>
                        3) Participate in battles and win!<br/>
                        - You will be able to put your NFT video in battle even if the opponent in the battle is the
                        original singer of the same music track.
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        You are MUSIC FAN:
                    </p>

                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        1) Buy game tokens<br/>
                        2) Choose the battles by genre<br/>
                        3) Vote in battles and win<br/>
                        -To vote, you need tokens that you can buy in your account. Each click on the heart symbol
                        during the battle is equal to one token.<br/>
                        -The more tokens you wager, the bigger your winnings will be. The NFT with the most votes wins,
                        no matter how many tokens they staked.<br/>
                        NOTE: You can play multiple roles at the same time be a PRODUCER at the same time ADVANCED
                        PRODUCER and MUSIC FAN!
                    </p>

                    <div style={{marginTop: '40px'}}></div>

                    <h2 className={styled.headerSecondWhite}>
                        GAME NFT`s
                    </h2>

                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        Each NFT is a cover of a popular music track created using artificial intelligence with the name
                        of the track and the name of the artist. Each NFT is accepted in a single copy and can only be
                        used in the game on music.bet
                    </p>

                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        NOTE!!! All copyrights to the music track belong to the creators and copyright holders!
                    </p>

                    <div style={{marginTop: '40px'}}></div>

                    <h2 className={styled.headerSecondWhite}>
                        GAME PRIZES
                    </h2>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        IN BATTLES:
                    </p>

                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        Prize fund formed from the tokens of lost players. 70% of the prize fund for the care of the
                        MUSIC FANS, 30% for the owners of NFTs in battles:
                    </p>

                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        The winner of the battle is the NFT who received the most votes during the battle when the timer
                        allows more bets to be placed. After the end of the battle, the winner of the battle is shown.
                        (The quantity of tokens is not decisive for winning.) The losers are considered to be those who
                        are in the minority, that is, their votes in aggregate are less than the votes received by the
                        winning NFT.
                    </p>

                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        Example:
                    </p>

                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        In 1st Round of the battle NFT#1 vs NFT#2, 10 players voted for NFT#1 and bid 100 tokens in
                        total, and 9 players voted for NFT#2 and bid 200 tokens in total. As a result of this battle,
                        NFT#1 wins and all 10 players who voted for it become winners, while NFT#2 and all 9 players who
                        voted for it, respectively, become losers. In this case, the 200 tokens staked by the losers is
                        the prize pool and is distributed among the winners:
                    </p>

                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        25% from 200 tkn = 50 tkn for owner of NFT#1 who win
                    </p>

                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        5% from 200 tkn = 10 tkn for owner of NFT#2 who lose (consolation prize)
                    </p>


                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        70% from 200 tkn = 140 tkn for 10 winners (distributed according to their rates - % of
                        individual bets from all winners total bets)
                    </p>


                    <p className={styled.text} style={{textAlign: 'left'}}>
                        1st Round (2 NFT in battle):
                    </p>

                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        For owner of NFT who WIN: 25% of the 30% of prize fund and winner NFT advances to the next round<br/>
                        For owner of NFT who LOSE: 5% of the 30% of prize fund and loser NFT and will start over from
                        the first round with another opponent<br/>
                        By analogy, the winners in the next rounds advance to the next round and the losers start from
                        the beginning with other opponents
                    </p>


                    <p className={styled.text} style={{textAlign: 'left'}}>
                        2nd Round (4 NFT in battle):
                    </p>

                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        For owner of NFT who WIN: 24% of the 30% of prize fund and winner NFT advances to the next round<br/>
                        2nd place NFT in battle: 3%<br/>
                        3rd place NFT in battle: 2%<br/>
                        4th place NFT in battle: 1%
                    </p>


                    <p className={styled.text} style={{textAlign: 'left'}}>
                        3rd Round (8 NFT in battle):
                    </p>

                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        For owner of NFT who WIN: 17% of the 30% of prize fund and winner becomes the undisputed
                        champion of the entire tournament <br/>
                        2nd place NFT: 4%<br/>
                        3rd place NFT: 3%<br/>
                        4th place NFT: 2%<br/>
                        5th place NFT: 1%<br/>
                        6th place NFT: 1%<br/>
                        7th place NFT: 1%<br/>
                        8th place NFT: 1%
                    </p>

                    <p className={styled.text} style={{textAlign: 'left'}}>
                        IN PLAYLIST CHARTS:
                    </p>

                    <p className={styled.textRegular} style={{textAlign: 'left'}}>
                        The first three places in the chart are prize-winning. For each place there are special prizes
                        that will be shown on the main page of the site. The only rule is to be among the winners every
                        Sunday at exactly 00:00 GMT.
                    </p>

                    <br/>

                    <img
                        className={styled.bmiLogo}
                        style={{width: '300px', marginTop: '20px'}}
                        src='image/howToPlayAndWin/opeansea-logo.svg'
                        alt='path'
                    />
                    <p className={styled.textRegular}>
                        <a href="https://opensea.io/collection/music-bet" style={{color: "white", textDecoration: "underline"}} target={'_blank'}>
                            Music.bet collection on Opensea.io
                        </a>
                    </p>

                    <br/>

                    <img
                        className={styled.bmiLogo}
                        style={{marginTop: '20px'}}
                        src='image/howToPlayAndWin/bmi-logo.png'
                        alt='path'
                    />

                    <p className={styled.textRegular}>
                        Licensed music by BMI on music.bet
                    </p>

                    <br/>

                    <a href={'https://nftdroops.com/'} target={'_blank'}>
                        <img
                            className={styled.nftDropsLogo}
                            style={{marginTop: '20px'}}
                            src='image/howToPlayAndWin/NFTdroops-logo.png'
                            alt='path'
                        />
                    </a>

                    <br/>

                    <a href={'https://nftcalendar.io/'} target={'_blank'}>
                        <img
                            className={styled.nftCalendarLogo}
                            style={{marginTop: '20px'}}
                            src='image/howToPlayAndWin/nft_calendar_logo_vector_optimized.svg'
                            alt='path'
                        />
                    </a>

                    <br/>

                    <a href={'https://raritysniper.com/nft-drops-calendar'} target={'_blank'}>
                        <img
                            className={styled.rarirySniperLogo}
                            style={{marginTop: '20px'}}
                            src='image/howToPlayAndWin/raritysniper-logo2-white.png'
                            alt='path'
                        />
                    </a>

                    <br/>

                    {/*<img*/}
                    {/*    className={styled.logoSymbol}*/}
                    {/*    src='image/howToPlayAndWin/logo-symbol.svg'*/}
                    {/*    alt=''*/}
                    {/*/>*/}

                    {/*<h3 className={styled.logoDescription}>*/}
                    {/*    The Best Music Platform for betting and winning money!*/}
                    {/*</h3>*/}

                    {/*<div className={styled.preFooter}>*/}
                    {/*    <h3 className={styled.preFooterTitle}>*/}
                    {/*        Listen to trending song VOTE and WIN*/}
                    {/*    </h3>*/}
                    {/*    {!isAuth && <WhiteBtn handleClick={() => openModal()} title='Sign Up Free'/>}*/}
                    {/*    {isAuth && <WhiteBtn handleClick={() => logout()} title='logout'/>}*/}
                    {/*</div>*/}

                    <Footer/>
                </div>
            </div>
        </div>
    );
};

export {HowToPlayAndWin};

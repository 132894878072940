import {useEffect, useState} from 'react';
import {contactAPI, profileAPI} from '../../api/api';
import {ProfileLayout} from '../ProfileLayout/ProfileLayout';
import {toast} from 'react-toastify';

import styled from './ContactUs.module.scss';

const ContactUs = () => {
    const [message, setMessage] = useState<string>("");
    const [userData, setUserData] = useState<any>({});

    useEffect(() => {
        if (Object.keys(userData).length === 0) {
            profileAPI.getProfile().then((res) => {
                setUserData(res.data.customer);
            });
        }
    }, [userData]);

    const onSend = () => {
        const userEmail = userData.email ? userData.email : "info@music.bet"
        const name = userData.first_name ? `${userData.first_name} ${userData.last_name}` : "Not logged in user";

        contactAPI
            .createContact(name, userEmail, "Profile contact us", message)
            .then((res) => {
                toast.success(res.message);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };

    return (
        <ProfileLayout>
            <div className={styled.container}>
                <div className={styled.messageWrapper}>
                    <h1 className={styled.title}>Contact us</h1>
                    <textarea
                        className={styled.textAreaMessage}
                        placeholder='Your message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        rows={6}
                    />
                    <button onClick={onSend} className={styled.btn}>
                        Send
                    </button>
                </div>
            </div>
        </ProfileLayout>
    );
};

export {ContactUs};

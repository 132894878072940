import { ReactComponent as Cup } from '../../../assets/svg/icon-top-players/cup.svg';
import { ReactComponent as NFTImage } from '../../../assets/svg/icon-top-players/note.svg';
import styled from './TopPlayers.module.scss';
import { useMemo } from 'react';

interface IProps {
  index: number;
  name: string;
  balance: number;
  avatar: string | null;
  total_nfts: number;
  win_count: number;
}

const TopPlayers = ({ topPlayers }: any) => {
  return (
    <ul className={styled.container}>
      {topPlayers.map((el: any, index: number) => {
        return (
          <Player
            key={el.id}
            index={index}
            name={el.name}
            balance={el.account.balance}
            avatar={el.avatar_url}
            total_nfts={el.total_nfts}
            win_count={el.win_count}
          />
        );
      })}
    </ul>
  );
};

const Player: React.FC<IProps> = ({
  index,
  name,
  balance,
  avatar,
  total_nfts,
  win_count,
}) => {
  return (
    <li className={styled.wrapper}>
      <div className={styled.coverWrapper}>
        <img className={styled.cover} src={avatar} alt='cover' />

        <div className={styled.nameWrapper}>
          <div className={styled.place}>{index + 1}</div>
          <p className={styled.name}>{name}</p>
        </div>
      </div>

      <div className={styled.statsWrapper}>
        <Cup />
        <div className={styled.points}>{win_count}</div>
      </div>

      <div className={styled.statsWrapper}>
        <NFTImage />
        <div className={styled.NFT}>{total_nfts}</div>
      </div>

    </li>
  );
};

export { TopPlayers };

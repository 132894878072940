import cover from '../../../assets/explore/cover-card.png';
import play from '../../../assets/explore/btn-play.svg';
import pause from '../../../assets/explore/btn-stop.svg';
import note from '../../../assets/explore/note-blue.svg';

import styled from './CurrentCardElement.module.scss';
import {useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {setSongData, setPlaying} from '../../../store/playerSlice';
import {ScrollingText} from "../../TextUtils/ScrollingText";
import {useAppDispatch, useAppSelector} from "../../../hooks/reduxHooks";

const CurrentCardElement = (track: any) => {
    const dispatch = useAppDispatch();
    const image = useMemo(() => {
        if (track.track.image_url) {
            return `${track.track.image_url}`;
        }
        return cover;
    }, [track.track.image_url]);

    const currentlyPlayingSongId = useAppSelector((state) => state.player.songId);
    const isPlayerPlaying = useAppSelector((state) => state.player.playing);
    const isTrackPlaying = isPlayerPlaying && track.track.id === currentlyPlayingSongId;

    // const playSong = () => {
    //     let lyrics = [];
    //     try {
    //         lyrics = JSON.parse(track.track.lyrics.replace(/[\r\n]/g, ''));
    //     } catch (e) {
    //         // console.log("error lyrics: ", e);
    //     }
    //
    //     dispatch(
    //         setSongData({
    //             id: track.id,
    //             url: `${track.track.file_url}`,
    //             openseaUrl: `${track.track.opensea_link}`,
    //             image: `${track.track.image_url}`,
    //             title: track.name,
    //             singer: track?.track.artists[0]?.name,
    //             lyrics: lyrics,
    //             genre:
    //                 track.track.music_categories.length > 0 ? track.track.music_categories[0].name : 'Pop',
    //         }),
    //     );
    //
    //     setIsPlaying(true);
    // };

    const playPauseSong = () => {
        if (!isTrackPlaying) {
            dispatch(
                setSongData({
                    id: track.track.id,
                    url: track.track.file_url,
                    openseaUrl: track.track.opensea_link,
                    image: track.track.image_url,
                    title: track.track.name,
                    singer: track?.track.artists[0]?.name,
                    lyrics: [],
                    genre:
                        track.track.music_categories.length > 0 ? track.track.music_categories[0].name : 'Pop',
                }),
            );

            dispatch(setPlaying(true));
        } else {
            dispatch(setPlaying(false));
        }
    }

    const openBuyNowLink = (link: Location | (string & Location)) => {
        window.location = link;
    };


    return (
        <div className={styled.container}>
            <figure>
                <img src={image} width={176} height={176} alt='cover'/>
                {isTrackPlaying ?
                    <img className={styled.playerBtn} onClick={playPauseSong} src={pause} alt='pause'/>
                    :
                    <img className={styled.playerBtn} onClick={playPauseSong} src={play} alt='play'/>
                }
                {track.track.sold && (
                    <div className={styled.soldPlaceholder}>
                        SOLD!
                    </div>
                )}
                {/* <img className={styled.playerBtn} src={stop} alt='stop' /> */}
            </figure>
            <article>
                <div>
                    <h4>{track?.track?.artists[0]?.name ?? "Micheal Jackson"}</h4>
                    <h4 style={{overflow: 'visible'}}>Price</h4>
                </div>
                <div>
                    <ScrollingText text={track.track.name ?? "Give in to Me"} classNames={styled.h4}/>
                    <h3>{track.track.price}</h3>
                </div>
            </article>
            <hr/>
            <div className={styled.buyNowBlock}>
                <img src={note} alt='note'/>
                <p onClick={() => openBuyNowLink(track.track.opensea_link)}>BUY NOW</p>
            </div>
        </div>
    );
};

export {CurrentCardElement};

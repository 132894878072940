import {useNavigate} from 'react-router-dom';
import {AnimateBtn} from '../../Buttons/AnimateBtn/AnimateBtn';
import styled from './MainContent.module.scss';
import "video-react/styles/scss/video-react.scss";
import {Player, ControlBar, BigPlayButton} from 'video-react';
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';
import image1 from '../../../assets/howtoplayandwin/VS1.png';
import image2 from '../../../assets/howtoplayandwin/VS2.png';
import image3 from '../../../assets/howtoplayandwin/VS3.png';

interface IProps {
    scroll: () => void;
    isDesktopOrLaptop: any;
    isAuth: any;
    openModal: any;
}

const MainContent: React.FC<IProps> = ({scroll, isDesktopOrLaptop, isAuth, openModal}) => {
    const navigate = useNavigate();

    function startToPlayNavigate() {
        navigate('/battle/all-battles?status=0&category_id=1&step=1');
    }

    function buyTokenNavigate() {
        navigate('/buy-nft');
    }

    const properties = {
        duration: 3000,
        autoplay: true,
        transitionDuration: 500,
        arrows: false,
        infinite: true,
        easing: "ease"
    };

    const slideImages = [image1, image2, image3];

    return (
        <div className={styled.mainContent}>
            <div className={styled.wrapper}>
                <h1 className={styled.title}>HOW TO PLAY</h1>

                <p className={styled.text}>
                    Music.bet is P2E NFT game
                </p>

                <div className={styled.slideContainer}>
                    <Slide {...properties}>
                        {slideImages.map((each, index) => (
                            <div key={index} className={styled.eachSlide}>
                                <img className="lazy" src={each} alt="sample" />
                            </div>
                        ))}
                    </Slide>
                </div>

                <div className={styled.btnBlock}>
                    <AnimateBtn
                        additionalStyles={{height: "62px", width: "255px"}}
                        handleClick={() => startToPlayNavigate()}
                        title='Start To Play'
                    />
                </div>

                <img
                    onClick={scroll}
                    className={styled.arrowDown}
                    src='image/howToPlayAndWin/arrow-down.svg'
                    alt='arrow'
                />
            </div>
        </div>
    );
};

export {MainContent};
